import {
  Input, Typography, Flex, Space, Row, Col,
} from 'antd';
import { capitalize } from 'lodash-es';
import React from 'react';
import { WL_CONFIG } from 'constants';
import chroma from 'chroma-js';
import OrderFormItem from '../../components/OrderFormItem';

function CustomPaymentForm({ meta }) {
  function renderPaymentMetaDataForm() {
    const fields = meta ? Object.entries(meta?.fields) : [];
    return fields.map(([name, attrs]) => (
      <Col key={name} xs={24} md={12} lg={8}>
        <OrderFormItem
          name={['customPaymentMeta', name]}
          rules={[
            {
              required: attrs?.required,
              message: 'This field is required',
            },
          ]}
        >
          <Input
            placeholder={capitalize(name)}
            type={attrs?.type || 'string'}
            {...attrs}
          />
        </OrderFormItem>
      </Col>
    ));
  }

  return (
    <Flex
      vertical
      gap={12}
      className="py-2 px-4 rounded"
      style={{
        backgroundColor: chroma(WL_CONFIG.colors.primary).alpha(0.1).css(),
      }}
    >
      <Space align="baseline">
        <Typography.Title level={5}>
          Custom payment
        </Typography.Title>
      </Space>
      <Flex vertical gap={8}>
        <Typography.Text>
          To complete your payment, please fill out the following fields.
        </Typography.Text>
        <Row>
          {renderPaymentMetaDataForm()}
        </Row>
      </Flex>
    </Flex>
  );
}

export default CustomPaymentForm;
