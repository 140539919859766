import {
  PlusOutlined, EditOutlined, DeleteOutlined, DownOutlined, UpOutlined,
} from '@ant-design/icons';
import {
  Button, Divider, Empty,
  Flex, Input, InputNumber, List,
  Skeleton, Space, Table, Typography,
} from 'antd';
import AmountInput from 'components/AmountInput';
import DietaryTags from 'components/DietaryTags';
import useIsDesktop from 'hooks/useIsDesktop';
import React, {
  useMemo,
  useState,
} from 'react';
import { priceTransformer } from 'utils';
import { getImage } from 'utils/image';
import { generateColumns } from 'utils/table';
import QuantityInput from 'components/QuantityInput';
import { renderServingSizeLabel } from 'utils/orders';
import { getItemTotalPrice, getItemTotalRealPrice } from './ItemDetailOverlay/item';
import ItemPrice from './AddOrderItemOverlay/ItemPrice';
import AddOrderItemOverlay from './AddOrderItemOverlay';
import ItemDetailOverlay from './ItemDetailOverlay';
import { ItemsInputContext } from './constants';

const { TextArea } = Input;

function ItemsInput({
  value: orderItems = [], onChange, merchant, loading, isEditMode = true, isCatering,
  validating,
}) {
  const [showAddItem, setShowAddItem] = useState(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState();
  const isDesktop = useIsDesktop();

  const handleRemoveOrderItem = (orderItemId) => {
    onChange(orderItems.filter(({ id }) => id !== orderItemId));
  };

  function handleRemoveOrderOption({ orderItemId, orderOptionId }) {
    onChange(orderItems.map((orderItem) => {
      if (orderItem.id === orderItemId) {
        return {
          ...orderItem,
          order_options: orderItem.order_options.filter((or) => or.modifier_option.id !== orderOptionId),
        };
      }
      return orderItem;
    }));
  }

  const normalizedColumns = useMemo(() => generateColumns([
    {
      title: 'item',
      key: 'item_related.name',
      width: 250,
      render: (_, { item_related }) => (
        <Flex align="center">
          {getImage({ data: item_related }) && (
            <img
              className="image"
              src={getImage({ data: item_related })}
              alt={item_related?.name || ''}
              width={45}
              height={45}
              style={{ border: '1px solid #DDE1E4', borderRadius: 5, marginRight: 14 }}
            />
          )}
          <Typography.Text>
            {item_related?.name || ' - '}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'size',
      key: 'size',
      width: 140,
      render: (size, { item_related }) => (
        <Space direction="vertical">
          <Typography>{size?.name || 'Default'}</Typography>
          {Boolean(size?.serving_size) && (
            <Typography.Text className="text-xs text-[#00000073]">
              {renderServingSizeLabel(size?.serving_size) }
            </Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: 'base price',
      key: 'real_price',
      dataIndex: 'real_price',
      width: 130,
      render: (value, orderItem) => (
        isEditMode ? (
          <Flex vertical>
            <AmountInput
              value={value}
              onChange={(real_price) => {
                if (real_price === 0 || real_price) {
                  onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, real_price } : i)));
                }
              }}
            />
            <Typography.Text
              type="secondary"
              style={{
                marginBottom: -15,
                fontSize: 10,
                whiteSpace: 'nowrap',
              }}
            >
              Without markup
            </Typography.Text>
          </Flex>
        ) : priceTransformer(value)
      )
      ,
    },
    {
      title: '',
      key: 'x',
      width: 30,
      render: () => 'x',
    },
    {
      title: 'quantity',
      key: 'qty',
      width: 130,
      render: (_, orderItem) => (
        <Flex gap={8} align="center">
          {isCatering ? (
            <InputNumber
              suffix={orderItem.item_related.catering_label}
              value={orderItem.qty || 0}
              onChange={(qty) => {
                onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, qty } : i)));
              }}
              className="w-full"
              min={1}
            />
          ) : (
            <QuantityInput
              min={1}
              value={orderItem.qty || 0}
              onChange={(qty) => {
                onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, qty } : i)));
              }}
            />
          )}
        </Flex>
      ),
    },
    isEditMode && {
      title: '',
      key: '+',
      width: 30,
      render: () => '+',
    },
    isEditMode && {
      title: 'markup',
      key: 'markup',
      width: 45,
      render: (_) => {
        if (!merchant || !(merchant.markup_fixed && merchant.markup)) {
          return '-';
        }
        if (merchant.should_use_fixed_markup) {
          return priceTransformer(merchant.markup_fixed);
        }
        return `%${merchant.markup}`;
      },
    },
    {
      title: '',
      key: '=',
      width: 30,
      render: () => '=',
    },
    {
      title: 'price',
      key: 'price',
      width: 185,
      render: (_, orderItem) => (
        <Space direction="vertical">
          {validating ? <Skeleton.Button active /> : (
            <>
              <ItemPrice
                price={(orderItem.value * orderItem.qty) || getItemTotalRealPrice(orderItem)}
                discountedPrice={orderItem.total_value || getItemTotalPrice(orderItem)}
                showPercentage={false}
                priceColor="#131B1F"
              />
              {Boolean(orderItem.size?.serving_size) && (
                <Typography.Text className="text-xs text-[#00000073]">
                  { priceTransformer((orderItem.total_value || getItemTotalPrice({ ...orderItem, qty: 1 })) / orderItem.size?.serving_size)}
                  /person
                </Typography.Text>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: (
        <div style={{ textAlign: 'right' }}>
          <Button
            icon={<PlusOutlined />}
            type={isEditMode ? 'default' : 'primary'}
            onClick={() => setShowAddItem(true)}
          >
            Add item
          </Button>
        </div>
      ),
      width: 100,
      render: (_, data) => (
        <Flex style={{ justifyContent: 'right' }}>
          <Button type="text" icon={<EditOutlined />} onClick={() => setSelectedOrderItem(data)} />
          <Button type="text" icon={<DeleteOutlined />} danger onClick={() => handleRemoveOrderItem(data.id)} />
        </Flex>
      ),
    },
  ].filter((c) => c)), [validating, orderItems]);
  const renderRowDetail = (orderItem, index) => (
    <Flex
      vertical
      gap={8}
      className="py-5 bg-[#E6F3EB]"
    >
      {(orderItem.item_related.description || orderItem.item_related?.dietary_tags?.length > 0) && (
        <>
          <Flex vertical gap={12} className="pl-14 pr-4">
            <Typography.Text className="text-base font-bold">Description:</Typography.Text>
            {orderItem.item_related.description && (
              <Typography.Text>{orderItem.item_related.description}</Typography.Text>
            )}
            <DietaryTags tags={orderItem.item_related?.dietary_tags} maxCount={orderItem.item_related?.dietary_tags?.length} hasTagStyle={false} />
          </Flex>
          <Divider style={{ margin: '16px 0' }} />
        </>
      )}

      {orderItem.order_options?.length > 0 && (
        <>
          <Flex vertical gap={12} className="pl-14 pr-4">
            <Typography.Text className="text-base font-bold">Modifiers:</Typography.Text>
            <List
              itemLayout="horizontal"
              dataSource={orderItem.order_options}
              renderItem={(op) => (
                <Flex className="py-2" justify="space-between">
                  <Flex className="w-full">
                    <Space className="w-[495px] min-w-0 flex-shrink-0">
                      <Typography.Text ellipsis className="font-bold">
                        {op.qty ? `${op.qty} x ` : ''}
                        {op.modifier_option.title}
                      </Typography.Text>
                      <DietaryTags tags={op.modifier_option?.dietary_tags} hasTagStyle={false} />
                    </Space>

                    <Typography.Text className="flex-shrink-1 font-semibold">{priceTransformer(op.real_price)}</Typography.Text>
                  </Flex>
                  {
                    !op.modifier_option.modifier.mandatory && (
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          handleRemoveOrderOption({ orderItemId: orderItem.id, orderOptionId: op.modifier_option.id });
                        }}
                        className="text-[#00000073]"
                      />
                    )
                  }
                </Flex>
              )}
            />
          </Flex>
          <Divider style={{ margin: '16px 0' }} />
        </>
      )}

      <Flex vertical gap={12} className="pl-14 pr-4">
        <Typography.Text className="text-base font-bold">Special notes:</Typography.Text>
        <TextArea
          value={orderItem.message}
          onChange={(e) => {
            onChange(orderItems.map((i) => (i.id === orderItem.id ? { ...i, message: e.target.value } : i)));
          }}
          rows={2}
          placeholder="Special notes"
          maxLength={100}
          style={{ maxWidth: 360 }}
        />
      </Flex>
    </Flex>
  );
  const contextValue = useMemo(() => ({
    selectedOrderItem,
  }), [selectedOrderItem]);

  if (loading) {
    return <Skeleton paragraph={{ rows: 3 }} />;
  }
  return (
    <ItemsInputContext.Provider value={contextValue}>
      <Table
        columns={normalizedColumns}
        dataSource={orderItems}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: renderRowDetail,
          columnWidth: 20,
          expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
            <UpOutlined onClick={(e) => onExpand(record, e)} />
          ) : (
            <DownOutlined onClick={(e) => onExpand(record, e)} />
          )),
        }}
        locale={{
          emptyText: <Empty description="No Data">
            {!isDesktop && (
              <Button
                icon={<PlusOutlined />}
                type={isEditMode ? 'default' : 'primary'}
                onClick={() => setShowAddItem(true)}
              >
                Add item
              </Button>
            )}
          </Empty>,
        }}
        scroll={{ x: orderItems?.length ? 1070 : 900 }}
      />
      <AddOrderItemOverlay
        open={showAddItem}
        merchantId={merchant?.id}
        onClose={() => setShowAddItem(false)}
        onAdd={setSelectedOrderItem}
        value={orderItems}
        onChange={onChange}
        isCatering={isCatering}
      />
      <ItemDetailOverlay
        nested={showAddItem}
        onChange={(newOrderItem) => {
          if (orderItems.some((oi) => oi.id === newOrderItem.id)) {
            onChange(orderItems.map((o) => (o.id !== newOrderItem.id ? o : newOrderItem)));
          } else {
            onChange([...orderItems, newOrderItem]);
          }
        }}
        onClose={() => setSelectedOrderItem(null)}
        handleAddItem={(item) => {
          onChange(item);
        }}
        isCatering={isCatering}
        editablePrice={isEditMode}
      />
    </ItemsInputContext.Provider>

  );
}

export default ItemsInput;
