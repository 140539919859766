import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Space, Tag
} from 'antd';
import { EMPTY_ARRAY } from 'constants';
import PhoneInput from 'react-phone-input-2';

function SMSNotificationReceivers({ onChange, value: phones = EMPTY_ARRAY }) {
  const [isAdding, setIsAdding] = useState(false);
  const [newPhone, setNewPhone] = useState('');

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleSubmit = () => {
    if (newPhone.trim()) {
      onChange([...phones, newPhone.trim()]);
      setNewPhone('');
      setIsAdding(false);
    }
  };

  const handleRemove = (phone) => {
    onChange(phones.filter((p) => p !== phone));
  };

  const handleCancel = () => {
    setNewPhone('');
    setIsAdding(false);
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Space wrap>
        {phones.map((phone) => (
          <Tag
            key={phone}
            closable
            onClose={() => handleRemove(phone)}
            style={{ padding: '4px 8px' }}
            color="green"
          >
            {`+${phone.slice(0, phone.length - 10)} ${phone.slice(-10, -7)} ${phone.slice(-7, -4)} ${phone.slice(-4)}`}
          </Tag>
        ))}

        {isAdding ? (
          <Space.Compact>
            <PhoneInput
              country="us"
              buttonClass="phone-country"
              inputClass="input"
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
              }}
              value={newPhone}
              onChange={(value, country) => {
                setNewPhone(value);
              }}
              inputStyle={{ width: '100%', height: 32 }}
              placeholder="Recipient phone"
              autofocus
              preferredCountries={['us', 'ca']}
              enableSearch
              regions={['america', 'europe']}
              countryCodeEditable={false}
              masks={{
                pr: '(...) ...-....',
              }}
            />
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              Add
            </Button>
          </Space.Compact>
        ) : (
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add phone number
          </Button>
        )}
      </Space>
    </Space>
  );
}

export default SMSNotificationReceivers;
