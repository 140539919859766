import { Space, Typography } from 'antd';
import { EmptyTableIcon } from 'assets/icons';
import BaseTable from 'components/Table';
import withGuard from 'components/withGuard';
import { AdminContext } from 'constants/contexts';
import { PERMISSIONS } from 'constants/permissions';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router';
import { captureException } from 'utils/errors';
import { generateColumns } from 'utils/table';
import ReportToolbar from '../../components/ReportToolbar';
import ReportLayout from '../../layout';
import columns from './columns';
import { fetchPayoutsReport } from './requests';

function PayoutsReport() {
  const [filterData, setFilterData] = useState({ selected: { date: { range: null, type: 'all' } } });
  const resetFetchRef = useRef(false);
  const { organizationId } = useContext(AdminContext);

  const fetchReport = useCallback(
    (pagination) => {
      const page = resetFetchRef.current ? 1 : pagination.current;
      const queries = {
        page,
        page_size: pagination.pageSize,
        paid_at_after: filterData.selected?.date.type !== 'all' && dayjs(filterData.selected?.date.range[0])
          .format('YYYY-MM-DD'),
        paid_at_before: filterData.selected?.date.type !== 'all' && dayjs(filterData.selected?.date.range[1])
          .format('YYYY-MM-DD'),
      };
      resetFetchRef.current = false;

      return fetchPayoutsReport(queries)
        .then(({ data: { data: { results, count } } }) => ({
          data: results,
          total: count,
          page,
        }))
        .catch(captureException);
    },
    [
      organizationId,
      filterData,
    ],
  );
  const normalizedColumns = useMemo(() => generateColumns(columns), [columns]);

  return (
    <ReportLayout
      title="Payouts report"
      defaultRoute="/console/reports"
    >
      <ReportToolbar
        filterData={{ ...filterData }}
        setFilterData={setFilterData}
        hasLocationFilter={false}
        type="payouts"
        hasExport={false}
      />

      <BaseTable
        columns={normalizedColumns}
        fetchFn={fetchReport}
        empty={(
          <Space direction="vertical" className="my-10 mx-5">
            <EmptyTableIcon/>
            <Typography.Paragraph>There are no records to display for the selected date.</Typography.Paragraph>
          </Space>
        )}
        scroll={{ x: 500 }}
        pagination={{
          showTotal: (total) => `Total ${total} items`,
        }}
      />

      <Outlet/>
    </ReportLayout>
  );
}

export default withGuard(PayoutsReport, [PERMISSIONS.REPORTS], { redirect: true });
