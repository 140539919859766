import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col,
  Row,
} from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import { useNavigate } from 'react-router';
import MenuSelector from '../Menus/MenuSelector';

function MenuToolbar({
  onCreate, onEdit, menus, selectedMenuId, loading, hasCategory,
}) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Row gutter={[16, 16]} className="w-full">
      <Col xs={24} md={12} lg={8} xl={8}>
        <MenuSelector
          menus={menus}
          selectedMenuId={selectedMenuId}
          onCreate={onCreate}
          onChange={(value) => navigate(`/console/menus/${value}`)}
        />
      </Col>
      <Col xs={24} md={6} lg={4} xl={4}>
        <Button
          icon={<EditOutlined />}
          block={isMobile}
          disabled={loading}
          onClick={onEdit}
        >
          Edit menu
        </Button>
      </Col>
      <Col xs={24} md={6} lg={12} xl={12} style={{ textAlign: 'end' }}>
        <Button
          icon={<PlusOutlined />}
          block={isMobile}
          type={hasCategory ? 'default' : 'primary'}
          disabled={loading}
          onClick={() => navigate(`/console/menus/${selectedMenuId}/category/add`)}
        >
          Create category
        </Button>
      </Col>
    </Row>
  );
}

export default MenuToolbar;
