import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Table } from 'antd';
import EmptyComponent from 'components/Empty';
import React, { useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { captureException } from 'utils/errors';
import { EventEmitter } from 'utils/event';
import http, { getHttpErrorMessage } from 'utils/http';
import { generateColumns } from 'utils/table';
import { MenuContext } from '../constants';
import columns from './columns';
import DeleteDisableModal from './DeleteDisableModal';
import EditItemDrawer from './EditItemDrawer';

function List({
  items, isItemPool, loading, searchTerm, selectedRowKeys, onSelect,
  onCreate,
}) {
  const { isGlobal } = useContext(MenuContext);
  const [currentItem, setCurrentItem] = useState();
  const { message } = App.useApp();
  const [removeLoading, setRemoveLoading] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [loadingItemIds, setLoadingItemIds] = useState([]);

  const onEdit = (itemId) => {
    setEditingItemId(itemId);
  };

  const onRemove = (item) => {
    setCurrentItem(item);
  };

  const onRemoveConfirm = () => {
    setRemoveLoading(true);
    return http.delete(`/v1/organizations/{{organizationId}}/global-items/${currentItem.id}`).then(() => {
      EventEmitter.emit('refresh_items');
    }).catch((e) => {
      captureException(e);
      message.error(getHttpErrorMessage(e));
    }).finally(() => {
      setCurrentItem();
      setRemoveLoading(false);
    });
  };

  const onChangeItemAvailability = (itemId) => {
    setLoadingItemIds((current) => [...current, itemId]);
    return http.post(`v1/items/${itemId}/status/`)
      .then(() => EventEmitter.emit('refresh_items'))
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingItemIds((current) => current.filter((id) => id !== itemId));
        }, 1500);
      });
  };

  const normalizedColumns = useMemo(
    () => generateColumns(columns({
      isItemPool, isGlobal, onEdit, onRemove, onChangeItemAvailability, loadingItemIds,
    })),
    [isGlobal, isItemPool, loadingItemIds],
  );

  if (!loading && !items.length && !searchTerm) {
    return (
      <EmptyComponent
        title={isGlobal ? 'Start by adding your first item to build your restaurant\'s menu effortlessly!' : 'Please contact your organization\'s manager to create or edit an item.'}
        primaryAction={isGlobal && (
          <Button icon={<PlusOutlined />} onClick={onCreate} type="primary">
            Create item
          </Button>
        )}
      />
    );
  }
  return (
    <>
      <Table
        columns={normalizedColumns}
        dataSource={items}
        scroll={{ x: 780 }}
        rowSelection={isItemPool && {
          onChange: (selectedRowKeys) => {
            onSelect?.(selectedRowKeys);
          },
          selectedRowKeys,
        }}
        loading={loading || removeLoading}
        pagination={!isItemPool && {
          showTotal: (total) => `Total ${total} results`,
        }}
        rowClassName="cursor-pointer"
      />
      <EditItemDrawer
        itemId={editingItemId}
        onClose={() => {
          setEditingItemId(null);
          EventEmitter.emit('refresh_items');
        }}
      />
      <DeleteDisableModal
        item={currentItem}
        open={Boolean(currentItem)}
        setOpen={() => setCurrentItem(null)}
        isDelete={isGlobal}
        onConfirm={isGlobal ? onRemoveConfirm : onChangeItemAvailability}
      />
    </>
  );
}

export default List;
