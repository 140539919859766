import EmptyComponent from 'components/Empty';
import BaseTable from 'components/Table';
import { AdminContext } from 'constants/contexts';
import { captureException } from 'utils/errors';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import InvoiceDetail from './InvoiceDetail';
import { fetchInvoices } from './requests';
import columns from './columns';

function BillingHistory() {
  const { organizationId } = useContext(AdminContext);
  const [open, setOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState();

  const getInvoices = useCallback(
    (pagination) => {
      const page = pagination.current;
      const queries = {
        page,
        page_size: pagination.pageSize,
      };
      return fetchInvoices(queries)
        .then(({ data: { data: { results, count } } }) => ({
          data: results,
          total: count,
          page,
        }))
        .catch((e) => captureException(e))
        .finally(() => {});
    },
    [organizationId],
  );

  const onDetailClick = (data) => {
    setCurrentInvoice(data);
    setOpen(true);
  };

  const onClose = () => {
    setCurrentInvoice();
    setOpen(false);
  };
  const tableColumns = useMemo(() => columns({
    onDetailClick,
  }), []);

  return (
    <>
      <BaseTable
        columns={tableColumns}
        fetchFn={getInvoices}
        updateInterval={1000 * 60 * 3}
        empty={(
          <EmptyComponent
            title=""
            description="Your billing history appears to be as blank as a brand new notebook — no transactions to display here yet!"

          />
        )}
        scroll={{ x: 400 }}
      />

      <InvoiceDetail invoice={currentInvoice} open={open} onClose={onClose} />
    </>
  );
}

export default BillingHistory;
