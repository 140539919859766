import { Tabs } from 'antd';
import BaseLayout from 'components/Layout';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import React from 'react';
import { Routes } from 'react-router';
import {
  Route,
  useLocation, useNavigate,
} from 'react-router-dom';
import Items from '../Items';
import Modifiers from '../Modifiers';
import Menus from './Menus';
import MenuProvider from '../MenuProvider';

function GlobalMenus() {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the active tab from the URL path
  const pathSegments = location.pathname.split('/');
  const lastPath = `/${pathSegments[pathSegments.length - 1]}`;

  // Define tab items
  const tabItems = [
    { key: '/', label: 'Menus' },
    { key: '/items', label: 'Items' },
    { key: '/modifiers', label: 'Modifiers' },
  ];
  const activeTab = tabItems.find((tab) => tab.key === lastPath) || tabItems[0];

  const handleTabChange = (key) => {
    navigate(`/console/menus${key}`, { replace: true });
  };

  return (
    <BaseLayout header={(
      <BaseLayout.Header title="Global menus" />
    )}
    >
      <Tabs
        activeKey={activeTab.key}
        onChange={handleTabChange}
        items={tabItems}
      />
      <MenuProvider isGlobal>
        <Routes>
          <Route path="items" element={<Items />} />
          <Route
            path="modifiers"
            element={(
              <Modifiers
                fetchUrl="v1/organizations/{{organizationId}}/global-modifiers/?all=true"
                hasCreateAction
              />
            )}
          />
          <Route path="/:menuId?/*" element={<Menus />} />
        </Routes>
      </MenuProvider>

    </BaseLayout>
  );
}

export default withGuard(GlobalMenus, [PERMISSIONS.GLOBAL_MENU], { redirect: true });
