import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex } from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import { isEqual } from 'lodash-es';
import React, {
  useEffect,
  useMemo, useState, useContext,
} from 'react';
import { Outlet } from 'react-router';
import { MenuContext } from '../constants';
import Items from './index';

function ItemsPoolDrawer({
  open, onClose, selectedItemIds, onAdd, onCreate,
}) {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys(selectedItemIds);
  }, [selectedItemIds]);

  function handleAdd() {
    if (!onAdd) {
      return;
    }
    setLoading(true);
    onAdd(selectedRowKeys).finally(() => {
      setLoading(false);
    });
  }

  function handleCreate() {
    if (!onCreate) {
      return;
    }
    onCreate();
  }

  const isDisabled = useMemo(
    () => loading
      || !selectedRowKeys.length
      || isEqual(selectedRowKeys.sort(), selectedItemIds.sort()),
    [selectedRowKeys, loading, selectedItemIds],
  );

  return (
    <Drawer
      title="Items"
      placement="right"
      width={isMobile ? '100%' : 840}
      onClose={onClose}
      open={open}
      styles={{ body: { padding: '16px 24px' } }}
      footer={(
        <Flex justify="space-between">
          <Button
            disabled={loading}
            onClick={handleCreate}
            icon={<PlusOutlined />}
          >
            Create item
          </Button>
          <Flex justify="end" gap={8}>
            <Button disabled={loading} onClick={onClose}>Cancel</Button>
            <Button
              loading={loading}
              disabled={isDisabled}
              type="primary"
              onClick={handleAdd}
            >
              Add selected
              item(s)
            </Button>
          </Flex>
        </Flex>
      )}
    >
      <Items
        isItemPool
        selectedRowKeys={selectedRowKeys}
        onSelect={setSelectedRowKeys}
      />
      <Outlet />
    </Drawer>
  );
}

export default ItemsPoolDrawer;
