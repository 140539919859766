import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/BadgeLabel';
import { FOODHUB, OMNI_MIGRATION_DRIVER } from 'constants/migrationTypes';
import React from 'react';

export const getOrderType = (order) => (order?.has_delivery ? (
  <BadgeLabel
    label="Delivery"
    leadingIcon={<DeliveryIcon />}
    classes="type-size p-1"
  />
) : (
  <BadgeLabel
    label="Pick Up"
    leadingIcon={<PickupIcon />}
    classes="type-size p-1"
  />
));

export const isSelfDelivery = ({ migration_driver: driver, source } = {}) => driver === FOODHUB && source?.toUpperCase() !== 'MERCHANT WEBSITE';
export const isOmniPartner = ({ migration_driver: driver, merchant: { is_partner: isPartner } = {} } = {}) => isOmni(driver) && isPartner;
export const isOmni = (driver) => OMNI_MIGRATION_DRIVER.includes(driver);
export const getItemValue = ({ value, migrationDriver }) => {
  switch (migrationDriver) {
  case 'DD':
    return value.replace('$', '');
  case 'FH':
    return value / 100;
  default:
    return value;
  }
};
