import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table } from 'antd';
import React from 'react';
import DraggableRow from './DraggableRow';

function DraggableTable(
  {
    columns,
    dataSource,
    setDataSource,
    onDragCallback,
    showHeader = true,
    pagination = false,
    bordered = false,
    size = 'small',
    CustomCell,
    ...rest
  },
) {
  const onDragEnd = ({ active, over }) => {
    if (!active?.id || !over?.id) {
      return;
    }
    if (active.id !== over.id) {
      const activeIndex = dataSource.findIndex((i) => i.key === active.id);
      const overIndex = dataSource.findIndex((i) => i.key === over.id);
      const reOrderedData = arrayMove(dataSource, activeIndex, overIndex)
        ?.map((item, index) => (item.item_position ? {
          ...item,
          item_position: [{ ...item.item_position?.[0], position: index + 1 }],
        } : item));
      setDataSource(reOrderedData);
      if (onDragCallback) {
        onDragCallback({ id: active.id, position: over.data.current.sortable.index + 1 });
      }
      return reOrderedData;
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: DraggableRow,
              cell: CustomCell,
            },
          }}
          bordered={bordered}
          showHeader={showHeader}
          pagination={pagination}
          rowKey="key"
          columns={columns}
          size={size}
          scroll={{ x: 800 }}
          dataSource={dataSource}
          {...rest}
        />
      </SortableContext>
    </DndContext>
  );
}

export default DraggableTable;
