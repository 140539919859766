import React from 'react';
import { ReactComponent as SunIcon } from 'assets/image/sun.svg';
import { Badge, Space, Typography } from 'antd';
import { DeliveryIcon, PickupIcon } from 'assets/icons';
import StopOutlinedIcon from '@ant-design/icons/StopOutlined';
import CalendarOutlinedIcon from '@ant-design/icons/CalendarOutlined';
import CheckOutlinedIcon from '@ant-design/icons/CheckOutlined';
import Icon from '@ant-design/icons';
import * as orderStatus from 'constants/status';
import { isOrderStatus } from 'utils/orders';

export const TABS = ({ hasNewOrder, hasNewScheduledOrder, isAdmin }) => [
  {
    key: 'Today',
    label: (
      <Space>
        <Icon component={SunIcon} />
        Today
        {' '}
        {hasNewOrder && <Badge dot />}
      </Space>
    ),
    state: '',
    state__not_in: [
      orderStatus.COMPLETED, orderStatus.CANCELLED_BY_RESTAURANT, orderStatus.REJECTED_BY_RESTAURANT, orderStatus.STRANDED, orderStatus.PAYMENT_SCHEDULED,
      !isAdmin && orderStatus.NEEDS_APPROVAL,
    ].filter(isOrderStatus).join(','),
    only_today: 'only_today',
    order: '-is_new,requested_pickup_time',
  },
  {
    key: 'Scheduled',
    label: (
      <Space>
        <CalendarOutlinedIcon />
        Scheduled
        {' '}
        {hasNewScheduledOrder && <Badge dot />}
      </Space>
    ),
    state: [
      orderStatus.NEW, orderStatus.IN_PROGRESS,
      isAdmin && orderStatus.NEEDS_APPROVAL, orderStatus.PAYMENT_SCHEDULED, orderStatus.ON_THE_WAY, orderStatus.READY,
    ].filter(isOrderStatus).join(','),
    state__not_in: '',
    only_scheduled: 'only_scheduled',
    order: '-is_new,requested_pickup_time',
  },
  {
    key: 'Completed',
    label: (
      <Space>
        <CheckOutlinedIcon />
        Completed
      </Space>
    ),
    state: `${orderStatus.COMPLETED},${orderStatus.DELIVERED}`,
    state__not_in: '',
    order: '-is_new,-timestamp',
  },
  {
    key: 'Cancelled',
    label: (
      <Space>
        <StopOutlinedIcon />
        Cancelled
      </Space>
    ),
    state: `${orderStatus.CANCELLED_BY_RESTAURANT},${orderStatus.REJECTED_BY_RESTAURANT}`,
    state__not_in: '',
    order: '-is_new,-timestamp',
  },
];

export const TYPE_OPTIONS = [
  {
    value: null,
    label: 'All Order Types',
  },
  {
    value: 1,
    label: (
      <div className="flex items-center">
        <PickupIcon />
        <Typography.Text className="ml-1">Pick up</Typography.Text>
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className="flex items-center">
        <DeliveryIcon />
        <Typography.Text className="ml-1">Delivery</Typography.Text>
      </div>
    ),
  },
];

export const FILTERS = [
  'In progress',
  'All orders',
];
