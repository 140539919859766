import DraggableTable from 'components/DraggableTable';
import { orderBy } from 'lodash-es';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import { OptionMode } from '../constants';
import ModifierDrawer from '../index';
import columns from './columns';

function List({ options, setOptions, setEditedOption }) {
  const { isGlobal, modifiers } = useContext(MenuContext);
  const [currentModifier, setCurrentModifier] = useState();
  const [open, setOpen] = useState(false);
  const onRemove = useCallback((e, id) => {
    e.preventDefault();
    const newOptions = options.map((o) => {
      if (o.id ? o.id === id : o.localId === id) {
        o.type = 'DELETED';
      }
      return o;
    });
    setOptions(newOptions);
  }, [options, setOptions]);
  const onEdit = useCallback((modifier) => {
    if (modifier.modifiers_children?.length) {
      setCurrentModifier(modifier);
      setOpen(true);
    } else {
      setEditedOption(modifier);
    }
  }, [setEditedOption, setCurrentModifier, setOpen]);

  const getNestedOptions = useCallback((modifierChildren) => {
    if (typeof modifierChildren[0] !== 'number') {
      return modifierChildren;
    }
    const nestedOptions = [];
    modifierChildren.forEach((childId) => {
      const modifier = modifiers.find((m) => m.id === childId);
      nestedOptions.push(modifier);
    });
    return nestedOptions;
  }, [modifiers]);

  const onChangeModifierAvailability = useCallback((optionId) => {
    const modifiedOptions = options.map((o) => {
      if (o.id ? o.id === optionId : o.localId === optionId) {
        o.is_active = !o.is_active;
      }
      return o;
    });
    setOptions(modifiedOptions);
  }, [options, setOptions]);

  const tableColumns = useMemo(() => columns({
    isGlobal,
    onRemove,
    onEdit,
    getNestedOptions,
    onChangeModifierAvailability,
  }), [isGlobal, onEdit, onRemove, getNestedOptions, onChangeModifierAvailability]);

  const sortedOptions = useMemo(() => {
    const indexedOptions = options?.map((option) => ({
      ...option,
      key: (option.id || option.localId),
    })).filter((o) => o.type !== OptionMode.DELETED) || [];
    return orderBy(indexedOptions, ['position'], ['asc']);
  }, [JSON.stringify(options)]);

  const reorderPositions = (items) => {
    const reorderedOptions = items.map((item, index) => ({
      ...item,
      position: index + 1,
      type: item.position === index + 1 ? item.type : OptionMode.UPDATED,
    }));
    setOptions(reorderedOptions);
  };

  const onChangeNested = () => {
    setCurrentModifier();
  };

  return (
    <>
      {sortedOptions.length > 0 && (
        <DraggableTable
          columns={tableColumns}
          dataSource={sortedOptions}
          setDataSource={reorderPositions}
          showHeader={false}
        />
      )}

      <ModifierDrawer
        open={open}
        onClose={() => setOpen(false)}
        modifier={currentModifier}
        handleNested={onChangeNested}
      />
    </>
  );
}

export default List;
