import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Input, Space, Tag,
} from 'antd';
import { EMPTY_ARRAY } from 'constants';

function EmailNotificationReceivers({ onChange, value: emails = EMPTY_ARRAY }) {
  const [isAdding, setIsAdding] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (newEmail.trim() && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail.trim())) {
      onChange([...emails, newEmail.trim()]);
      setNewEmail('');
    }
    setIsAdding(false);
  };

  const handleRemoveEmail = (email) => {
    onChange(emails.filter((em) => em !== email));
  };

  const handleCancel = () => {
    setNewEmail('');
    setIsAdding(false);
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Space wrap>
        {emails.map((email) => (
          <Tag
            key={email}
            closable
            onClose={() => handleRemoveEmail(email)}
            style={{ padding: '4px 8px' }}
            color="green"
          >
            {email}
          </Tag>
        ))}

        {isAdding ? (
          <Space.Compact>
            <Input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter email"
              style={{ width: 200 }}
              onPressEnter={handleSubmit}

            />
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              Add
            </Button>
          </Space.Compact>
        ) : (
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add email
          </Button>
        )}
      </Space>
    </Space>
  );
}

export default EmailNotificationReceivers;
