import {
  App, Button, Drawer, Flex, Form, Skeleton,
} from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import { EMPTY_OBJECT } from 'constants';
import useFetchedData from 'hooks/useFetchedData';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';
import http, { getHttpErrorMessage } from 'utils/http';
import { EventEmitter } from 'utils/event';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import { OptionMode } from './constants';
import GeneralInfo from './GeneralInfo';
import Options from './Options';
import Rules from './Rules';

function ModifierDrawer({
  open,
  onClose,
  modifier: initialModifier,
  handleNested,
  merchantId,
}) {
  const isMobile = useIsMobile();
  const { message, modal } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isEditMode = Boolean(initialModifier);
  const { isGlobal } = useContext(MenuContext);
  const editable = isGlobal;

  const { data: fetchedModifier = initialModifier, loading: initialLoading } = useFetchedData(
    isEditMode && (isGlobal ? `v1/organizations/{{organizationId}}/global-modifiers/${initialModifier.id}/` : `v1/modifiers/${initialModifier.id}/`),
    (res) => (isGlobal ? res?.data : res) || EMPTY_OBJECT,
  );

  const modifier = useMemo(() => ({
    ...initialModifier,
    ...fetchedModifier,
  }), [fetchedModifier, initialModifier, isEditMode]);

  useEffect(() => {
    if (fetchedModifier) {
      form.setFieldsValue(deserialize({ isGlobal, modifier: fetchedModifier }));
    }
  }, [fetchedModifier, isGlobal]);

  const handleSubmit = async () => {
    setLoading(true);
    const baseURL = isGlobal ? 'v1/organizations/{{organizationId}}/global-modifiers/' : 'v1/modifiers/';

    const serializedModifier = serialize({ data: form.getFieldsValue(), isGlobal, merchantId });
    (isEditMode ? http.put(`${baseURL}${modifier.id}/`, serializedModifier) : http.post(baseURL, serializedModifier))
      .then((result) => {
        message.success(`Modifier ${isEditMode ? 'updated' : 'created'} successfully!`);
        handleNested && handleNested(result.data);
        handleClose();
        EventEmitter.emit('refresh_modifiers');
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onConfirmDelete = () => {
    setLoading(true);
    return http.delete(`v1/organizations/{{organizationId}}/global-modifiers/${modifier.id}/`).then((res) => {
      EventEmitter.emit('refresh_modifiers');
      handleClose();
    })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => setLoading(false));
  };

  const showDeleteConfirm = () => {
    modal.confirm({
      title: 'Are you sure you want to delete this modifier?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: onConfirmDelete,
    });
  };

  return (
    <Drawer
      title={`${isEditMode ? 'Edit' : 'Create'} modifier`}
      open={open}
      onClose={handleClose}
      width={isMobile ? '100%' : 575}
      styles={{ body: { padding: '16px 0 84px' } }}
    >
      {initialLoading && <Skeleton paragraph={{ rows: 10 }} className="px-5" />}
      {!initialLoading && (
        <Form
          form={form}
          layout="vertical"
          onFinishFailed={() => {
            message.error('Please fix errors!');
          }}
          onFinish={handleSubmit}
        >

          <GeneralInfo disabled={!editable} />
          <FormItem name="options" fullWidth>
            <Options disabled={!editable} />
          </FormItem>
          <Rules disabled={!editable} />

          <FormLayout.Footer>
            <Flex justify={isEditMode && isGlobal ? 'space-between' : 'flex-end'} className="w-full">
              {isEditMode && isGlobal && (
                <Button danger disabled={loading} onClick={showDeleteConfirm}>Delete</Button>
              )}
              <Flex justify="end" gap={8}>
                <Button disabled={loading} onClick={handleClose}>Close</Button>
                <Button htmlType="submit" loading={loading} type="primary">{isEditMode ? 'Save' : 'Create'}</Button>
              </Flex>
            </Flex>
          </FormLayout.Footer>
        </Form>
      )}
    </Drawer>
  );
}

function serialize({ isGlobal, merchantId, data }) {
  const options = data.options?.filter((o) => !(!o.id && o.type === OptionMode.DELETED))
    .map((option) => ({
      ...option,
      [isGlobal ? 'name' : 'title']: option.name,
      price: option.price?.toString() || 0,
    }));

  return {
    ...data,
    restaurant: merchantId && Number(merchantId),
    [isGlobal ? 'name' : 'title']: data.name,
    options,
    modifier_type: 2,
  };
}

function deserialize({ isGlobal, modifier }) {
  if (!modifier) {
    return {};
  }
  const options = modifier.options?.map((option) => ({
    ...option,
    is_active: option.is_active !== undefined ? option.is_active : true,
    name: option[isGlobal ? 'name' : 'title'],
    price: option.price?.toString() || null,
    type: OptionMode.NOT_CHANGED,
    modifiers_children: option.modifiers_children?.length ? [option.id || option.localId] : [],
  }));

  return {
    ...modifier,
    name: modifier[isGlobal ? 'name' : 'title'],
    options,
  };
}

export default ModifierDrawer;
