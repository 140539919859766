import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React from 'react';

function Sizes({ disabled }) {
  const { message } = App.useApp();
  const sizes = Form.useWatch('sizes');

  return (
    <FormLayout.Section>
      <Row gutter={8} align="middle" className="mb-1">
        <Col span={5}><Typography.Text strong>Price</Typography.Text></Col>
        {
          sizes?.length > 1 && (
            <Col span={7}><Typography.Text strong>Size</Typography.Text></Col>
          )
        }
        <Col span={7}><Typography.Text strong>Servings per unit</Typography.Text></Col>

      </Row>
      <Form.List name="sizes">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={8} align="start">
                <Col span={5}>
                  <FormItem
                    {...restField}
                    name={[name, 'price']}
                    rules={[{ required: true, message: 'Price is required' }]}
                    style={{ marginBottom: 8 }}
                  >
                    <InputNumber
                      placeholder="Price"
                      prefix="$"
                      min={0}
                      style={{ width: '100%' }}
                      disabled={disabled}
                    />
                  </FormItem>
                </Col>
                {fields.length !== 1 && (
                  <Col span={7}>
                    <FormItem
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Name is required' }]}
                      style={{ marginBottom: 8 }}
                    >
                      <Input placeholder="Size name" disabled={disabled}/>
                    </FormItem>
                  </Col>
                )}
                <Col span={7}>
                  <FormItem
                    {...restField}
                    name={[name, 'serving_size']}
                    style={{ marginBottom: 8 }}
                  >
                    <InputNumber
                      placeholder="Serving size"
                      min={1}
                      defaultValue={1}
                      style={{ width: '100%' }}
                      disabled={disabled}
                    />
                  </FormItem>
                </Col>
                {fields.length > 1 && index !== (fields.length - 1) && (
                  <Col span={5} className="self-baseline">
                    <Button
                      type="text"
                      icon={<MinusOutlined/>}
                      onClick={() => remove(name)}
                      disabled={disabled}
                    />
                  </Col>
                )}
                {(fields.length === 0 || index === (fields.length - 1)) && (
                  <Col span={fields.length !== 1 ? 5 : 12} className="self-baseline text-right">
                    <Button
                      onClick={() => fields.length < 4 ? add() : message.error('You can define just 4 sizes!')}
                      icon={<PlusOutlined/>}
                      block={fields.length !== 1}
                      disabled={disabled}
                    >
                      Add {index === 0 ? 'Size' : ''}
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </FormLayout.Section>
  );
}

export default Sizes;
