import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Row, Typography,
} from 'antd';
import SearchItemInput from 'components/ItemsInput/AddOrderItemOverlay/SearchItemInput';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import { MenuContext } from '../constants';

function Toolbar({
  isItemPool, searchTerm, setSearchTerm, hasItem,
  onCreate, loading,
}) {
  const { isGlobal } = useContext(MenuContext);
  const isMobile = useIsMobile();

  return (
    <Row gutter={[16, 16]} className="w-full">
      <Col xs={24} md={12} lg={10}>
        <SearchItemInput value={searchTerm} onChange={setSearchTerm} placeholder="Item name" />
      </Col>

      {!isItemPool && (
        <>
          {isGlobal && (
            <Col xs={24} md={12} lg={14} style={{ textAlign: 'end' }}>
              <Button
                icon={<PlusOutlined />}
                block={isMobile}
                type={(hasItem || searchTerm) ? 'default' : 'primary'}
                disabled={loading}
                onClick={onCreate}
              >
                Create item
              </Button>
            </Col>
          )}

          {!isGlobal && (
            <Col xs={24} md={12} lg={14} className="xl:text-end m-auto">
              <Typography.Text>
                Please contact your organization&apos;s manager to create or edit an item.
              </Typography.Text>
            </Col>
          )}
        </>
      )}

    </Row>
  );
}

export default Toolbar;
