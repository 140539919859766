import { Empty, Flex, Typography } from 'antd';
import campaign from 'assets/image/campaign.svg';
import React from 'react';

function EmptyComponent({
  title = 'No data!', description, icon = campaign, primaryAction, secondaryAction,
}) {
  return (
    <Empty
      image={icon}
      style={{ marginTop: '15%', marginBottom: '10%' }}
      imageStyle={{
        height: 60,
        marginBottom: 20,
      }}
      description={(
        <div>
          <Typography.Title level={5}>
            {title}
          </Typography.Title>
          <Typography.Text>
            {description}
          </Typography.Text>
        </div>
      )}
    >
      <Flex gap={16} justify="center">
        {primaryAction}
        {secondaryAction}
      </Flex>
    </Empty>
  );
}

export default EmptyComponent;
