import { EditOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Row,
} from 'antd';
import SearchItemInput from 'components/ItemsInput/AddOrderItemOverlay/SearchItemInput';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import MenuSelector from '../Menus/MenuSelector';

function MenuToolbar({
  menus, selectedMenuId, searchTerm, onSearchTermChange, loading, onEditMenu,
}) {
  const isMobile = useIsMobile();

  return (
    <Row gutter={[16, 16]} className="w-full">
      <Col xs={24} md={12} lg={8} xl={8}>
        <MenuSelector menus={menus} selectedMenuId={selectedMenuId} disabled />
      </Col>
      <Col xs={24} md={12} lg={16}>
        <Flex gap={16}>
          <SearchItemInput
            value={searchTerm}
            onChange={onSearchTermChange}
            placeholder="Search ..."
          />

          <Button
            icon={<EditOutlined />}
            block={isMobile}
            disabled={loading}
            onClick={() => onEditMenu()}
          >
            Edit menu
          </Button>
        </Flex>

      </Col>

    </Row>
  );
}

export default MenuToolbar;
