import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete, Button, Col, Input, Row, Switch, Typography,
} from 'antd';
import DraggableTable from 'components/DraggableTable';
import FormLayout from 'components/FormLayout';
import useIsMobile from 'hooks/useIsMobile';
import { debounce, orderBy } from 'lodash-es';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { MenuContext } from '../../constants';
import ModifierDrawer from '../../Modifiers/ModifierDrawer';
import { getNormalizedFilteredModifiers } from '../../Modifiers/utils';

function Modifiers({ value: modifiers = [], onChange }) {
  const isMobile = useIsMobile();
  const [openModifierDrawer, setOpenModifierDrawer] = useState(false);

  const { isGlobal, modifiers: allModifiers } = useContext(MenuContext);

  const [modifierOptions, setModifierOptions] = useState(getNormalizedFilteredModifiers({
    modifiers: allModifiers,
    excludeNested: true,
  }));

  useEffect(() => {
    if (allModifiers) {
      setModifierOptions(getNormalizedFilteredModifiers({
        modifiers: allModifiers,
        excludeNested: true,
      }));
    }
  }, [allModifiers]);

  const onAddModifier = (_, modifier) => {
    const isDuplicate = modifiers?.some((m) => m.id === modifier.id);
    if (!isDuplicate) {
      onChange([...modifiers, modifier]);
    }
  };
  const onRemoveModifier = (e, id) => {
    e.preventDefault();
    const newModifiers = modifiers?.filter((m) => m.id !== id);
    onChange(newModifiers);
  };

  const onChangeModifierAvailability = useCallback((optionId) => {
    const modifiedOptions = modifiers.map((o) => {
      if (o.id ? o.id === optionId : o.localId === optionId) {
        o.is_active = !o.is_active;
      }
      return o;
    });
    onChange(modifiedOptions);
  }, [modifiers, onChange]);

  const columns = [
    {
      key: 'sort',
      width: 30,
      align: 'right',
    },
    {
      title: 'Title',
      key: isGlobal ? 'name' : 'title',
      dataIndex: isGlobal ? 'name' : 'title',
      editable: true,
    },
    // !isGlobal && {
    //   title: 'Item availability',
    //   key: 'is_active',
    //   dataIndex: 'is_active',
    //   width: 80,
    //   align: 'center',
    //   render: (value, record) => (
    //     <Switch checked={value} onChange={() => onChangeModifierAvailability?.(record.id)}/>
    //   ),
    // },
    isGlobal && {
      title: '',
      key: 'operation',
      width: 70,
      align: 'center',
      render: (_, record) => (
        <Button icon={<DeleteOutlined />} type="text" onClick={(e) => onRemoveModifier(e, record.id)} />
      ),
    },
  ].filter((c) => c);

  const sortedModifiers = useMemo(() => {
    const indexedModifiers = modifiers?.map((mod) => ({ ...mod, key: mod.id })) || [];
    return orderBy(indexedModifiers, ['position'], ['asc']);
  }, [modifiers]);

  const reorderPositions = (data) => {
    const reorderedModifiers = data.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    onChange(reorderedModifiers);
  };

  const debouncedChangeHandler = useCallback(debounce((searchTerm) => {
    setModifierOptions(getNormalizedFilteredModifiers({
      searchTerm,
      modifiers: allModifiers,
      excludeNested: true,
    }));
  }, 300), [allModifiers]);

  return (
    <FormLayout.Section>
      <Typography.Text strong>
        Modifiers
        <Typography.Text type="secondary"> (optional)</Typography.Text>
      </Typography.Text>
      {isGlobal && (
        <Row gutter={12} className="mt-2">
          <Col span={16}>
            <AutoComplete
              popupMatchSelectWidth={isMobile ? 290 : 350}
              className="w-full"
              options={modifierOptions}
              onSelect={onAddModifier}
              onSearch={debouncedChangeHandler}
            >
              <Input.Search placeholder="Search to add modifier" enterButton />
            </AutoComplete>
          </Col>
          <Col span={8}>
            <Button icon={<PlusOutlined />} onClick={() => setOpenModifierDrawer(true)} block>Create modifier</Button>
          </Col>
        </Row>
      )}

      {modifiers?.length > 0 && (
        <DraggableTable
          className="mt-2"
          columns={columns}
          dataSource={sortedModifiers}
          setDataSource={reorderPositions}
          showHeader={false}
        />
      )}

      <ModifierDrawer open={openModifierDrawer} onClose={() => setOpenModifierDrawer(false)} />

    </FormLayout.Section>
  );
}

export default Modifiers;
