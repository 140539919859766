import {
  Flex, Form, Space, Typography,
} from 'antd';
import React from 'react';
import { formatAmount } from 'utils';

function Summary() {
  const adjustment_amount = Form.useWatch('adjustment_amount');
  const custom_delivery_fee = Form.useWatch('custom_delivery_fee');
  const tip = Form.useWatch('tip');
  const tip_percentage = Form.useWatch('tip_percentage');
  const plus = <Typography.Text style={{ fontWeight: 'bold' }}>+</Typography.Text>;

  return (
    <Flex gap={32}>
      <Space size={4} className="items-start md:items-center">

        {
          !!adjustment_amount && (
            <>
              {plus}
              <Typography.Text style={{ fontWeight: 'bold' }}>
                {formatAmount(adjustment_amount, { hasSign: true })}
              </Typography.Text>
              <Typography.Text>
                Adjustment
              </Typography.Text>
            </>
          )
        }
        {
          !!custom_delivery_fee && (
            <>
              {plus}
              <Typography.Text style={{ fontWeight: 'bold' }}>
                {formatAmount(custom_delivery_fee, { hasSign: true })}
              </Typography.Text>
              <Typography.Text>
                Custom Delivery Fee
              </Typography.Text>
            </>
          )
        }
        {
          !!(tip || tip_percentage) && (
            <>
              {plus}
              <Typography.Text style={{ fontWeight: 'bold' }}>
                {tip ? formatAmount(tip, { hasSign: true }) : `${tip_percentage}%`}
              </Typography.Text>
              <Typography.Text>
                Delivery Tip
              </Typography.Text>
            </>
          )
        }
      </Space>
    </Flex>
  );
}
export default Summary;
