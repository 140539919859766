import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import useFetchedData from 'hooks/useFetchedData';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { generateURLWithParams } from 'utils';
import { captureException } from 'utils/errors';
import http from 'utils/http';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { queries } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [currentCompanyURL, setCurrentCompanyURL] = useState(
    generateURLWithParams('v1/organizations/{{organizationId}}/companies/',
      queries || { ordering: '-status_order', page_size: 1 }));

  const { data: list = {}, loading: initialLoading } = useFetchedData(currentCompanyURL);

  const onLoadNextCompany = (url) => {
    if (!url) {
      return;
    }
    const newFetchingURL = url.split('api/')[1];
    http.get(newFetchingURL).then(({ data: { results: data = [] } }) => {
      setCurrentCompanyURL(newFetchingURL);
      navigate(`/console/companies/${data[0].id}/info`);
    })
      .catch(captureException)
      .finally(() => setLoading(false));
  };

  return (
    <Flex
      justify="space-between"
      className="sticky top-0 z-10 py-4 flex-col md:flex-row items-start md:items-center gap-2 bg-white"
    >
      <Flex
        onClick={() => navigate('/console/companies')}
        gap={8}
        align="center"
        className="cursor-pointer text-[#717679]"
      >
        <ArrowLeftOutlined/>
        <span className="font-weight-bolder">Companies</span>
      </Flex>

      <Flex gap={8}>
        <Button
          onClick={() => onLoadNextCompany(list.previous)}
          loading={loading}
          disabled={!list.previous || initialLoading}
        >
          <LeftOutlined/>
          Previous
        </Button>

        <Button
          onClick={() => onLoadNextCompany(list.next)}
          loading={loading}
          disabled={!list.next || initialLoading}
        >
          Next
          <RightOutlined/>
        </Button>
      </Flex>

    </Flex>
  );
}

export default Header;
