import { MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import withGuard from 'components/withGuard';
import { Col, Row } from 'antd';
import CardLink from 'components/CardLink';
import Layout from 'components/Layout';
import React from 'react';
import { PERMISSIONS } from 'constants/permissions';
import { WL_CONFIG } from 'constants';

function Support() {
  return (
    <Layout header={(
      <Layout.Header
        title="Help center"
        description="Any queries or issues would be addressed here."
      />
    )}
    >
      <Row gutter={16} className="mt-10">
        {window?.Intercom && (
          <Col xs={24} sm={12}>
            <CardLink
              title="Send message"
              description="Click to address any queries or issues if you can’t find answers in our knowledge base - we’re here to help and ensure your satisfaction."
              icon={<MessageOutlined />}
              onClick={() => window?.Intercom('show')}
              rel="noopener noreferrer"
              target="_blank"
            />
          </Col>
        )}
        {
          WL_CONFIG.supportPanelUrl && (
            <Col xs={24} sm={12}>
              <CardLink
                title="Frequently Asked Questions"
                description="Find answers to common queries about our products, technical details, payments, and more, simplifying your experience with us."
                icon={<QuestionCircleOutlined />}
                isExternal
                link={WL_CONFIG.supportPanelUrl}
                rel="noopener noreferrer"
                target="_blank"
              />
            </Col>
          )
        }

      </Row>
    </Layout>
  );
}

export default withGuard(Support, [PERMISSIONS.ADMIN_ACCESS], { redirect: true });
