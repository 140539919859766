import {
  Col, Form, Input, Row,
} from 'antd';
import SwitchInput from 'components/SwitchInput';
import React from 'react';
import OrderFormItem from '../../components/OrderFormItem';

function Adjustment() {
  const hasAdjustment = Form.useWatch('has_adjustment');
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8} lg={5} className="d-flex align-items-center">
        <OrderFormItem name="has_adjustment" noStyle boolean fullWidth>
          <SwitchInput label="Add adjustment" direction="horizontal" />
        </OrderFormItem>
      </Col>
      <Col xs={24} md={6} lg={4} style={{ display: hasAdjustment ? 'block' : 'none' }}>
        <OrderFormItem name="adjustment_amount" noStyle>
          <Input suffix="$" type="number" />
        </OrderFormItem>
      </Col>
    </Row>
  );
}

export default Adjustment;
