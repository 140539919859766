import { App, Form } from 'antd';
import { PaymentTypes } from 'constants';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { getHttpErrorMessage } from 'utils/http';
import { captureException } from 'utils/errors';
import AdditionalDetails from './AdditionalDetails';
import DeliveryInfo from './DeliveryInfo';
import EstimatedInfo from './EstimatedInfo';
import GeneralInfo from './GeneralInfo';
import ItemsInfo from './Items';
import PaymentInfo from './PaymentInfo';
import { createDirectEntryOrder, fetchOrderEstimate } from './requests';
import { StepsContext } from './Steps/StepsProvider';

function OrderForm() {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { setLoading } = useContext(StepsContext);
  const [open, setOpen] = useState(false);
  const [estimatedData, setEstimatedData] = useState();
  const navigate = useNavigate();

  window.form = form;
  function getPaymentData() {
    const order = form.getFieldsValue();
    if (order.payment_method?.type === PaymentTypes.QUOTED) {
      return {
        quote_receiver_email: order.quotedPaymentMeta?.email,
        quote_receiver_phone: order.quotedPaymentMeta?.phone,
        is_quote: true,
        payment_type: order.quotedPaymentMeta?.type || PaymentTypes.CARD,
      };
    }
    if (order.payment_method?.type === PaymentTypes.CUSTOM) {
      return {
        payment_type: order.payment_method?.type,
        custom_payment_id: order.payment_method?.id,
        custom_payment_meta: order.customPaymentMeta,
      };
    }
    if (order.payment_method?.type === PaymentTypes.CARD) {
      return {
        payment_type: order.payment_method.type,
        card_id: order.card_id,
      };
    }
    return {};
  }
  const getOrderPayload = () => {
    const order = form.getFieldsValue();

    const orderItems = order?.items?.map((item) => ({
      item_id: item.item_related.id,
      qty: item.qty,
      size: item.size.position,
      message: item.message,
      modifiers: item.order_options?.map((b) => b.modifier_option.id),
    }));
    return {
      restaurant: order.merchant.value,
      has_delivery: order.has_delivery,
      address: order.address?.id,
      tip: order.has_delivery ? (order.tip_percentage === -1 ? Number(order.tip) : null) : undefined,
      tip_percentage: order.has_delivery ? (order.tip_percentage === -1 ? null : order.tip_percentage) : undefined,
      voucher_code: null,
      pickup_time: null,
      items: orderItems,
      user_id: order.customer.id,
      adjustment_amount: Number(order.adjustment || 0),
      delivery_fee: Number(order.delivery_fee || 0),
      source: 'Direct Entry',
      order_source: 'Direct Entry', // TODO: remove this
      scheduled_for: order.scheduled_for,
      delivery_message: order.delivery_message,
      headcount: order.headcount,
      recipient_name: order.recipient_name && `${order.recipient_name} ${order.recipient_last_name}`,
      recipient_number: order.recipient_phone,
      ...getPaymentData(),
    };
  };

  const getOrderEstimateData = () => {
    setLoading(true);
    const estimatePayload = getOrderPayload();
    fetchOrderEstimate(estimatePayload).then((res) => {
      if (res?.status === 'success') {
        setEstimatedData(res?.data);
        setOpen(true);
      }
    })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e) || 'Something went wrong during estimate order!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    const payload = getOrderPayload();
    setLoading(true);
    return createDirectEntryOrder(payload)
      .then((res) => {
        if (res?.status === 'success') {
          navigate('/console/orders');
        }
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e) || 'Something went wrong during create order!');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinishFailed={() => {
        message.error('Please fix errors!');
      }}
      onFinish={getOrderEstimateData}
      initialValues={{ has_delivery: false }}
      validateMessages={{ required: '${label} is required!' }}
    >
      <GeneralInfo />
      <DeliveryInfo />
      <ItemsInfo />
      <PaymentInfo />
      <AdditionalDetails />

      <EstimatedInfo
        data={estimatedData}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
      />
    </Form>
  );
}

export default OrderForm;
