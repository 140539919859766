import { Flex, Form, InputNumber } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React from 'react';

function Rules({ disabled }) {
  const minimum = Form.useWatch('min_selections');
  const maximum = Form.useWatch('max_selections');
  return (
    <FormLayout.Section title="Rules">
      <Flex gap={16}>
        <FormItem
          name="min_selections"
          label="Minimum"
          fullWidth
          rules={[
            {
              validator: async (_, value) => {
                if (value && maximum && value > maximum) {
                  throw new Error('Minimum selection could not be greater than maximum selection');
                }
              },
            },
          ]}
        >
          <InputNumber type="number" max={30} placeholder="No Min" className="w-full" disabled={disabled}/>
        </FormItem>

        <FormItem
          name="max_selections"
          label="Maximum"
          fullWidth
          rules={[
            {
              validator: async (_, value) => {
                if (value && minimum && value < minimum) {
                  throw new Error('Maximum selection could not be less than minimum selection');
                }
              },
            },
          ]}
        >
          <InputNumber type="number" max={30} placeholder="No Mix" className="w-full" disabled={disabled}/>
        </FormItem>
      </Flex>
    </FormLayout.Section>
  );
}

export default Rules;
