import { Input } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import SwitchInput from 'components/SwitchInput';
import React, { useContext } from 'react';
import { MenuContext } from '../../constants';
import AdditionalInfo from './AdditionalInfo';
import Modifiers from './Modifiers';
import Sizes from './Sizes';
import Unit from './Unit';

function ItemFields({ disabled }) {
  const { isGlobal } = useContext(MenuContext);
  return (
    <>
      <FormLayout.Section divider={false}>
        <FormItem name="name" label="Name" rules={[{ required: true }]} fullWidth>
          <Input placeholder="Item name" disabled={disabled} />
        </FormItem>
        <FormItem name="is_active" boolean fullWidth hidden={isGlobal}>
          <SwitchInput
            label="Mark as available"
            description="Customers can view item and select it."
            className="flex-row-reverse w-full justify-between"
          />
        </FormItem>
      </FormLayout.Section>

      <Unit disabled={disabled} />
      <Sizes disabled={disabled} />
      <AdditionalInfo disabled={disabled} />
      <FormItem name="modifiers" fullWidth>
        <Modifiers />
      </FormItem>
    </>
  );
}

export default ItemFields;
