import { WL_CONFIG } from 'constants';

export const Colors = {
  black: '#0000000',
  white: '#ffffff',
  green: '#68d300',
  lightOrange: '#f8aa30',
  orange: '#f48530',
  purple: '#672792',
  brandColor: '#E45628',
  success: '#4BB43A',
  brandColor: WL_CONFIG.colors.primary,
  primary1: WL_CONFIG.colors.primary,
  textPrimary1: '#131b1f',
};
