import { App, Button, Input } from 'antd';
import { WL_CONFIG } from 'constants';
import { editCategory } from 'containers/MenuManagement/Overview/MenuItemsTable/requests';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import { EventEmitter } from 'utils/event';
import { createCategory } from './requests';

function CategoryInput({
  onClose, category: categoryProp = {}, menuId, ...props
}) {
  const [category, setCategory] = useState(categoryProp?.name || '');
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  const handleSaveCategory = (category) => {
    setLoading(true);

    return (categoryProp?.id ? editCategory({ id: categoryProp?.id, name: category }) : createCategory({ name: category, menuId }))
      .then((res) => {
        EventEmitter.emit('refresh_categories');
      })
      .catch((e) => {
        captureException(e);
        message.error('Something went wrong! please try again');
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: 40,
        padding: '0 12px',
        border: `1px solid ${WL_CONFIG.colors.primary}`,
        gap: '12px',
        borderRadius: '5px',
        justifyContent: 'space-between',
      }}
      {...props}
    >
      <Input
        disabled={loading}
        placeholder="Type then press enter to add category"
        onChange={(e) => setCategory(e.target.value)}
        value={category}
        className="border-none shadow-none"
        onKeyDown={(e) => {
          if (loading) return;
          if (e.key === 'Enter') {
            handleSaveCategory(category);
          }
          if (e.key === 'Escape') {
            setCategory('');
            onClose();
          }
        }}
      />
      <Button onClick={onClose} className="border-none" disabled={loading}>Cancel (Esc)</Button>
      <Button
        type="primary"
        className="border-none"
        onClick={() => handleSaveCategory(category)}
        disabled={!category || loading}
        loading={loading}
      >
        Save (Enter)
      </Button>
    </div>
  );
}

export default CategoryInput;
