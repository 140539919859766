import { Modal, Typography } from 'antd';
import React from 'react';

function DeleteDisableModal({
  isDelete = true, modifier, open, setOpen, onConfirm,
}) {
  return (
    <Modal
      title={`${isDelete ? 'Deleting' : 'Disabling'} ${modifier?.name || modifier?.title}`}
      open={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ danger: isDelete }}
      okText={isDelete ? 'Delete' : 'Confirm'}
      onOk={onConfirm}
    >
      <Typography.Text>
        {`By confirming this modal the selected modifier (${modifier?.name || modifier?.title}) will be ${isDelete ? 'deleted permanently.' : 'disabled and won’t show on the customer’s menu.'}`}
      </Typography.Text>
    </Modal>
  );
}

export default DeleteDisableModal;
