import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Row, Typography,
} from 'antd';
import SearchItemInput from 'components/ItemsInput/AddOrderItemOverlay/SearchItemInput';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import { MenuContext } from '../constants';

function Toolbar({ searchTerm, setSearchTerm, openCreateModifierDrawer }) {
  const isMobile = useIsMobile();
  const { loading, isGlobal } = useContext(MenuContext);

  return (
    <Row gutter={[16, 16]} className="w-full">
      <Col xs={24} md={12} lg={10}>
        <SearchItemInput value={searchTerm} onChange={setSearchTerm} placeholder="Modifier name" />
      </Col>

      {isGlobal && (
        <Col xs={24} md={12} lg={14} style={{ textAlign: 'end' }}>
          <Button
            icon={<PlusOutlined />}
            block={isMobile}
            type="default"
            disabled={loading}
            onClick={openCreateModifierDrawer}
          >
            Create modifier
          </Button>
        </Col>
      )}

      {!isGlobal && (
        <Col xs={24} md={12} lg={14} className="xl:text-end m-auto">
          <Typography.Text>
            Please contact your organization's manager to create or edit a modifier.
          </Typography.Text>
        </Col>
      )}

    </Row>
  );
}

export default Toolbar;
