import {
  Flex, Space, Typography,
} from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import StepsProvider, { StepsContext } from './StepsProvider';

function Steps({ children }) {
  return (
    <StepsProvider>
      <div style={{ border: '1px solid #DCDDDD', borderRadius: 5 }}>
        {children}
      </div>
    </StepsProvider>
  );
}

function Step(
  {
    step,
    summary,
    children,
    disabled = false,
  },
) {
  const { title, id: stepNumber } = step || {};
  const { setActiveStep, isActiveStep, isLastStep } = useContext(StepsContext);
  const isActive = isActiveStep(stepNumber);
  const isLast = isLastStep(stepNumber);
  const titleLevel = 4;
  const isMobile = useIsMobile();
  if (!step) {
    return null;
  }

  return (
    <Flex
      vertical
      style={{
        cursor: !isActive ? 'pointer' : 'unset',
        opacity: disabled ? 0.5 : 1,
        position: 'relative',
      }}
      onClick={() => setActiveStep(stepNumber)}
    >
      {disabled && (
        <div style={{
          position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        />
      )}
      <Flex
        vertical
        style={{
          padding: '12px 24px 24px',
          backgroundColor: '#F5F8F7',
          borderBottom: '1px solid #DCDDDD',
          borderRadius: isLast && !isActive ? '0 0 5px 5px' : stepNumber === 1 ? '5px 5px 0 0' : 'unset',
        }}
      >
        <Space size={isMobile ? 0 : 4} align="baseline" direction={isMobile ? 'vertical' : 'horizontal'} style={{ marginBottom: isActive ? 24 : 12 }}>
          <Space>
            <Typography.Title level={titleLevel} style={{ color: '#00000073' }}>
              {stepNumber}
              .
            </Typography.Title>
            <Typography.Title level={titleLevel} style={{ fontWeight: 700, color: 'inherit' }}>
              {title}
            </Typography.Title>
            {!isActive && (
              <EditOutlined style={{ fontSize: 20 }} />
            ) }
          </Space>
        </Space>

        <div style={{ display: isActive ? 'block' : 'none' }}>
          {children}
        </div>
        {!isActive && summary}
      </Flex>

    </Flex>
  );
}

Steps.Step = Step;
export default Steps;
