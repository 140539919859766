import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Spin } from 'antd';
import EmptyComponent from 'components/Empty';
import React, { useContext, useState } from 'react';
import { MenuContext } from '../constants';
import List from './List';
import ModifierDrawer from './ModifierDrawer';
import Toolbar from './Toolbar';

function Modifiers({
  hasCreateAction = true,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [openCreateModifierDrawer, setOpenCreateModifierDrawer] = useState(false);
  const [currentModifier, setCurrentModifier] = useState();
  const { modifiers, modifiersLoading, isGlobal } = useContext(MenuContext);

  const onClose = () => {
    setOpenCreateModifierDrawer(false);
    setCurrentModifier();
  };

  if (modifiersLoading && !searchTerm) {
    return (
      <Flex align="center" justify="center" className="h-[50vh]">
        <Spin size="large" />
      </Flex>
    );
  }

  const empty = (
    <EmptyComponent
      title={isGlobal ? 'Start by adding your first modifier to build your restaurant\'s menu effortlessly!' : 'Please contact your organization\'s manager to create or edit a modifier.'}
      primaryAction={hasCreateAction && (
        <Button icon={<PlusOutlined />} onClick={() => setOpenCreateModifierDrawer(true)} type="primary">
          Create modifier
        </Button>
      )}
    />
  );
  if (!modifiers?.length && !searchTerm) {
    return (
      <>
        {empty}
        <ModifierDrawer open={openCreateModifierDrawer} onClose={onClose} />
      </>
    );
  }

  return (
    <Flex vertical gap={12}>
      {(Boolean(modifiers?.length) || searchTerm) && (
        <Toolbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          openCreateModifierDrawer={() => setOpenCreateModifierDrawer(true)}
        />
      )}

      {(!modifiers?.length && !searchTerm) ? (
        { empty }
      ) : <List searchTerm={searchTerm} setCurrentModifier={setCurrentModifier} />}

      <ModifierDrawer
        open={openCreateModifierDrawer || Boolean(currentModifier)}
        onClose={onClose}
        modifier={currentModifier}
      />
    </Flex>
  );
}

export default Modifiers;
