import React, {
  createContext,
  useMemo, useState,
} from 'react';
import { STEPS } from '../constants';

export const StepsContext = createContext();

function StepsProvider({ children }) {
  const [activeStep, setActiveStep] = useState(STEPS.GENERAL_INFO.id);
  const [loading, setLoading] = useState(false);

  function isActiveStep(step) {
    return step === activeStep;
  }

  const validate = (form) => {
    const { fields } = Object.entries(STEPS)[activeStep - 1][1];
    if (activeStep === STEPS.PAYMENT_INFO?.id) {
      const meta = form.getFieldValue('payment_method')?.meta;
      if (meta) {
        const metaFields = meta ? Object.entries(meta?.fields) : [];
        metaFields?.map(([name]) => fields.push(['customPaymentMeta', name]));
      }
    }
    return form
      .validateFields(fields, { validateOnly: false });
  };

  const contextValue = useMemo(() => ({
    activeStep,
    setActiveStep,
    loading,
    setLoading,
    isActiveStep,
    validate,
    isLastStep: (step) => step === Object.keys(STEPS).length,
  }), [activeStep, loading]);

  return (
    <StepsContext.Provider value={contextValue}>
      {children}
    </StepsContext.Provider>
  );
}

export default StepsProvider;
