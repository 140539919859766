import {
  AutoComplete, Button, Col, Flex, InputNumber, Row, Space, Typography,
} from 'antd';
import DietaryTagSelector from 'components/DietaryTags/DietaryTagSelector';
import React, { useContext, useMemo, useState } from 'react';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import { getNormalizedFilteredModifiers } from '../../utils';
import { OptionMode } from '../constants';
import ModifierDrawer from '../index';

function OptionForm({
  onSave, onAddNested, onCancel, selectedOptions, editedOption,
}) {
  const [currentOption, setCurrentOption] = useState(editedOption);
  const { modifiers } = useContext(MenuContext);
  const [open, setOpen] = useState(false);

  const availableModifiers = useMemo(() => (
    modifiers.map((m) => ({
      id: m.id,
      value: m.name,
      name: m.name,
      modifiers_children: m.options,
    }))
      .filter((nested) => !selectedOptions?.find((option) => (option.localId || option.modifiers_children?.[0]?.id) === nested.id && option.type !== OptionMode.DELETED))
  ), [selectedOptions]);

  const [availableOptions, setAvailableOptions] = useState(availableModifiers || []);

  const getSearchResult = (searchTerm) => (!searchTerm ? [] : getNormalizedFilteredModifiers({
    searchTerm,
    modifiers: availableModifiers,
  }));

  return (
    <>
      <Row gutter={8} className="mt-2">
        <Col span={editedOption ? 17 : 15}>
          <Flex vertical gap={8}>
            <AutoComplete
              options={availableOptions}
              style={{ width: '100%' }}
              onSelect={(_, option) => setCurrentOption({ ...currentOption, option })}
              defaultValue={currentOption?.option || currentOption?.name}
              onChange={(value) => setCurrentOption({ ...currentOption, option: { value } })}
              onSearch={(text) => setAvailableOptions(getSearchResult(text))}
              placeholder="Write name then enter"
              dropdownStyle={{ width: 350 }}
              optionRender={(option) => (
                <Flex gap={4}>
                  <Typography.Text>{option.data.value}</Typography.Text>
                  <Typography.Text type="secondary" ellipsis>
                    {option.data.modifiers_children?.map((option) => option.name)?.join(', ')}
                  </Typography.Text>
                </Flex>
              )}
            />

            <Flex gap={8}>
              <InputNumber
                type="number"
                placeholder="Price (e.g. $12)"
                prefix="$"
                defaultValue={currentOption?.price}
                style={{ width: '180px' }}
                controls={false}
                onChange={(price) => setCurrentOption({ ...currentOption, price })}
              />
              <DietaryTagSelector
                onChange={(values) => setCurrentOption({ ...currentOption, dietary_tags: values })}
                value={currentOption?.dietary_tags}
                maxTagCount={2}
                iconOnly
              />
            </Flex>
          </Flex>
        </Col>
        <Col span={editedOption ? 7 : 9} style={{ alignSelf: 'end' }}>
          <Space>
            {!editedOption ? (
              <Button onClick={() => setOpen(true)}>
                Create nested
              </Button>
            ) : (
              <Button onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button type="primary" onClick={() => onSave(currentOption)} disabled={!currentOption?.option?.value}>
              Save
            </Button>
          </Space>

        </Col>
      </Row>

      <ModifierDrawer open={open} onClose={() => setOpen(false)} handleNested={onAddNested} />
    </>
  );
}

export default OptionForm;
