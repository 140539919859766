import { DownOutlined, EllipsisOutlined, UpOutlined } from '@ant-design/icons';
import {
  Dropdown, Space, Table, Typography,
} from 'antd';
import { orderBy } from 'lodash-es';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';
import DraggableTable from 'components/DraggableTable';
import { MenuContext } from '../context';
import AddCategory, { CategoryInput } from './AddCategory';
import ItemsTable from './ItemsTable';
import { deleteCategory, editCategory, reorderCategoryPosition } from './requests';
import './style.css';
import { getFilteredItems } from './utils';

function MenuItemsTable() {
  const {
    itemsLoading,
    menuDetail: menu,
    setItemsLoading,
    fetchMenuDetail,
    showMessage,
    searchTerm,
    selectedMenu,
  } = useContext(MenuContext);
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState();

  const onRemoveCategory = (e, id) => {
    e.preventDefault();
    setItemsLoading(true);
    deleteCategory(id).then((res) => {
      fetchMenuDetail();
    })
      .catch((e) => {
        captureException(e);
        showMessage({ type: 'error' });
        setItemsLoading(false);
      });
  };

  const columns = [
    {
      key: 'sort',
      width: 40,
      align: 'center',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      inputType: 'text',
      editable: true,
      render: (value, data) => (
        <Space wrap>
          <Typography.Text>{value}</Typography.Text>
          {!!data.items?.length && (
            <Typography.Text style={{ fontSize: 12, color: '#666B71' }}>
              {data.items?.length}
              {' '}
              items
            </Typography.Text>
          )}
        </Space>
      ),

    },
    {
      key: 'action',
      width: 40,
      render: (value, data) => (data.key === editingCategory?.key ? null : (
        <Dropdown menu={{
          items: [
            {
              key: '1',
              label: <a type="text" onClick={(e) => setEditingCategory(data)}>Edit</a>,
            },
            {
              key: '2',
              label: <a type="text" onClick={(e) => onRemoveCategory(e, data.id)}>Remove</a>,
            },
          ],
        }}
        >
          <EllipsisOutlined />
        </Dropdown>
      )),
    },
    Table.EXPAND_COLUMN,
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.key === editingCategory?.key,
      }),
    };
  });

  const sortedCategories = useMemo(() => {
    const indexedCategories = menu.categories?.map((category) => ({ ...category, key: category.id })) || [];
    return orderBy(indexedCategories, ['position'], ['asc']);
  }, [menu]);
  useEffect(() => {
    if (menu.categories) {
      setCategories(sortedCategories);
    }
  }, [menu]);

  useEffect(() => {
    setCategories(getFilteredItems({ searchTerm, categories: sortedCategories }));
  }, [searchTerm, sortedCategories]);

  const onReorderCategory = ({ id, position }) => {
    setItemsLoading(true);
    reorderCategoryPosition({ categoryId: id, position }).then((res) => {
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
    }).finally(() => setItemsLoading(false));
  };

  function customExpandIcon(props) {
    if (props.expanded) {
      return <UpOutlined onClick={(e) => props.onExpand(props.record, e)} />;
    }
    return <DownOutlined onClick={(e) => props.onExpand(props.record, e)} />;
  }

  const onEditCategory = (name) => {
    setItemsLoading(true);
    editCategory({ id: editingCategory?.id, name }).then((res) => {
      fetchMenuDetail();
      setEditingCategory();
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
      setItemsLoading(false);
    });
  };

  return (
    <div style={{ marginTop: 16 }}>
      {!!categories?.length && (
        <DraggableTable
          columns={mergedColumns}
          dataSource={categories}
          setDataSource={setCategories}
          showHeader={false}
          bordered
          loading={itemsLoading}
          onDragCallback={onReorderCategory}
          expandable={{
            expandedRowRender: (record) => (
              <ItemsTable
                category={record}
                categories={categories}
                setCategories={setCategories}
              />
            ),
            expandIcon: customExpandIcon,
          }}
          CustomCell={(props) => (
            <EditableCell
              {...props}
              onCancel={() => setEditingCategory()}
              onSave={onEditCategory}
            />
          )}
          footer={() => <AddCategory buttonType="text" />}
          scroll={{ x: 400 }}
        />
      )}

      {selectedMenu && !categories?.length && !itemsLoading && <AddCategory />}
    </div>
  );
}

function EditableCell({
  editing,
  inputType,
  record,
  index,
  children,
  onCancel,
  onSave,
  ...restProps
}) {
  return (
    <td {...restProps}>
      {(editing && inputType === 'text') ? (
        <CategoryInput value={record.name} onCancel={onCancel} onSave={onSave} />
      ) : (editing && inputType !== 'text') ? null : (
        children
      )}
    </td>
  );
}

export default MenuItemsTable;
