import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import {
  Flex, Form, Space, Typography,
} from 'antd';
import { DeliveryIcon, PickupIcon } from 'assets/icons';
import dayjs from 'dayjs';
import React from 'react';

function Summary() {
  const form = Form.useFormInstance();
  const data = form.getFieldsValue();
  return (
    <Flex gap={32}>
      <Space size={12} align="center">
        {data.has_delivery ? (
          <>
            <DeliveryIcon style={{ marginTop: 5 }} />
            <Typography>
              Delivery to
              {' '}
              {data.address?.address}
            </Typography>
          </>
        ) : (
          <>
            <PickupIcon style={{ marginTop: 4 }} />
            <Typography>Pickup</Typography>
          </>
        )}

      </Space>
      <Space size={4} className="flex-col md:flex-row items-start md:items-center">
        <Space size={12}>
          <UserOutlined style={{ fontSize: 20 }} />
          <Typography>
            Recipient:
            {' '}
            {data.recipient_name ? `${data.recipient_name} ${data.recipient_last_name}` : data.customer?.name}
            ,
          </Typography>
        </Space>
        <Typography>{data.recipient_phone || data.customer?.phone}</Typography>
      </Space>
      {data.scheduled_for && (
        <Space size={12}>
          <CalendarOutlined style={{ fontSize: 20 }} />
          <Typography>
            Scheduling time:
            {' '}
            {dayjs(data.scheduled_for).format('ddd, D MMM, hh:mm A')}
          </Typography>
        </Space>
      )}

    </Flex>
  );
}
export default Summary;
