import { FileTextTwoTone, InboxOutlined, MailTwoTone, PhoneTwoTone } from '@ant-design/icons';
import { Flex } from 'antd';
import React from 'react';
import AgentFollowUpEvent from './AgentFollowUpEvent';
import CallEvent from './CallEvent';
import EmailEvent from './EmailEvent';
import NoteEvent from './NoteEvent';
import OrderEvent from './OrderEvent';
import { ReactComponent as AIIcon } from './ai-icon.svg';

export const EventType = {
  ORDER: 'Order',
  CALL: 'Call',
  NOTE: 'Note',
  EMAIL: 'Email',
  AGENT_FOLLOW_UP: 'AI Call Follow-up Order',
};

export const EventItems = {
  [EventType.ORDER]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: '#fff',
          borderColor: '#00000040',
        }}
      >
        <InboxOutlined style={{ fontSize: 24, color: '#00000040' }}/>
      </TimelineIcon>
    ),
    component: OrderEvent,
  },
  [EventType.CALL]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(246, 255, 237, 1)',
          borderColor: 'rgba(82, 196, 26, 1)',
        }}
      >
        <PhoneTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(82, 196, 26, 1)"/>
      </TimelineIcon>
    ),
    component: CallEvent,
  },
  [EventType.NOTE]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(255, 247, 230, 1)',
          borderColor: 'rgba(250, 140, 22, 1)',
        }}
      >

        <FileTextTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(250, 140, 22, 1)" />
      </TimelineIcon>
    ),
    component: NoteEvent,
  },
  [EventType.EMAIL]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(230, 247, 255, 1)',
          borderColor: 'rgba(24, 144, 255, 1)',
        }}
      >
        <MailTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(24, 144, 255, 1)"/>
      </TimelineIcon>
    ),
    component: EmailEvent,
  },
  [EventType.AGENT_FOLLOW_UP]: {
    icon: (
      <TimelineIcon
        style={{

          background: 'linear-gradient(225.32deg, #E7EAFF 0.28%, #FDE4FF 99.72%) padding-box,\n' +
            ' linear-gradient(225.12deg, rgb(0, 21, 181) 0.11%, rgb(221, 0, 237) 99.89%) border-box',
          border: '1px solid transparent',
          borderRadius: '8px',
        }}
      >
        <AIIcon/>
      </TimelineIcon>
    ),
    component: AgentFollowUpEvent,
  },
};

function TimelineIcon({ style, children, ...props }) {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        width: 44,
        height: 44,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        ...style,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
}
