import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import FormLayout from 'components/FormLayout';
import React, { useContext, useState } from 'react';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import { OptionMode } from '../constants';
import List from './List';
import OptionForm from './OptionForm';

function Options({ value: options = [], onChange, disabled }) {
  const { isGlobal, merchant } = useContext(MenuContext);
  const [isNewOption, setIsNewOption] = useState(false);
  const [editedOption, setEditedOption] = useState();

  const onSave = (record) => {
    if (editedOption) {
      const editedOptions = [...options];
      const index = editedOptions.findIndex((o) => o.id === record.id);
      editedOptions[index] = { ...record, name: record.option.value, type: OptionMode.UPDATED };
      onChange(editedOptions);
      setEditedOption();
    } else {
      onChange([...options, {
        ...record,
        ...record?.option,
        name: record.option.value,
        type: OptionMode.NEW,
        id: null,
        localId: record?.option?.id ?? options?.length + 1,
      }]);
      setIsNewOption(false);
    }
  };

  const onAddNested = (record) => {
    onChange([...options, {
      ...record,
      type: OptionMode.NEW,
      id: null,
      localId: record?.id ?? options?.length + 1,
      modifiers_children: [record?.id],
    }]);
    setIsNewOption(false);
  };

  const getMarkup = () => {
    if (Number(merchant?.markup)) {
      return ` ${merchant?.markup}%`;
    }
    if (Number(merchant?.markup_fixed)) {
      return ` $${merchant?.markup_fixed}`;
    }
  };

  return (
    <FormLayout.Section
      title="Options"
      subtitle={!isGlobal && `The modifier’s option price calculated with${getMarkup() || 'out'} markup.`}
    >
      <List options={options} setOptions={onChange} setEditedOption={setEditedOption} />

      {(isNewOption || editedOption) && (
        <OptionForm
          selectedOptions={options}
          onCancel={() => {
            setIsNewOption(false);
            setEditedOption();
          }}
          onSave={onSave}
          editedOption={editedOption}
          onAddNested={onAddNested}
        />
      )}

      <Space style={{ marginTop: 8 }} hidden={disabled}>
        <Button
          icon={<PlusOutlined />}
          disabled={isNewOption || Boolean(editedOption)}
          onClick={() => setIsNewOption(true)}
        >
          Add option
        </Button>
      </Space>
    </FormLayout.Section>
  );
}

export default Options;
