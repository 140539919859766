import { Flex, message, Spin } from 'antd';
import { fetchMyPermissions, logout } from 'api/auth';
import { AdminContext } from 'constants/contexts';
import usePersistedState from 'hooks/usePersistedState';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';

const initialUserState = {
  first_name: '',
  last_name: '',
  email: '',
  pk: null,
  authToken: null,
  permissions: [],
};
function AdminProvider({ children }) {
  const [organization, setOrganization] = usePersistedState(null, 'admin-provider/organization');
  const [user, setUserInfo] = usePersistedState(initialUserState, 'admin-provider/user');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.authToken) {
      setLoading(true);
      // To ensure persisted token is loaded
      // TODO: investigate and find a more robust solution.
      setTimeout(() => {
        fetchMyPermissions().then((res) => {
          setUserInfo((d) => ({ ...d, permissions: res.message?.permissions }));
          setLoading(false);
        }).catch((e) => {
          captureException(e);
          setLoading(false);
          if (e.response?.status === 401) {
            message.error('Authentication failed!');
            logoutUser();
          }
        });
      }, 100);
    }
  }, [user.authToken, organization?.id]);

  const logoutUser = useCallback(() => {
    logout().then((res) => {
      setUserInfo(initialUserState);
      setOrganization(null);
    }).catch((e) => {
      captureException(e);
      errorHandling(e);
      if (e.response?.status === 401) {
        setUserInfo(initialUserState);
      }
    });
  }, []);
  const contextValue = useMemo(() => ({
    user,
    organization,
    isLoggedIn: Boolean(user.authToken),
    organizationId: organization?.id,
    permissions: user.permissions,
    setOrganization,
    setUser: (data) => setUserInfo((d) => ({ ...d, ...data })),
    logoutUser,
  }), [user, organization, setOrganization, setUserInfo, logoutUser]);

  return (
    <AdminContext.Provider value={contextValue}>
      {loading && (
        <Flex justify="center" align="center" className="h-[100vh]">
          <Spin size="large" id="initial-loading"/>
        </Flex>
      )}
      {!loading && children}
    </AdminContext.Provider>
  );
}

export default AdminProvider;
