import { App, Button, Drawer, Flex, Input, Typography } from 'antd';
import FormLayout from 'components/FormLayout';
import useIsMobile from 'hooks/useIsMobile';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from 'utils/http';

function NoteDrawer({ open, onClose, note: noteEvent }) {
  const [note, setNote] = useState();
  const [loading, setLoading] = useState(false);
  const { id: companyId } = useParams();
  const { message } = App.useApp();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (noteEvent) {
      setNote(noteEvent.data.note);
    }
  }, [noteEvent?.data]);

  const handleClose = () => {
    setNote('');
    onClose();
  };

  const handleSave = () => {
    setLoading(true);
    const baseURL = `v1/organizations/{{organizationId}}/companies/${companyId}/notes/`;
    (noteEvent ? http.put(`${baseURL}${noteEvent.uid}/`, { note }) : http.post(baseURL, { note }))
      .then(() => {
        message.success(`Note ${noteEvent ? 'updated' : 'added'} successfully`);
        handleClose();
      }).catch((err) => {
      message.error(err.response.data.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Drawer
      title={setNote ? 'Edit Note' : 'Add Note'}
      open={open}
      onClose={handleClose}
      width={isMobile ? '100%' : 620}
    >
      <Flex vertical gap={8}>
        <Typography.Text className="font-bold">Enter your note</Typography.Text>
        <Input.TextArea rows={10} value={note} onChange={(e) => setNote(e.target.value)}/>
      </Flex>
      <FormLayout.Footer>
        <div className="flex justify-content-end gap-2">
          <Button type="default" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave} loading={loading}>
            {noteEvent ? 'Confirm' : 'Add'}
          </Button>
        </div>
      </FormLayout.Footer>
    </Drawer>
  );
}

export default NoteDrawer;
