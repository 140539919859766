import React from 'react';
import Input from 'components/Input';
import SkeletonLoading from 'components/SkeletonLoading';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';
import { isOmni } from 'utils/order';
import { StyledWrapper } from './styles';

function FreeStructureBox({ merchantData, merchantDataLoading, ...props }) {
  const showCommission = !isOmni(merchantData.migration_driver) && merchantData.is_partner;
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);

  if (!showCommission) {
    return null;
  }

  return (
    <StyledWrapper>
      <div className="box">
        <div className="box-title">Fee structure</div>

        <div className="box-form">
          {showCommission && (
            <div className="box-form-section">
              <div className="box-form-title">Delivery Commission</div>
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="238px"
                  height="38px"
                  count={1}
                />
              ) : (
                <Input
                  type="number"
                  onKeyDown={(e) => {
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                  }}
                  id="campaign-name"
                  value={
                    merchantData?.should_use_fixed_delivery_fee
                      ? merchantData?.application_delivery_fee_fixed
                      : merchantData?.application_delivery_fee * 100
                  }
                  onChange={() => {}}
                  placeholder=""
                  inputClasses="input"
                  trailingIcon={(
                    <span className="input-icon">
                      {merchantData?.should_use_fixed_delivery_fee ? '$' : '%'}
                    </span>
                  )}
                  disabled
                />
              )}
            </div>
          )}
          {showCommission && (
            <div className="box-form-section">
              <div className="box-form-title">Pickup Commission</div>
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="238px"
                  height="38px"
                  count={1}
                />
              ) : (
                <Input
                  type="number"
                  onKeyDown={(e) => {
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                  }}
                  id="campaign-name"
                  value={
                    merchantData?.should_use_fixed_pickup_fee
                      ? merchantData?.application_pickup_fee_fixed
                      : merchantData?.application_pickup_fee * 100
                  }
                  onChange={() => {}}
                  placeholder=""
                  inputClasses="input"
                  trailingIcon={(
                    <span className="input-icon">
                      {merchantData?.should_use_fixed_pickup_fee ? '$' : '%'}
                    </span>
                  )}
                  disabled
                />
              )}
            </div>
          )}
          {
            isAdmin && (
              <div className="box-form-section">
                <div className="box-form-title">Markup</div>
                {merchantDataLoading ? (
                  <SkeletonLoading
                    borderRadius="2px"
                    width="238px"
                    height="38px"
                    count={1}
                  />
                ) : (
                  <Input
                    type="number"
                    onKeyDown={(e) => {
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                    }}
                    id="campaign-name"
                    value={merchantData?.should_use_fixed_markup ? merchantData?.markup_fixed : merchantData?.markup}
                    onChange={() => {}}
                    placeholder=""
                    inputClasses="input"
                    trailingIcon={(
                      <span className="input-icon">
                        {merchantData?.should_use_fixed_markup ? '$' : '%'}
                      </span>
                    )}
                    disabled
                  />
                )}
              </div>
            )
          }
        </div>
      </div>
    </StyledWrapper>
  );
}

export default FreeStructureBox;
