import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { ExportIcon } from 'assets/icons';
import ColumnWithTooltip from 'containers/Reports/components/ColumnWithTooltip';
import {
  getExactDate, renderBoldPriceColumn, renderPercentageColumn, renderPriceColumn,
} from 'containers/Reports/utils';
import { Link } from 'react-router-dom';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';

const { Text } = Typography;

const useColumns = ({ dateFilter, isMobile }) => {
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const dateQuery = dateFilter?.type === 'custom' && dateFilter.range ?
    `from=${getExactDate(dateFilter?.range[0])}&to=${getExactDate(dateFilter?.range[1])}`
    : `range=${dateFilter?.type}`;

  return useMemo(() => [
    {
      title: 'RESTAURANT',
      dataIndex: 'name',
      key: 'name',
      width: '270px',
      render: (value, record) => (
        <Link
          to={`/console/reports/merchants/${record.id}/?${dateQuery}`}
          target="_blank"
          className="merchant-name-column"
        >
          <Text className="merchant-name" ellipsis={{ tooltip: value }}>{value}</Text>
          <ExportIcon />
        </Link>
      ),
      fixed: isMobile ? false : 'left',
    },
    {
      title: () => <ColumnWithTooltip title="R. TYPE" description="Restaurant type (Catering, Partner, Non-partner, and Omni)" />,
      dataIndex: 'restaurant_type',
      key: 'restaurant_type',
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
    },
    {
      title: () => <ColumnWithTooltip title="P/D COMMISSION" description="Pickup and Delivery commission" />,
      dataIndex: 'pickup_commission_percent',
      key: 'pickup_commission_percent',
      render: (value, record) => `${renderPercentageColumn(value * 100)} / ${renderPercentageColumn(record.delivery_commission_percent * 100)}`,
    },
    isAdmin && {
      title: () => <ColumnWithTooltip title="TOTAL SALES" description="Total charged to customers" />,
      dataIndex: 'total_sales',
      key: 'total_sales',
      render: renderBoldPriceColumn,
    },
    {
      title: 'COMMISSION',
      dataIndex: 'commission',
      key: 'commission',
      render: renderPriceColumn,
    },
    isAdmin && {
      title: 'MARKUP',
      dataIndex: 'markup',
      key: 'markup',
      render: renderPriceColumn,
    },
    {
      title: () => (
        <ColumnWithTooltip
          title="R. PAYMENT FEES"
          description="The portion of the payment processing that restaurants are responsible for."
        />
      ),
      dataIndex: 'payment_fee',
      key: 'payment_fee',
      render: renderPriceColumn,
    },
    {
      title: 'CREDIT USED',
      dataIndex: 'credit_used',
      key: 'credit_used',
      render: renderPriceColumn,
    },
    {
      title: 'COUPON USED (Admin)',
      dataIndex: 'coupon_used',
      key: 'coupon_used_holder',
      width: '200px',
      render: (v, record) => renderPriceColumn(v?.holder_admin, record),
    },
    {
      title: 'COUPON USED (Restaurant)',
      dataIndex: 'coupon_used',
      key: 'coupon_used_merchant',
      width: '200px',
      render: (v, record) => renderPriceColumn(v?.merchant_admin, record),
    },
    {
      title: () => (
        <ColumnWithTooltip
          title="R. TAX"
          description="Restaurant tax which also includes the tax for the markup."
        />
      ),
      dataIndex: 'tax',
      key: 'tax',
      render: renderPriceColumn,
    },
    {
      title: () => (
        <ColumnWithTooltip
          title="RESTAURANT NET"
          description="Payment processing fee has been deducted from this amount. This is the final value that restaurants should expect to receive."
        />
      ),
      dataIndex: 'restaurant_net',
      key: 'restaurant_net',
      render: renderBoldPriceColumn,
    },

  ].filter(Boolean), [dateFilter, isMobile]);
};
export default useColumns;
