import {
  App, Button, Drawer, Flex, Form, Skeleton,
} from 'antd';
import ActiveHoursInput from 'components/ActiveHoursInput';
import FormLayout from 'components/FormLayout';
import { EMPTY_OBJECT } from 'constants';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'utils/http';
import { EventEmitter } from 'utils/event';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import ImportForm from './ImportForm';
import MenuForm from './MenuForm';

function MenuFormDrawer({
  menu = EMPTY_OBJECT,
  menuId,
  open,
  onClose,
  title,
  onSubmit,
  onDelete,
  onDuplicate,
  showImportForm,
  loading: initialLoading,
  onReset,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { message, modal } = App.useApp();
  const [changeConfirmed, setChangeConfirmed] = useState(false);
  const { isGlobal } = useContext(MenuContext);

  function getSubmitActionName() {
    if (showImportForm) {
      return 'Import';
    }
    if (menuId) {
      return 'Save';
    }
    return 'Create';
  }

  useEffect(() => {
    form.setFieldsValue(deserialize(menu));
  }, [menu, form]);

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit(serialize(form.getFieldsValue()))
      .then((res) => {
        message.success(`Menu ${menuId ? 'updated' : 'created'} successfully!`);
        EventEmitter.emit('refresh_menus');
        onClose();
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setLoading(true);
    modal.confirm({
      title: 'Reset to global menu time',
      content: 'Are you sure you want to reset the menu time to match the global menu time? ',
      onOk: () => onReset()
        .then((data) => {
          message.success('Menu reset successfully!');
        })
        .catch((e) => {
          captureException(e);
          message.error(getHttpErrorMessage(e));
        })
        .finally(() => {
          setLoading(false);
        }),
    });
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width={590}
      onClose={onClose}
      open={open}
      styles={{ body: { padding: '16px 0 64px' } }}
    >
      {initialLoading && <Skeleton paragraph={{ rows: 10 }} className="px-5" />}

      <Form
        form={form}
        layout="vertical"
        onFinishFailed={() => {
          message.error('Please fix errors!');
        }}
        onValuesChange={() => {
          if (isGlobal || changeConfirmed) {
            return;
          }
          modal.confirm({
            title: 'Edit menu time',
            content: 'Changing the time in the local menu won’t affect the global menu time. You can always reset it to match the global menu time with a single tap.',
            onOk: () => {
              setChangeConfirmed(true);
            },
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
          });
        }}
        onFinish={handleSubmit}
        style={{ display: initialLoading ? 'none' : 'block' }}
      >
        {showImportForm ? <ImportForm /> : <MenuForm onReset={handleReset} />}

        <FormLayout.Footer>
          <Flex justify={onDelete ? 'space-between' : 'flex-end'} className="w-full">
            {onDelete && <Button danger disabled={loading} onClick={onDelete}>Delete</Button>}
            <Flex justify="end" gap={8}>
              {onDuplicate ? (
                <Button disabled={loading} onClick={onDuplicate}>Duplicate</Button>
              ) : (
                <Button disabled={loading} onClick={onClose}>Close</Button>
              )}
              <Button
                htmlType="submit"
                disabled={loading || (!isGlobal && !changeConfirmed)}
                loading={loading}
                type="primary"
              >
                {getSubmitActionName()}
              </Button>
            </Flex>
          </Flex>
        </FormLayout.Footer>
      </Form>

    </Drawer>
  );
}

function serialize({
  active_times,
  ...data
}) {
  return {
    ...data,
    active_times: active_times && Object.entries(active_times || {})
      .flatMap(([weekday_name, { hours, isClosed }]) => (isClosed ? null : (hours || [])
        .filter(([start, end]) => start && end)
        .map(([start, end]) => ({
          weekday_name,
          from_hour: start.format('HH:mm:ss'),
          duration: dayjs.duration(end.diff(start)).format('HH:mm:ss'),
        }))))
      .filter(Boolean),
  };
}

function deserialize({
  active_times,
  active_hours,
  ...data
}) {
  return {
    ...data,
    active_times: (active_times || active_hours) && ActiveHoursInput.deserialize(active_times || active_hours),
  };
}

export default MenuFormDrawer;
