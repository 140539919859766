import { useMemo } from 'react';
import http from 'utils/http';
import useSWR from 'swr';
import { generateURLWithParams } from 'utils';

const defaultFetcher = (...args) => http.get(...args).then((res) => res.data);

function useFetchedData(params, transformer = (d) => d) {
  function getURL() {
    if (!params) {
      return null;
    }
    if (typeof params === 'string') {
      return params;
    }
    return generateURLWithParams(params.url, params.query);
  }
  const { data, ...res } = useSWR(
    getURL(),
    defaultFetcher,
  );
  const transformedData = useMemo(() => transformer(data), [data]);
  return {
    ...res,
    data: transformedData,
    loading: Boolean(params && !res.error && !data),
  };
}

export default useFetchedData;
