import { Tag } from 'antd';
import ColumnWithTooltip from 'containers/Reports/components/ColumnWithTooltip';
import { renderBoldPriceColumn } from 'containers/Reports/utils';
import dayjs from 'dayjs';
import { getOrderType } from 'utils/order';

const columns = ({ isMobile }) => ([
  {
    title: 'ORDER NUMBER',
    dataIndex: 'number',
    key: 'number',
    width: '135px',
    fixed: isMobile ? false : 'left',
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    key: 'customer',
    ellipsis: true,
    width: '150px',
    fixed: isMobile ? false : 'left',
  },
  {
    title: 'DELIVERED DATE',
    dataIndex: 'delivered_at',
    key: 'delivered_at',
    width: '140px',
    render: (value) => value === "–" ? value : dayjs(value).utc(true).format('MMM D, hh:mm A'),
  },
  {
    title: 'TYPE',
    dataIndex: 'has_delivery',
    key: 'has_delivery',
    width: '110px',
    render: (_, record) => getOrderType(record)
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: '130px',
  },
  {
    title: 'ORDER SOURCE',
    dataIndex: 'source',
    key: 'source',
    width: '140px',
    render: (value) => (value ? <Tag>{value?.toUpperCase()}</Tag> : null),
  },
  {
    title: 'SUBTOTAL',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: renderBoldPriceColumn,
    width: '110px',
  },
  {
    title: () => <ColumnWithTooltip title="R.TAX" description="Restaurant tax" />,
    dataIndex: 'tax',
    key: 'tax',
    render: renderBoldPriceColumn,
    width: '90px',
  },
  {
    title: 'COMMISSION',
    dataIndex: 'commission',
    key: 'commission',
    render: renderBoldPriceColumn,
    width: '115px',
  },
  {
    title: 'R. PAYMENT FEES',
    dataIndex: 'payment_fee',
    key: 'payment_fee',
    render: renderBoldPriceColumn,
    width: '140px',
  },
  {
    title: () => <ColumnWithTooltip title="R.NET" description="Payment processing fee has been deducted from this amount. This is the final value that restaurants should expect to receive." placement="left" />,
    dataIndex: 'net',
    key: 'net',
    render: renderBoldPriceColumn,
    width: '120px',
  },
]);
export default columns;
