import React from 'react';
import Icon from '@ant-design/icons';
import { WL_CONFIG } from 'constants';

const PlateSVGIcon = function () {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.25 }}>
      <path d="M45.65 19.4516C45.6497 17.7119 44.2392 16.3019 42.4993 16.3022C40.7598 16.3026 39.3497 17.7132 39.35 19.4528L39.3507 23.5159C32.4566 24.0771 26.2322 26.49 21.5991 30.5422C16.2362 35.2326 13.1143 42.0317 13.5307 50.3421C13.5773 51.2734 14.346 52.0046 15.2785 52.0046H69.7237C70.6565 52.0046 71.4251 51.2731 71.4716 50.3417C71.886 42.0313 68.7633 35.2325 63.4006 30.5422C58.7677 26.4901 52.544 24.0771 45.6507 23.5159L45.65 19.4516Z" fill={WL_CONFIG.colors.primary} />
      <path d="M10.2934 55.6958C11.0004 55.1285 11.8665 54.9087 12.7501 54.9087H72.2502C73.1336 54.9087 73.9999 55.1285 74.7069 55.6958C75.4149 56.2642 75.7712 57.0272 75.9259 57.7479C76.215 59.0926 75.8808 60.6196 75.4783 61.8289C74.3509 65.2102 71.1824 67.494 67.6166 67.494H17.3836C13.8179 67.494 10.6493 65.2109 9.52224 61.8296C9.11971 60.6203 8.785 59.0926 9.0741 57.7479C9.22905 57.0272 9.58524 56.2642 10.2934 55.6958Z" fill={WL_CONFIG.colors.primary} />
    </svg>
  );
};
function PlateIcon(props) {
  return (
    <Icon component={PlateSVGIcon} {...props} />
  );
}

export default PlateIcon;
