import {
  Alert, DatePicker, Flex,
  Space, TimePicker, Typography,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { EMPTY_OBJECT } from 'constants';
import dayjs from 'dayjs';
import useFetchedData from 'hooks/useFetchedData';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  useEffect,
  useState,
} from 'react';
import { captureException } from 'utils/errors';
import http from 'utils/http';
import RestaurantTime from './RestaurantTime';

function CateringSchedulingInput({
  value,
  lock = () => { },
  unlock = () => { },
  loading,
  onChange,
  merchantId,
}) {
  const [selectedDate, setSelectedDate] = useState(value ? dayjs(value) : null);
  const [selectedTime, setSelectedTime] = useState(value ? dayjs(value) : null);
  const isMobile = useIsMobile();
  const form = useFormInstance();
  const formMerchant = form.getFieldValue('merchant');
  const [outOfRange, setOutOfRange] = useState(false);
  const [firstAvailableTime, setFirstAvailableTime] = useState(null);
  const [openingHours, setOpeningHours] = useState(null);
  const [openingHoursOpen, setOpeningHoursOpen] = useState(false);
  const { data: fetchedMerchant } = useFetchedData(formMerchant && `v1/restaurants/${formMerchant?.id}`, (res) => res?.data || EMPTY_OBJECT);
  const merchant = {
    ...formMerchant,
    ...fetchedMerchant,
  };

  useEffect(() => {
    if (!merchant?.id) {
      return;
    }
    setOutOfRange(false);
    lock();
    http.get(`v1/restaurants/${merchantId}/availability/?scheduled_datetime=${new Date(value || Date.now()).toISOString()}`).then(({ data: { data } }) => {
      if (data.failed_check) {
        setOutOfRange(true);
      }
      setFirstAvailableTime(data.first_available_time);
      setOpeningHours(data.schedule);
    }).catch(captureException).finally(unlock);
  }, [value, merchantId]);

  useEffect(() => {
    if (!value) {
      setSelectedTime();
      setSelectedDate();
    }
  }, [value]);

  useEffect(() => {
    if (selectedDate != null && selectedTime != null) {
      const formattedScheduledData = `${dayjs(selectedDate).format('YYYY-MM-DD')}T${dayjs(selectedTime).format('HH:mm:ss')}`;
      onChange(dayjs(formattedScheduledData).toISOString());
    }
  }, [selectedDate, selectedTime]);

  const handleSelectDate = (value) => {
    setSelectedDate(value);
  };
  const handleSelectTime = (value) => {
    setSelectedTime(value);
  };

  return (
    <>
      {
        firstAvailableTime && (
          <Typography.Paragraph>
            First available scheduling time:
            <Typography.Text strong>{dayjs(firstAvailableTime).format('ddd, MMM D, h:mm A')}</Typography.Text>
          </Typography.Paragraph>
        )
      }
      {
        openingHours && (
          <Typography.Paragraph>
            <Typography.Text>{merchant.name}</Typography.Text>
            {' '}
            <Typography.Text>
              operates in the
              {' '}
              { merchant.parent.location_timezone }
              {' '}
              time zone. Please select a time based on the restaurant&apos;s local time.
            </Typography.Text>

            <Typography.Link onClick={() => setOpeningHoursOpen(true)}>View restaurant&apos;s hours</Typography.Link>
          </Typography.Paragraph>
        )
      }

      <Space size="large" direction={isMobile ? 'vertical' : 'horizontal'} className="w-full mt-2">

        <Space direction={isMobile ? 'vertical' : 'horizontal'} gap={16}>
          <Flex gap={8} align="center">
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>Date:</Typography.Text>
            <DatePicker
              name="scheduled_for"
              value={selectedDate}
              onChange={handleSelectDate}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
              disabled={loading}
            />
          </Flex>
          <Flex gap={8} align="center">
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>Time:</Typography.Text>
            <TimePicker
              name="scheduled_for"
              value={selectedTime}
              onChange={handleSelectTime}
              showSecond={false}
              minuteStep={15}
              use12Hours
              format="hh:mm A"
              disabled={loading}
            />
          </Flex>
          {
            outOfRange && value && (
              <Alert type="error" message="Warning: The restaurant is closed or lead time isn't met. You can still place your order, but inform the restaurant." />
            )
          }
        </Space>
      </Space>
      <RestaurantTime
        openingHours={openingHours}
        merchant={merchant}
        open={openingHoursOpen}
        firstAvailableTime={firstAvailableTime}
        onClose={() => setOpeningHoursOpen(false)}
      />
    </>
  );
}

export default CateringSchedulingInput;
