import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Flex, Typography, Upload } from 'antd';
import axios from 'axios';
import { API_URL } from 'constants/index';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';

function UploadImage({ value: images, onChange, disabled }) {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {
      const formData = new FormData();
      formData.append('image', file);
      setLoading(true);
      axios
        .post(`${API_URL}v1/restaurant-images/`, formData)
        .then((res) => {
          onChange([res.data]);
          setLoading(false);
        })
        .catch((e) => {
          captureException(e);
          setLoading(false);
        });
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <Flex vertical align="center">
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <Typography.Text type="secondary">Upload item picture</Typography.Text>
    </Flex>
  );
  return (
    <Upload
      className="mt-1"
      name="image"
      listType="picture-card"
      maxCount={1}
      showUploadList={false}
      beforeUpload={beforeUpload}
      disabled={disabled}
    >
      {images?.length > 0 ?
        <img
          src={images[0]?.image || images[0].image_med}
          alt="avatar"
          style={{ width: '100%', maxHeight: '100px' }}
        /> : uploadButton}
    </Upload>
  );
}

export default UploadImage;
