import {
  Alert,
  App, Button,
  Flex, Form, Modal, Skeleton, Space, Typography,
} from 'antd';
import useFetchedData from 'hooks/useFetchedData';
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { formatAmount, generateURLWithParams } from 'utils';
import { captureException } from 'utils/errors';
import http, { getHttpErrorMessage } from 'utils/http';
import useRerender from 'hooks/useRerender';
import AdditionalDetails from './AdditionalDetails';
import DeliveryInfo from './DeliveryInfo';
import GeneralInfo from './GeneralInfo';
import ItemsInfo from './Items';

function OrderForm() {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: order } = useFetchedData(`v1/orders/${id}/`, (res) => res?.data);
  const validateVersionRef = useRef(0);
  const [errors, setErrors] = useState([]);
  const { modal } = App.useApp([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [latestOrderData, setLatestOrderData] = useState(order);
  const rerender = useRerender();
  useEffect(() => {
    if (order) {
      form.setFieldsValue(deserializeData(order));
      rerender();
    }
  }, [order]);

  const sync = () => {
    const values = form.getFieldsValue();
    setLoading(true);
    validateVersionRef.current++;
    const validateVersion = validateVersionRef.current;
    submit({ dryRun: true, ignorePaymentErrors: false, values })
      .then((res) => {
        if (validateVersionRef.current !== validateVersion) {
          return;
        }
        setErrors([]);
        form.setFieldsValue(deserializeData(res.data));
        setLatestOrderData(res.data);
      })
      .catch((e) => {
        if (e?.response?.data?.errors) {
          setErrors(formatErrors(e.response.data.errors));
        } else {
          message.open({
            type: 'error',
            content: getHttpErrorMessage(e),
            style: {
              maxWidth: '60%',
              margin: 'auto',
            },
          });
          captureException(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const debouncedSync = useDebouncedCallback(sync, 2000);

  function submit({ values, dryRun, ignorePaymentErrors }) {
    return http.put(generateURLWithParams(
      `/v1/orders/${id}/edit/`,
      { dry_run: dryRun, ignore_payment_errors: ignorePaymentErrors },
    ), serializeData(values));
  }

  function handleSubmit() {
    setSubmitting(true);
    const returnToOrder = () => navigate(`/console/orders/${id}`);
    const turnOffLoading = () => setSubmitting(false);
    return submit({ values: form.getFieldsValue(), dryRun: false, ignorePaymentErrors: false })
      .then(returnToOrder)
      .catch((e) => {
        if (e?.response?.data?.title === 'PayByStripeCardRequiresActionException') {
          modal.confirm({
            title: `Confirm order #${id} edit`,
            content: (
              <Flex vertical gap={16} className="w-full">
                <Alert type="warning" message="If the total amount changes, you must collect the difference from the user. Thank you." />
              </Flex>
            ),
            onCancel: turnOffLoading,
            onOk: () => submit({ values: form.getFieldsValue(), dryRun: false, ignorePaymentErrors: true })
              .then(() => {
                returnToOrder();
                turnOffLoading();
              })
              .catch((e) => {
                message.open({
                  type: 'error',
                  content: getHttpErrorMessage(e),
                  style: {
                    maxWidth: '60%',
                    margin: 'auto',
                  },
                });
                turnOffLoading();
              }),
          });
        } else {
          message.open({
            type: 'error',
            content: getHttpErrorMessage(e),
            style: {
              maxWidth: '60%',
              margin: 'auto',
            },
          });
        }
      })
      .finally(() => {
        setConfirmOpen(false);
        setSubmitting(false);
      });
  }
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(changedValues) => {
          if (changedValues.hasOwnProperty('order_items')) {
            debouncedSync();
          }
        }}
        onFinishFailed={() => {
          message.error('Please fix errors!');
        }}
        onFinish={() => {
          sync();
          setConfirmOpen(true);
        }}
        initialValues={{ has_delivery: false }}
        validateMessages={{ required: '${label} is required!' }}
      >
        <GeneralInfo />
        <DeliveryInfo />
        <ItemsInfo validating={loading} total={(latestOrderData || order)?.food_value} />
        <AdditionalDetails />
        <Space
          size={16}
          className="w-full"
          style={{
            padding: 16,
            justifyContent: 'flex-end',
            borderBottom: '1px solid #DCDDDD',
          }}
        >
          <Button
            disabled={submitting}
            onClick={() => navigate(`/console/orders/${id}`)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={submitting && !confirmOpen}
            disabled={
              confirmOpen ||
              errors.length > 0 ||
              submitting ||
              loading ||
              !order?.is_editable
            }
          >
            Confirm
          </Button>
        </Space>
      </Form>
      <ConfirmationModal
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
          setSubmitting(false);
        }}
        onOk={handleSubmit}
        order={order}
        updatedOrder={latestOrderData}
        loading={loading || submitting}
      />
    </>
  );
}

function ConfirmationModal({
  open, onClose, onOk, order, updatedOrder, loading,
}) {
  const financialInformation = (updatedOrder && !loading) ? (
    <Flex vertical>
      <Typography.Title level={5}>Order summary</Typography.Title>
      {
        [
          ['Subtotal', formatAmount(updatedOrder.food_value)],
          ['Delivery Fee', formatAmount(updatedOrder.delivery_fare)],
          ['Service Fee', formatAmount(updatedOrder.service_fee)],
          (
            updatedOrder.tip_percentage ? (
              ['Tip', `${updatedOrder.tip_percentage}%`]
            ) : (
              ['Tip', formatAmount(updatedOrder.tip)]
            )
          ),
          ['Tax', formatAmount(updatedOrder.order_nets?.total_tax)],
          ['Adjustment Amount', formatAmount(updatedOrder.adjustment_amount)],
        ].map(([label, value]) => (
          <Flex justify="space-between">
            <Typography.Text type="secondary">{label}</Typography.Text>
            <Typography.Text>{value}</Typography.Text>
          </Flex>
        ))
      }
    </Flex>
  ) : null;
  const confirmContent = (
    <>
      <Typography.Text>
        After confirming, these changes will be applied to the order. Are you sure you want to proceed?
      </Typography.Text>
      {financialInformation}
      {
        (!updatedOrder || loading) ? (
          <Skeleton active />
        ) : (
          <Flex justify="space-between">
            <Typography.Text strong>Order's total change</Typography.Text>
            <Typography.Text strong>
              {order?.value}
              {' → '}
              {updatedOrder.value}
            </Typography.Text>
          </Flex>
        )
      }
    </>
  );
  return (
    <Modal
      title={`Confirm order #${order?.id} edit`}
      open={open}
      okButtonProps={{ disabled: loading, loading }}
      cancelButtonProps={{ disabled: loading }}
      onCancel={onClose}
      onOk={onOk}
    >
      <Flex vertical gap={16} className="w-full">{confirmContent}</Flex>
    </Modal>
  );
}
const getTipValues = (hasDelivery, tipPercentage, tipAmount) => {
  if (!hasDelivery) return { tip: undefined, tip_percentage: undefined };
  if ((!tipPercentage || tipPercentage === -1) && tipAmount) {
    return { tip: Number(tipAmount), tip_percentage: null };
  } if (tipPercentage) {
    return { tip: null, tip_percentage: tipPercentage };
  }
  return { tip: null, tip_percentage: null };
};

const serializeData = ({
  address,
  adjustment_amount,
  custom_delivery_fee,
  delivery_message,
  headcount,
  order_items,
  merchant,
  admin_note,
  scheduled_for,
  recipient_name,
  recipient_last_name,
  recipient_number,
  ...order
}) => {
  const orderItems = order_items?.map((item) => ({
    item_related: item.item_related.id,
    qty: item.qty,
    size: item.size.position,
    message: item.message,
    options: item.order_options?.map(({ modifier_option, ...orderOption }) => ({
      ...orderOption,
      modifier_option: modifier_option.id,
      id: undefined,
    })),
    real_price: Number(item.real_price),

  }));

  const { tip, tip_percentage } = getTipValues(order.has_delivery, order.tip_percentage, order.tip);

  const serializeData = {
    custom_delivery_fee: Number((Number(custom_delivery_fee) || 0)?.toFixed(2) || 0),
    headcount,
    admin_note,
    delivery_message,
    restaurant: merchant.value,
    // address: address?.id,
    items: orderItems,
    adjustment_amount: Number(adjustment_amount || 0),
    recipient_name: recipient_name && `${recipient_name} ${recipient_last_name}`,
    recipient_number,
    scheduled_for,
  };
  if (tip !== null) {
    serializeData.tip = tip;
  } else if (tip_percentage) {
    serializeData.tip_percentage = tip_percentage;
  }
  return serializeData;
};

function deserializeData({
  merchant, customer, delivery_fare, adjustment_amount, recipient_name, ...order
}) {
  return {
    ...order,
    merchant: { value: merchant.id, label: merchant.name, ...merchant },
    customer: { value: customer.id, label: customer.name, ...customer },
    custom_delivery_fee: Number(delivery_fare?.toFixed(2) || 0),
    has_delivery_fee: !!delivery_fare,
    has_tip: order.tip !== null && order.has_delivery,
    has_adjustment: !!adjustment_amount,
    adjustment_amount: Number(adjustment_amount || 0),
    order_items: order.order_items.map((orderItem) => ({
      ...orderItem,
      real_price: Number(orderItem.real_price),
      size: { position: orderItem.size_number, name: orderItem.size, serving_size: orderItem.serving_size },
    })),
    recipient: recipient_name ? 'other' : 'self',
    recipient_name,
    recipient_last_name: ' ',
  };
}
function formatErrors(errors) {
  return errors.map((e) => traverse('', e));

  function traverse(parent, child) {
    if (typeof child === 'string') {
      return `${parent} > ${child}`;
    } if (Array.isArray(child)) {
      return traverse(parent, child[0]);
    } if (typeof child === 'object') {
      const key = Object.keys(child)[0];
      return traverse(`${parent} > ${key}`, child[key]);
    }
    return parent;
  }
}
export default OrderForm;
