import { Col, Row, Skeleton } from 'antd';
import Layout from 'components/Layout';
import useFetchedData from 'hooks/useFetchedData';
import React from 'react';
import { useParams } from 'react-router-dom';
import Activities from './Activities';
import Contacts from './Contacts';
import CustomFields from './CustomFields';
import Details from './Details';
import Header from './Header';

import './index.css';

function CompanyInfo() {
  const { id } = useParams();
  const { data: company = {}, loading, mutate: mutateCompany } = useFetchedData(
    `v1/organizations/{{organizationId}}/companies/${id}/`,
  );

  return (
    <Layout overflowX="visible">
      <Header />
      <Row className="w-full" gutter={[0, 24]}>

        <Col
          span={24}
          lg={8}
        >
          <div className="pb-2 sticky" style={{ top: 80 }}>
            {loading && (
              <Skeleton/>
            )}
            {!loading && (
              <Details company={company} fetchCompany={mutateCompany}/>
            )}
            <div
              className="pt-2"
              style={{
                maxHeight: 'calc(100vh - 100px)',
                overflowY: 'auto',
              }}
            >

              <Contacts/>
              {(company?.integration_meta && company?.integration_meta?.custom_fields) && (
                <CustomFields meta={company?.integration_meta}/>
              )}
            </div>

          </div>

        </Col>
        <Col span={24} lg={16} className="lg:pl-5">
          <Activities created_at={company?.created_at}/>
        </Col>
      </Row>
    </Layout>
  );
}

export default CompanyInfo;
