import {
  Col, Form, Radio, Row, Space,
} from 'antd';
import DisableableSection from 'components/DisableableSection';
import React from 'react';
import { captureException } from 'utils/errors';
import { checkLocationIsSupported } from 'containers/Order/requests';
import OrderFormItem from '../../components/OrderFormItem';
import AddressInput from './AddressInput';

function OrderType({ disabled }) {
  const has_delivery = Form.useWatch('has_delivery');
  const merchant = Form.useWatch('merchant');
  const checkAddressZone = (addressId) => checkLocationIsSupported({ merchantId: merchant?.id, addressId })
    .then(({ data }) => data.is_location_supported)
    .catch(captureException);

  return (
    <DisableableSection disabled={disabled}>
      <Row>
        <Col xs={24} lg={7}>
          <OrderFormItem name="has_delivery" label="Order type" fullWidth rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value>Delivery</Radio>
                <Radio value={false}>Pickup</Radio>
              </Space>
            </Radio.Group>
          </OrderFormItem>
        </Col>
        {has_delivery && (
          <Col xs={24} lg={17}>
            <OrderFormItem
              name="address"
              fullWidth
              validateTrigger={['onChange', 'onBlur']}
              rules={disabled ? [] : [{
                required: true,
              }, {
                validator: async (_, value) => {
                  if (!value?.id) {
                    return Promise.resolve();
                  }
                  const isSupported = await checkAddressZone(value.id);
                  if (!isSupported) {
                    return Promise.reject(new Error('Out of operation area. Please select another address.'));
                  }
                  return Promise.resolve();
                },
              }]}
            >
              <AddressInput />
            </OrderFormItem>
          </Col>
        )}
      </Row>
    </DisableableSection>

  );
}
export default OrderType;
