import { Modal, Typography } from 'antd';
import React from 'react';

function DeleteDisableModal({
  isDelete = true, item, open, setOpen, onConfirm,
}) {
  return (
    <Modal
      title={`${isDelete ? 'Deleting' : 'Disabling'} ${item?.name}`}
      open={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ danger: isDelete }}
      okText={isDelete ? 'Delete' : 'Confirm'}
      onOk={onConfirm}
    >
      <Typography.Text>
        {`By confirming this modal the selected item (${item?.name}) will be ${isDelete ? 'deleted permanently.' : 'disabled and won’t show on the customer’s menu.'}`}
      </Typography.Text>
    </Modal>
  );
}

export default DeleteDisableModal;
