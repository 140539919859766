import { Flex, Select, Tag } from 'antd';
import React from 'react';
import { TAGS } from './index';

const { Option } = Select;

const getOptions = () => {
  const options = [];
  for (const tag in TAGS) {
    options.push(<Option key={tag}>
      <Flex align="center" gap={3}>
        {TAGS[tag].icon}
        {TAGS[tag].label}
      </Flex>
    </Option>);
  }
  return options;
};

function DietaryTagSelector({
  defaultValue, value, onChange, maxTagCount, disabled, iconOnly,
}) {
  return (
    <Select
      className="multi-select-filter-box"
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Tag"
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      maxTagCount={maxTagCount}
      filterOption={(inputValue, option) => option.value.toLowerCase().includes(inputValue.toLowerCase())}
      tagRender={(({ value, onClose }) => (
          <Tag
            bordered={false}
            closable
            onClose={onClose}
            className={`flex items-center h-[24px] ${iconOnly ? '' : 'gap-1'} font-bold`}
          >
            {TAGS[value].icon} {!iconOnly && TAGS[value].label}
          </Tag>)
      )}
    >
      {getOptions()}
    </Select>
  );
}

export default DietaryTagSelector;
