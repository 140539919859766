import {
  App, Button, Drawer, Flex, Form, Skeleton,
} from 'antd';
import FormLayout from 'components/FormLayout';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'utils/http';
import { MenuContext } from '../../constants';
import ItemFields from './ItemFields';

function ItemDrawer({
  title, onClose, data, loading: initialLoading, onSubmit, onDelete, onDuplicate,
  open, itemId,
}) {
  const { isGlobal } = useContext(MenuContext);
  const isMobile = useIsMobile();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit(serialize(form.getFieldsValue()))
      .then(() => {
        message.success(`Item ${itemId ? 'updated' : 'created'} successfully!`);
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width={isMobile ? '100%' : 575}
      onClose={onClose}
      open={open}
      styles={{ body: { padding: '16px 0 84px' } }}
    >
      {initialLoading && <Skeleton paragraph={{ rows: 10 }} className="px-5" />}
      {!initialLoading && (
        <Form
          form={form}
          layout="vertical"
          onFinishFailed={() => {
            message.error('Please fix errors!');
          }}
          onFinish={handleSubmit}
          initialValues={{
            sizes: [{
              name: '',
              price: null,
              serving_size: null,
            }],
          }}
        >
          <ItemFields disabled={!isGlobal} />
          <FormLayout.Footer>
            <Flex justify={isGlobal && onDelete ? 'space-between' : 'flex-end'} className="w-full">
              {isGlobal && onDelete && <Button danger disabled={loading} onClick={onDelete}>Delete</Button>}
              <Flex justify="end" gap={8}>
                {onDuplicate ? (
                  <Button disabled={loading} onClick={onDuplicate}>Duplicate</Button>
                ) : (
                  <Button disabled={loading} onClick={onClose}>Close</Button>
                )}
                <Button htmlType="submit" loading={loading} type="primary">{itemId ? 'Save' : 'Create'}</Button>
              </Flex>
            </Flex>
          </FormLayout.Footer>
        </Form>
      )}
    </Drawer>
  );
}

function serialize({
  images, catering_label, sizes, modifiers, ...data
}) {
  return {
    ...data,
    image_ids: images?.map((img) => img.id),
    catering_label: catering_label || 'item',
    sizes: sizes.filter(({ price, name }, index) => price !== null && (index === 0 || name)),
    modifier_ids: modifiers?.map((modifier) => modifier.id),
  };
}

export default ItemDrawer;
