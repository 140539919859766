import Layout from 'components/Layout';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import React from 'react';
import { useParams } from 'react-router';
import OrderForm from './Form';
import Steps from './Steps';

function EditOrder() {
  const { id } = useParams();
  return (
    <Layout header={(
      <Layout.Header
        title={`Edit Order #${id}`}
        backTitle="Orders"
        backPath="/console/orders"
      />
    )}
    >
      <Steps>
        <OrderForm />
      </Steps>
    </Layout>
  );
}
export default withGuard(EditOrder, [PERMISSIONS.ORDERS], { redirect: true });
