import { ArrowRightOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Flex, Radio, Tag, Typography } from 'antd';
import EditForm from 'containers/Companies/Edit';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import http from 'utils/http';

const CUSTOMER_STATUSES = {
  CUSTOMER: { label: 'Customer', color: 'green' },
  INTERESTED: { label: 'Interested', color: 'geekblue' },
  QUALIFIED: { label: 'Qualified', color: 'purple' },
  POTENTIAL: { label: 'Potential' },
  NOT_INTERESTED: { label: 'Not Interested' },
  BAD_FIT: { label: 'Bad Fit', color: 'red' },
  CHURNED: { label: 'Churned', color: 'volcano'}
};

function Details({ company, fetchCompany }) {
  const { id } = useParams();
  const isCompleted = Boolean(company.website && company.address && company.sic_category);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    setOpen(true);
  };

  const onClose = (needRefresh) => {
    setOpen(false);
    if (needRefresh) {
      fetchCompany();
    }
  };

  const onStatusChange = (status) => {
    setLoading(true);
    http.patch(`v1/organizations/{{organizationId}}/companies/${id}/`, { status })
      .then(() => {
        fetchCompany();
      })
      .catch(e => {
        captureException(e);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Flex vertical gap={8}>
        {!isCompleted && (
          <Alert message="Please complete the remaining company information." type="warning"/>
        )}
        <Flex justify="space-between" align="baseline" gap={8}>
          <Typography.Title level={2} className="mb-0 font-extrabold">
            {company.name}
          </Typography.Title>

          <Dropdown
            disabled={loading}
            menu={{
              items: Object.entries(CUSTOMER_STATUSES).map(([key, status]) => ({
                key,
                label: (
                  <Radio
                    checked={status.label === company.status}
                    onChange={() => {
                      if (!loading) {
                        onStatusChange(status.label);
                      }
                    }}
                  >
                    <Tag color={status.color} className="font-bold py-0.5">{status.label}</Tag>
                  </Radio>
                ),
              })),
            }}
          >
            <Flex gap={0}>
              <Tag
                color={CUSTOMER_STATUSES[company.status?.toUpperCase().split(' ').join('_')]?.color}
                style={{ borderRadius: '4px 0 0 4px ' }}
                className={`font-bold py-1 px-3 mr-0 ${loading ? 'opacity-50' : ''}`}
              >
                {company.status}
              </Tag>
              <Button
                disabled={loading}
                icon={<DownOutlined style={{ fontSize: 12 }}/>}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '0 4px 4px 0',
                  borderLeft: 'none',
                }}
              />
            </Flex>
          </Dropdown>

        </Flex>

        <Flex vertical gap={8}>
          <InfoRow title="Website" value={company.website} isLink/>
          <InfoRow title="Address" value={company.address}/>
          <InfoRow title="SIC category" value={company.sic_category}/>
          <InfoRow title="Creation date" value={dayjs(company.created_at).format('MMM DD, hh:mm A')}/>
        </Flex>
        <Flex>
          {isCompleted ? (
            <Button onClick={onEdit} icon={<EditOutlined/>}>
              Edit information
            </Button>
          ) : (
            <Button onClick={onEdit} type="primary">
              Complete
              <ArrowRightOutlined/>
            </Button>
          )}
        </Flex>
      </Flex>

      <EditForm company={company} open={open} onClose={onClose}/>
    </>

  );
}

function InfoRow({ title, value, isLink }) {
  const getFullUrl = (url) => {
    if (!url) {
      return '';
    }
    return url.startsWith('http') ? url : `https://${url}`;
  };

  if (!value) {
    return null;
  }
  return (
    <Flex gap={16}>
      <Typography.Text className="text-[#717679] flex-shrink-0">{title}</Typography.Text>
      {isLink ? (
        <Typography.Link href={getFullUrl(value)} target="_blank" className="font-bold">{value || '-'}</Typography.Link>
      ) : (
        <Typography.Text className="font-bold">{value || '-'}</Typography.Text>
      )}
    </Flex>
  );
}

export default Details;
