import { Button, Modal } from 'antd';
import Map from 'components/Map';
import useAutoComplete from 'hooks/useAutoComplete';
import React, { useEffect, useMemo, useState } from 'react';
import { StyledWrapper } from './styles';

function AddAddressModal({
  open,
  onClose,
  onChange,
  value,
}) {
  const [address, setAddress] = useState({ });
  const [loading, setLoading] = useState(false);
  const addressOptions = useAutoComplete(value?.text ?? '');

  const cleanupState = () => {
    setAddress({ });
    setLoading(false);
  };

  useEffect(() => {
    if (value && addressOptions.length) {
      setAddress({
        ...value,
        place_id: addressOptions[0].place_id, // TODO check if we can remove it or not?
      });
    } else {
      setAddress(value);
    }
  }, [value, addressOptions, open]);

  const isDisable = useMemo(() => loading || !address?.lat || !address?.lng || !address?.text, [address, loading]);

  if (!open) {
    return null;
  }

  function handleConfirm() {
    onChange(address);
    onClose();
  }

  return (
    <Modal
      open={open}
      title="Choose Location"
      onCancel={() => {
        onClose();
        cleanupState();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onClose();
            cleanupState();
          }}
        >
          Cancel
        </Button>,
        <Button
          onClick={handleConfirm}
          type="primary"
          disabled={isDisable}
        >
          Confirm location
        </Button>,
      ]}
    >
      <StyledWrapper>
        <div className="top">
          <div className="map">
            <Map
              center={address}
              onChange={setAddress}
            >
              <Map.FixedMarker />
              <Map.SearchInput onChange={setAddress} value={address} />
            </Map>
          </div>
        </div>
      </StyledWrapper>
    </Modal>
  );
}

export default AddAddressModal;
