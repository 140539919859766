import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import OrderOverlay from 'components/OrderOverlay';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import { truncate } from 'lodash-es';
import React, { useMemo, useState } from 'react';

const { Text } = Typography;

function AgentFollowUpEvent({ event: { data: call, occurred_at: date } }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [open, setOpen] = useState(false);
  const [orderDetailOpen, setOrderDetailOpen] = useState(false);

  const handleViewOrder = (e) => {
    e.stopPropagation();
    setOrderDetailOpen(true);
  };

  const callDuration = useMemo(() => {
    const minutes = dayjs.duration(call.call_duration_seconds, 'seconds').minutes();
    const seconds = dayjs.duration(call.call_duration_seconds, 'seconds').seconds();
    return `${minutes ? minutes + ' min ' : ''}${seconds ? seconds + ' secs' : ''}`;
  }, [call.call_duration_seconds]);

  return (
    <>
      <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-full"
            onClick={() => call.custom_analysis_data.call_summary_transcript.length >= 70 && setOpen(!open)}>
        <Flex justify="space-between" align="center" vertical={isMobile} className="w-full">
          <Flex gap={12} align="center">
            <ArrowRightOutlined style={{ transform: 'rotate(-45deg)' }}/>
            <Text className="font-bold text-[16px]">
              Order #{call.order_number} follow up call
            </Text>
            <Text>
              {callDuration}
            </Text>
          </Flex>

          <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
        </Flex>
        {call.custom_analysis_data.need_callback && (
          <>
          <Flex justify="space-between">
            <Flex gap={16}>
              <Flex gap={8}>
                <Text className="flex-shrink-0">Status: </Text>
                <Text className="font-bold">Requires callback</Text>
              </Flex>
              <Flex gap={8}>
                <Text className="flex-shrink-0">Callback time: </Text>
                <Text className="font-bold">{call.custom_analysis_data.callback_time || '-'}</Text>
              </Flex>
            </Flex>

            <Button onClick={handleViewOrder}>
              Order details
            </Button>
          </Flex>

          </>
        )}
        {!call.custom_analysis_data.need_callback && (
          <>
            <Flex justify={call.custom_analysis_data.feedback ? 'space-between' : 'flex-end'} align="baseline" gap={4}>
              {call.custom_analysis_data.feedback && (
                <Flex gap={8}>
                  <Text className="flex-shrink-0">Customer feedback: </Text>
                  <Text className="font-bold">{call.custom_analysis_data.feedback}</Text>
                </Flex>
              )}

              <Button onClick={handleViewOrder}>
                Order details
              </Button>
            </Flex>

            <Flex gap={8}>
              <Text>Satisfaction score:</Text>
              <Text className="font-bold">{call.custom_analysis_data.satisfaction_score}</Text>
            </Flex>

            <Flex gap={16}>
              <Flex gap={8}>
                <Text>Next event:</Text>
                <Text className="font-bold">{call.custom_analysis_data.next_event_date || '-'}</Text>
              </Flex>
              <Flex gap={8}>
                <Text>Next follow-up date:</Text>
                <Text
                  className="font-bold">{call.custom_analysis_data.next_followup_date ? dayjs(call.custom_analysis_data.next_followup_date)
                  .format('MMM DD, YYYY') : '-'}</Text>
              </Flex>
            </Flex>
          </>
        )}


        {
          call.custom_analysis_data.call_summary_transcript && (
            <Flex
              vertical={isMobile}
              justify="space-between"
              align="center"
              gap={4}
            >
              <Text style={{ height: open ? 'auto' : '20px', overflow: 'hidden' }}>
                <Text className="text-[12px]" type="secondary">
                  Call summary:
                </Text>
                {' '}
                {open ? call.custom_analysis_data.call_summary_transcript : truncate(call.custom_analysis_data.call_summary_transcript, {
                  length: 70,
                  omission: '...',
                })}
              </Text>
              {
                !open && call.custom_analysis_data.call_summary_transcript.length >= 70 && (
                  <Button type="link" className="pr-0">
                    Show more
                  </Button>
                )
              }
            </Flex>
          )
        }
      </Flex>

      <OrderOverlay
        orderId={orderDetailOpen && call.order_id}
        onClose={() => setOrderDetailOpen(false)}
        disableActions
      />
    </>
  );
}

export default AgentFollowUpEvent;
