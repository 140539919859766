import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Switch } from 'antd';
import DietaryTags from 'components/DietaryTags';
import React from 'react';
import { priceTransformer } from 'utils';
import { getImage } from 'utils/image';

const columns = ({
  isItemPool, isGlobal, onEdit, onRemove, onChangeItemAvailability, sortable, loadingItemIds,
  onAddItem,
}) => ([
  sortable && {
    key: 'sort',
    width: 40,
    align: 'right',
  },
  {
    title: 'Item Name',
    key: 'name',
    render: (_, data) => (
      <Space>
        {getImage({ data }) && (
          <img
            className="image"
            src={getImage({ data, size: 'md' })}
            alt={data?.name || ''}
            width={25}
            height={25}
            style={{ border: '1px solid #DDE1E4', borderRadius: 5, marginRight: 4 }}
          />
        )}
        {data.name}
      </Space>
    ),
  },
  {
    title: 'Unit',
    key: 'catering_label',
    width: 100,
  },
  {
    title: 'Sizes',
    key: 'sizes',
    width: 120,
    render: (sizes) => sizes?.filter((size) => size.price)
      .map((size) => size.name || 'One size').join(', '),
  },
  {
    title: 'Base Price',
    key: isGlobal ? 'minimum_price' : 'value',
    width: 120,
    render: (value) => priceTransformer(value),
  },
  {
    title: 'Tags',
    key: 'dietary_tags',
    width: isItemPool ? 70 : 190,
    render: (value) => <DietaryTags tags={value} maxCount={isItemPool ? 2 : 3} />,
  },
  (!isItemPool && !isGlobal) && {
    title: 'Item availability',
    key: 'is_active',
    width: 160,
    render: (value, record) => (
      <Switch
        loading={loadingItemIds.includes(record.id)}
        checked={value}
        onChange={() => onChangeItemAvailability?.(record.id)}
      />
    ),
  },
  {
    title: isGlobal && (
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          onAddItem?.();
        }}
      >
        Add item
      </Button>
    ),
    key: 'operation',
    width: (!isItemPool && isGlobal) ? 85 : 40,
    align: 'right',
    render: (_, record) => (
      <Space size="small">
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => {
            onEdit?.(record.id);
          }}
        />
        {(!isItemPool && isGlobal) && (
          <Button type="text" icon={<DeleteOutlined />} loading={loadingItemIds.includes(record.id)} onClick={(e) => onRemove?.(e, record.id)} />
        )}
      </Space>
    ),
  },
].filter((c) => c));
export default columns;
