import { Helmet } from 'react-helmet';
import React from 'react';
import { WL_CONFIG } from 'constants';

const { name, favicon } = WL_CONFIG;
function SEO() {
  return (
    <Helmet>
      <title>{`${name || ''} Admin Panel`}</title>
      {favicon && <link rel="icon" href={favicon} />}
      {favicon && <link rel="apple-touch-icon" href={favicon} />}
    </Helmet>
  );
}

export default SEO;
