import { WL_CONFIG } from 'constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 412px;
  @media (max-width: 767.98px) {
    width: 300px;
  }

  .top {
    height: 64px;
    padding: 20px;
    color: #131b1f;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    border-bottom: 1px solid #dde1e4;
  }

  .box {
    background: #f5f8f7;
    color: #131b1f;
    padding: 20px 16px;
    border-top: 1px solid #dde1e4;
    border-bottom: 1px solid #dde1e4;

    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
    }

    &-detail {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    &-btn {
      color: ${WL_CONFIG.colors.primary};
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
    }
  }
`;
