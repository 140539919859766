import { BankOutlined, BarChartOutlined, FileTextOutlined, InboxOutlined, ShopOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import CardLink from 'components/CardLink';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React from 'react';

function ReportTypesContainer() {
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const actions = [
    isAdmin && {
      key: 'general_report',
      title: 'General report',
      icon: <BarChartOutlined />,
      description: 'A comprehensive summary of your business which includes information such as your total sales, total number of orders, etc.',
      link: '/console/reports/general',
    },
    {
      key: 'merchants_report',
      title: 'Restaurants report',
      icon: <ShopOutlined />,
      description: 'This report includes general information about your restaurants separated by restaurant’s name.',
      link: '/console/reports/merchants',
    },
    isAdmin && {
      key: 'orders_report',
      title: 'Orders report ',
      icon: <InboxOutlined />,
      description: 'This report includes a log of all completed and cancelled orders as well as details such as restaurant net, RDS net, etc.',
      link: '/console/reports/orders',
    },
    {
      key: 'sales_report',
      title: 'Sales report',
      icon: <FileTextOutlined />,
      description: 'This report provides sales records, call logs, lead details, and performance metrics for specific timeframes.',
      link: '/console/reports/sales',
    },
    {
      key: 'payouts_report',
      title: 'Payouts report',
        icon: <BankOutlined />,
      description: 'A summary of your total sales, number of orders, and payout details. Omnicart sends daily or weekly payouts, but merchants cu...',
      link: '/console/reports/payouts',
    },
  ].filter(Boolean);
  return (
    <Row gutter={[16, 16]}>
      {actions.map(({
        key, title, icon, description, link,
      }) => (
        <Col xs={24} sm={12} lg={8} key={key}>
          <CardLink
            title={title}
            icon={icon}
            description={description}
            link={link}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ReportTypesContainer;
