import { Select } from 'antd';
import { PaymentTypes } from 'constants';
import React from 'react';

const OPTIONS = Object.entries(PaymentTypes).map(([label, value]) => ({ value, label }));
function PaymentTypeSelectInput({ value, onChange, ...props }) {
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      value={value}
      style={{ width: 200 }}
      onChange={onChange}
      options={OPTIONS}
      mode="multiple"
      placeholder="Payments"
      allowClear
      filterOption={filterOption}
      {...props}
    />
  );
}

export default PaymentTypeSelectInput;
