import { PlusOutlined } from '@ant-design/icons';
import {
  App, Button, Flex, Spin,
} from 'antd';
import EmptyComponent from 'components/Empty';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants';
import useEventEffect from 'hooks/useEvent';
import useFetchedData from 'hooks/useFetchedData';
import React, { useEffect, useState } from 'react';
import {
  Outlet, Route, Routes, useNavigate, useParams,
} from 'react-router';
import { captureException } from 'utils/errors';
import http, { getHttpErrorMessage } from 'utils/http';
import CategoriesTable from '../Menus/CategoriesTable';
import CategoryInput from '../Menus/CategoryInput';
import MenuFormDrawer from '../Menus/MenuFormDrawer';
import MenuToolbar from './MenuToolbar';

function Menus() {
  const navigate = useNavigate();
  const [createMode, setCreateMode] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { modal, message } = App.useApp();
  const { menuId } = useParams();

  const { data: menus, loading, mutate: refreshMenus } = useFetchedData(
    {
      url: 'v1/organizations/{{organizationId}}/global-menus/',
      query: { query: '{id,name,is_active,active_times}' },
    },
    ({ results } = {}) => results || EMPTY_ARRAY,
  );
  const { data: menu, loading: menuLoading, mutate: refreshMenu } = useFetchedData(
    menuId && `v1/organizations/{{organizationId}}/global-menus/${menuId}/`,
    ({ data: menu } = {}) => menu || EMPTY_OBJECT,
  );
  const hasMenu = !!menus.length;

  useEventEffect(() => {
    refreshMenu();
  }, 'refresh_items');

  useEventEffect(() => {
    refreshMenu();
  }, 'refresh_categories');

  useEventEffect(() => {
    refreshMenus();
  }, 'refresh_menus');

  useEffect(() => {
    if (!menuId && menus?.length) {
      navigate(`/console/menus/${menus[0].id}`);
    }
  }, [menuId, menus, navigate]);

  async function handleCreateMenu(data) {
    const result = await http.post('/v1/organizations/{{organizationId}}/global-menus/', data);
    navigate(`/console/menus/${result.data?.data?.id}`);
  }
  async function handleImportMenu(data) {
    await http.post('/v1/organizations/{{organizationId}}/global-menus/ez-cater/', data);
  }
  async function handleDelete() {
    modal.confirm({
      title: `Deleting ${menu.name}`,
      content: `By confirming this modal the selected menu (${menu.name}) will be deleted permanently.`,
      onOk: () => http.delete(`/v1/organizations/{{organizationId}}/global-menus/${menuId}/`)
        .then((res) => {
          message.success('Menu deleted successfully!');
          navigate('..');
          refreshMenus();
          setOpenEditModal(false);
        })
        .catch((e) => {
          captureException(e);
          message.error(getHttpErrorMessage(e));
        }),
    });
  }
  async function handleEdit(data) {
    await http.put(`/v1/organizations/{{organizationId}}/global-menus/${menuId}/`, data);
    refreshMenu();
    setOpenEditModal(false);
  }

  return (
    <>
      {loading && (
        <Flex justify="center" align="center" className="h-[50vh]">
          <Spin size="large" />
        </Flex>
      )}
      {!loading && !hasMenu && (
        <EmptyComponent
          title="Start by adding your first global menu to build your restaurant's menu effortlessly!"
          primaryAction={(
            <Button
              icon={<PlusOutlined />}
              onClick={() => setCreateMode('scratch')}
              type="primary"
            >
              Create menu
            </Button>
          )}
          secondaryAction={(
            <Button
              onClick={() => setCreateMode('import')}
            >
              Import from another platform
            </Button>
          )}
        />
      )}
      <MenuFormDrawer
        title={createMode === 'scratch' ? 'Create menu' : 'Import menu from another platform'}
        open={createMode === 'scratch' || createMode === 'import'}
        onSubmit={createMode === 'scratch' ? handleCreateMenu : handleImportMenu}
        onClose={() => setCreateMode(null)}
        showImportForm={createMode === 'import'}
      />
      <MenuFormDrawer
        title="Edit menu"
        open={openEditModal}
        onSubmit={handleEdit}
        onClose={() => setOpenEditModal(false)}
        onDelete={handleDelete}
        menu={menu}
        loading={loading}
        menuId={menuId}
      />

      {!loading && hasMenu && (
        <MenuToolbar
          onCreate={setCreateMode}
          onEdit={() => setOpenEditModal(true)}
          hasCategory={menu?.categories?.length}
          menus={menus}
          selectedMenuId={menuId}
          loading={loading}
        />
      )}

      <Outlet />

      <Routes>
        {!loading && hasMenu && (
          <Route
            path="/"
            element={(
              <CategoriesTable
                isGlobal
                menuLoading={menuLoading}
                menu={menu}
              />
            )}
          />
        )}
        <Route
          path="/category/add"
          element={(
            <CategoryInput
              onClose={() => navigate('..')}
              className="mt-4"
              menuId={menuId}
            />
          )}
        />
      </Routes>

    </>
  );
}

export default Menus;
