import React from 'react';

function DisableableSection({ disabled, children }) {
  return (
    <div style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}>
      {children}
    </div>
  );
}

export default DisableableSection;
