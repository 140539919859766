import {
  App,
  Empty,
} from 'antd';
import DraggableTable from 'components/DraggableTable';
import React, {
  useMemo,
  useState,
} from 'react';
import { captureException } from 'utils/errors';
import { generateColumns } from 'utils/table';
import EditItemDrawer from 'containers/GlobalMenuManagement/Items/EditItemDrawer';
import CreateItemDrawer from 'containers/GlobalMenuManagement/Items/Create';
import ItemsPoolDrawer from 'containers/GlobalMenuManagement/Items/ItemsPoolDrawer';
import http, { getHttpErrorMessage } from 'utils/http';
import { EventEmitter } from 'utils/event';
import columns from './columns';
import {
  changeItemAvailability, deleteCategoryItem, reorderGlobalItemsPosition, reorderItemPosition,
} from './requests';

function ItemsTable({
  category, onItemsChange, menuId, isGlobal,
}) {
  const [editingItemId, setEditingItemId] = useState(null);
  const [createItemOpen, setCreateItemOpen] = useState(false);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const { message } = App.useApp();

  const [loadingItemIds, setLoadingItemIds] = useState([]);

  const onRemoveItem = (e, id) => {
    e.preventDefault();
    const itemIds = items.filter((item) => item.id !== id).map((item) => item.id);
    setLoadingItemIds([id]);
    deleteCategoryItem({ menuId, categoryId: category.id, itemIds })
      .then((res) => {
        setLoadingItemIds([]);
        EventEmitter.emit('refresh_items');
      })
      .catch(() => {
        captureException(e);
        message.error('Something went wrong! please try again');
      });
  };

  const handleAddItem = (newItemIds) => {
    const itemIds = Array.from(new Set(newItemIds));
    return http.put(`/v1/organizations/{{organizationId}}/global-menus/${menuId}/categories/${category.id}/items/`, itemIds)
      .then((res) => {
        message.success('Items added successfully');
        setAddItemOpen(false);
        EventEmitter.emit('refresh_items');
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      });
  };

  const onChangeItemAvailability = (id) => {
    setLoadingItemIds((prev) => [...prev, id]);
    changeItemAvailability(id).then((res) => {
      EventEmitter.emit('refresh_items');
    })
      .catch((e) => {
        captureException(e);
        message.error('Something went wrong! please try again');
      })
      .finally(() => {
        // add a delay to menu update
        setTimeout(() => {
          setLoadingItemIds((prev) => prev.filter((itemId) => itemId !== id));
        }, 2500);
      });
  };

  const cols = columns({
    isItemPool: false,
    isGlobal,
    sortable: true,
    onEdit: (itemId) => {
      setEditingItemId(itemId);
    },
    onRemove: onRemoveItem,
    onChangeItemAvailability,
    loadingItemIds,
    onAddItem: () => setAddItemOpen(true),
  });

  const items = useMemo(() => category.items?.sort((
    a,
    b,
  ) => a.item_position?.[0]?.position - b.item_position?.[0]?.position)
    ?.map((item) => ({ ...item, key: item.id })) || [], [category]);

  const onReorderItemCallback = ({ id, position, categoryId }) => {
    reorderItemPosition({ itemId: id, categoryId, position }).then((res) => {
    }).catch((e) => captureException(e));
  };

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);
  const normalizedColumns = useMemo(() => generateColumns(cols), [cols]);
  return (
    <>
      <DraggableTable
        columns={normalizedColumns}
        dataSource={items}
        setDataSource={(items) => {
          onItemsChange({ items });
          if (isGlobal) {
            reorderGlobalItemsPosition({
              itemIds: items.map((item) => item.id),
              menuId,
              categoryId: category.id,
            }).then(() => EventEmitter.emit('refresh_items'))
              .catch(captureException);
          }
        }}
        locale={{ emptyText: <Empty description="No items" /> }}
        onDragCallback={(dragInfo) => {
          if (isGlobal) {
            return;
          }
          onReorderItemCallback({
            id: dragInfo.id,
            position: dragInfo.position,
            categoryId: category.id,
          });
        }}
      />
      <EditItemDrawer
        itemId={editingItemId}
        onClose={() => {
          setEditingItemId(null);
          EventEmitter.emit('refresh_items');
        }}
      />
      <CreateItemDrawer
        open={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false);
          EventEmitter.emit('refresh_items');
        }}
      />
      <ItemsPoolDrawer
        open={addItemOpen}
        onClose={() => setAddItemOpen(false)}
        onAdd={handleAddItem}
        onCreate={() => {
          setCreateItemOpen(true);
        }}
        selectedItemIds={itemIds}
      />
    </>
  );
}

export default ItemsTable;
