import * as Sentry from '@sentry/react';
import { App as AntApp, ConfigProvider, theme } from 'antd';
import AdminProvider from 'components/AdminProvider';
import Intercom from 'components/Intercom';
import NewOrdersProvider from 'components/NewOrdersProvider';
import OrganizationConfigProvider from 'components/OrganizationConfigProvider';
import { getBaseURL, WL_CONFIG } from 'constants';
import React, { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import { getQueryStringValue } from 'utils';
import { SessionJsonStorage } from 'utils/storage';

import Routes from 'routes';
import './App.css';
import SEO from 'components/SEO';
import ErrorBoundary from './components/errorBoundary';
import ErrorFallback from './components/errorFallback';
import { ModalProvider } from './context/modalContext';

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
          }),
        ],
      });
    }

    const baseURL = getQueryStringValue('baseURL');
    if (baseURL) {
      SessionJsonStorage.set({
        key: 'baseURL',
        value: baseURL,
      });
      window.location.replace(window.location.pathname);
    }
  }, []);
  const baseURL = getBaseURL();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SEO />
      {baseURL.customized && (
        <div style={{
          background: 'crimson',
          color: '#fff',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 10000,
          fontSize: 12,
        }}
        >
          baseURL:
          {' '}
          {baseURL.url}
        </div>
      )}
      <AdminProvider>
        <ConfigProvider
          theme={{
            cssVar: true,
            components: {
              Input: {
                addonBg: 'white',
              },
              Button: {
                primaryShadow: 'none',
              },
            },
            token: {
              colorPrimary: WL_CONFIG.colors.primary,
              colorSuccess: WL_CONFIG.colors.primary,
              colorError: WL_CONFIG.colors.error,
              colorLink: WL_CONFIG.colors.primary,
              colorLinkHover: WL_CONFIG.colors.primary,
              borderRadius: 4,
              fontSize: 14,
            },
          }}
        >
          <AntApp>
            <OrganizationConfigProvider>
              {
                ['prod', 'stage'].includes(process.env.REACT_APP_ENV) && (
                  <Intercom />
                )
              }
              <ModalProvider>
                <Router basename={process.env.REACT_APP_BASENAME || ''}>
                  <NewOrdersProvider>
                    <Routes />
                  </NewOrdersProvider>
                </Router>
              </ModalProvider>
            </OrganizationConfigProvider>
          </AntApp>
        </ConfigProvider>
      </AdminProvider>
    </ErrorBoundary>
  );
}

export default App;
