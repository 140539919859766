/* eslint-disable react/no-unstable-nested-components */
import {
  DownOutlined, EllipsisOutlined, PlusOutlined, UpOutlined,
} from '@ant-design/icons';
import {
  App,
  Button,
  Dropdown, Space, Table, Typography,
} from 'antd';
import DraggableTable from 'components/DraggableTable';
// Todo we have to merge global menus and  old menu management and share these utils and requests
import {
  deleteCategory,
  deleteGlobalCategory,
  reorderCategoryPosition,
  reorderGlobalCategoriesPosition,
} from 'containers/MenuManagement/Overview/MenuItemsTable/requests';
import { getFilteredItems } from 'containers/MenuManagement/Overview/MenuItemsTable/utils';
import { orderBy } from 'lodash-es';
import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router';
import { captureException } from 'utils/errors';
import { EventEmitter } from 'utils/event';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import CategoryInput from '../CategoryInput';
import ItemsTable from './ItemsTable';
import './style.css';

function CategoriesTable({
  menu, searchTerm = '', loading, menuLoading,
}) {
  const { isGlobal } = useContext(MenuContext);
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState();
  const [expandedRows, setExpandedRows] = useState([]);
  const { modal, message } = App.useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (menu) {
      setExpandedRows(menu.categories?.map((category) => category.id));
    }
  }, [menu]);

  const onRemoveCategory = (e, category) => {
    e.preventDefault();
    modal.confirm({
      title: `Deleting ${category.name}`,
      content: `By confirming this modal the selected category(${category.name}) will be deleted permanently.`,
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => (isGlobal ? deleteGlobalCategory({ categoryId: category.id, menuId: menu?.id }) : deleteCategory(category.id)).then((res) => EventEmitter.emit('refresh_categories'))
        .catch((e) => {
          captureException(e);
          message.error('Something went wrong! please try again');
        }),
    });
  };

  const columns = [
    {
      key: 'sort',
      width: 40,
      align: 'center',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      inputType: 'text',
      editable: true,
      render: (value, data) => (editingCategory?.id === data.id ? (
        <CategoryInput category={editingCategory} ff="sdf" onClose={() => setEditingCategory()} />
      ) : (
        <Space wrap size="large">
          <Typography.Text strong style={{ fontSize: 14, fontWeight: 700 }}>{value}</Typography.Text>
          {!!data.items?.length && (
            <Typography.Text style={{ fontSize: 12, color: '#666B71' }}>
              {data.items?.length}
              {' '}
              items
            </Typography.Text>
          )}
        </Space>
      )),

    },
    isGlobal &&
      {
        key: 'action',
        width: 40,
        render: (value, data) => (data.key === editingCategory?.key ? null : (
          <Dropdown
            onClick={(e) => e.stopPropagation()}
            menu={{
              items: [
                {
                  key: '1',
                  label: <a type="text" onClick={(e) => setEditingCategory(data)}>Edit</a>,
                },
                {
                  key: '2',
                  label: <a type="text" onClick={(e) => onRemoveCategory(e, data)}>Remove</a>,
                },
              ],
            }}
          >
            <EllipsisOutlined />
          </Dropdown>
        )
        ),
      },
    Table.EXPAND_COLUMN,
  ].filter(Boolean);

  const sortedCategories = useMemo(() => {
    if (!menu) {
      return [];
    }
    const indexedCategories = menu.categories?.map((category) => ({ ...category, key: category.id })) || [];
    return orderBy(indexedCategories, ['position'], ['asc']);
  }, [menu]);

  useEffect(() => {
    if (sortedCategories?.length) {
      setCategories(sortedCategories);
    }
  }, [sortedCategories]);

  useEffect(() => {
    setCategories(getFilteredItems({ searchTerm, categories: sortedCategories }));
  }, [searchTerm, sortedCategories]);

  function customExpandIcon(props) {
    if (!expandedRows.includes(props.record.key) && isGlobal) {
      return (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            navigate(`/console/menus/${menu?.id}/category/${props.record.id}/items`);
          }}
        >
          Add item
        </Button>
      );
    }
    if (props.expanded) {
      return <UpOutlined onClick={(e) => props.onExpand(props.record, e)} />;
    }
    return <DownOutlined onClick={(e) => props.onExpand(props.record, e)} />;
  }

  return (
    <div style={{ marginTop: 16 }}>
      {!!categories?.length && (
        <DraggableTable
          className="menu-items-table"
          columns={columns}
          dataSource={categories}
          setDataSource={(categories) => {
            setCategories(categories);
            if (isGlobal) {
              reorderGlobalCategoriesPosition({
                menuId: menu?.id,
                categoryIds: categories.map((cat) => cat.id),
              });
            }
          }}
          showHeader={false}
          bordered
          loading={menuLoading}
          onDragCallback={({ id, position }) => {
            if (isGlobal) {
              return;
            }
            reorderCategoryPosition({ categoryId: id, position }).then((res) => {
              EventEmitter.emit('refresh_categories');
            }).catch((e) => {
              captureException(e);
              message.error('Something went wrong! please try again');
            });
          }}
          expandable={{
            expandedRowRender: (record) => (
              <ItemsTable
                menuId={menu?.id}
                isGlobal={isGlobal}
                category={record}
                onItemsChange={({ items }) => {
                  const updatedCategories = categories.map((cat) => (cat.id === record.id ? { ...cat, items } : cat));
                  setCategories(updatedCategories);
                }}
              />
            ),
            expandIcon: customExpandIcon,
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: (keys) => setExpandedRows(keys),
          }}
          scroll={{ x: 400 }}
          onRow={(record) => ({
            className: 'menu-item-row',
            onClick: () => {
              const isExpanded = expandedRows.includes(record.key);
              setExpandedRows(isExpanded
                ? expandedRows.filter((key) => key !== record.key)
                : [...expandedRows, record.key]);
            },
          })}
        />
      )}

    </div>
  );
}

export default CategoriesTable;
