import { Modal, Typography } from 'antd';
import React from 'react';

function EditConfirmation(
  {
    open, setOpen, onConfirmEdit,
  },
) {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    onConfirmEdit();
    setOpen(false);
  };

  return (
    <Modal
      title="Edit Confirmation"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Confirm"
      cancelText="Cancel"
    >
      <Typography.Text>
        By confirming this action, changes will be applied, potentially affecting and removing related data.
      </Typography.Text>
    </Modal>
  );
}

export default EditConfirmation;
