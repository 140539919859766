import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Flex, Space, Switch, Tag, Tooltip } from 'antd';
import { TAGS } from 'components/DietaryTags';
import React from 'react';

const columns = ({ isGlobal, onChangeAvailability, onEdit }) => ([
  {
    title: 'Name', key: isGlobal ? 'name' : 'title', width: 250,
  },
  {
    title: 'Options',
    key: 'options',
    dataIndex: 'options',
    render: (value, record) => {
      const options = record.options?.map((option) => (
        <Tag key={option.id} color={option.modifiers_children?.length ? 'green' : ''}>
          <Flex gap={2} align="center">
            {option[isGlobal ? 'name' : 'title']}
            {option.modifiers_children?.length > 0 && ' (nested)'}
            <Flex>
              {option.dietary_tags?.map((option) => TAGS[option]?.icon)}
            </Flex>
          </Flex>

        </Tag>
      )) || [];
      return (
        <Space size={[0, 8]} wrap>
          {options.slice(0, 5)}
          {options.length > 5 && (
            <Tooltip
              title={options.length > 5 ? <Space size={[0, 4]} wrap>{options}</Space> : ''}
              color="white"
              placement="bottomRight"
              overlayStyle={{ maxWidth: '600px' }}
            >
              <Tag bordered={false}><EllipsisOutlined/></Tag>
            </Tooltip>
          )}
        </Space>
      );
    },
  },
  !isGlobal && {
    title: 'Availability',
    key: 'is_active',
    dataIndex: 'is_active',
    width: 110,
    align: 'center',
    render: (value, record) => (
      <Switch checked={value} onChange={() => onChangeAvailability(record)}/>
    ),
  },
  {
    key: 'action',
    width: 40,
    align: 'center',
    render: (_, record) => (
      <Space size="small">
        <Button
          type="text"
          icon={<EditOutlined/>}
          onClick={() => {
            onEdit?.(record);
          }}
        />
      </Space>
    ),
  },
].filter(c => c));
export default columns;
