import React from 'react';
import { ReactComponent as InfoSquareIcon } from 'assets/icons/info-square.svg';
import { ReactComponent as ArrowDownIcon } from './arrowDown.svg';
import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as ChristmasOCLogoIcon } from './christmas-logo.svg';
import { ReactComponent as ChristmasOCSmallLogoIcon } from './christmas-small-logo.svg';
import { ReactComponent as CityIcon } from './city.svg';
import { ReactComponent as CustomersIcon } from './customers.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import deliverthatLogo from './deliver-that-logo.png';
import deliverthatLogoSmall from './deliver-that-logo-small.png';
import nccLogo from './ncc-logo.png';
import nccLogoSmall from './ncc-logo-small.png';
import { ReactComponent as DeliveryIcon } from './delivery.svg';
import { ReactComponent as EmptyTableIcon } from './empty-table.svg';
import { ReactComponent as ExportIcon } from './export.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { ReactComponent as MarketingIcon } from './marketing.svg';
import { ReactComponent as OCLogoIcon } from './OCLogo.svg';
import { ReactComponent as OrdersIcon } from './orders.svg';
import { ReactComponent as PickupIcon } from './pickup.svg';
import { ReactComponent as RegistrationIcon } from './registration.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as OCSmallLogoIcon } from './small-logo.svg';
import { ReactComponent as StaffIcon } from './staff.svg';

function DeliverthatLogo(props) {
  return (
    <img
      src={deliverthatLogo}
      alt="delivery that logo"
      {...props}
    />
  );
}

function DeliveryThatSmallLogo(props) {
  return (
    <img
      src={deliverthatLogoSmall}
      alt="delivery that logo"
      {...props}
    />
  );
}

function NCCLogo(props) {
  return (
    <img
      src={nccLogo}
      alt="NCC logo"
      {...props}
    />
  );
}

function NCCLogoSmall(props) {
  return (
    <img
      src={nccLogoSmall}
      alt="NCC logo"
      {...props}
    />
  );
}

export {
  ArrowDownIcon,
  BackIcon, ChristmasOCLogoIcon,
  ChristmasOCSmallLogoIcon, CityIcon, CustomersIcon,
  DashboardIcon,
  DeliveryIcon,
  EmptyTableIcon,
  ExportIcon,
  InfoSquareIcon,
  LogoutIcon,
  MarketingIcon,
  OCLogoIcon,
  OCSmallLogoIcon,
  OrdersIcon,
  PickupIcon,
  RegistrationIcon,
  SearchIcon,
  StaffIcon,
  DeliverthatLogo,
  DeliveryThatSmallLogo,
  NCCLogo,
  NCCLogoSmall,
};
