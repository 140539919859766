import { CheckCircleFilled } from '@ant-design/icons';
import { Form, Input } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React, { useMemo } from 'react';

function ImportForm() {
  // const url = Form.useWatch('url');
  //
  // const isValidUrl = useMemo(() => {
  //   try {
  //     new URL(url);
  //     return true;
  //   } catch (_) {
  //     return false;
  //   }
  // }, [url]);

  return (
    <FormLayout.Section divider={false}>
      <FormItem name="url" label="URL" rules={[{ required: true, type: 'url' }]} fullWidth>
        <Input
          placeholder="Enter a URL here..."
          // suffix={isValidUrl ? <CheckCircleFilled style={{ color: '#52c41a' }}/> : null}
        />
      </FormItem>
    </FormLayout.Section>
  );
}

export default ImportForm;
