import { EMPTY_ARRAY } from 'constants';
import { useEffect } from 'react';
import { EventEmitter } from 'utils/event';

function useEventEffect(callback, eventName, deps = EMPTY_ARRAY) {
  useEffect(() => {
    EventEmitter.on(eventName, callback);

    return () => EventEmitter.clear(eventName, callback);
  }, deps);
}

export default useEventEffect;
