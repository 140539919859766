import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import React from 'react';
import {
  Routes, useParams, useLocation, useNavigate,
} from 'react-router';
import { Route } from 'react-router-dom';
import { Tabs } from 'antd';
import BaseLayout from 'components/Layout';
import MenuSelectorDrawer from './MenuSelectorDrawer';
import Items from '../Items';
import MenuProvider from '../MenuProvider';
import Menus from './Menus';
import Modifiers from '../Modifiers';

// Update tab definitions to use path-based keys
const tabItems = [
  { key: '/', label: 'Menus' },
  { key: '/items', label: 'Items' },
  { key: '/modifiers', label: 'Modifiers' },
];

function MerchantMenus() {
  const { id: restaurantId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the active tab from the URL path
  const pathSegments = location.pathname.split('/');
  const lastPath = `/${pathSegments[pathSegments.length - 1]}`;
  const activeTab = tabItems.find((tab) => tab.key === lastPath) || tabItems[0];

  const handleTabChange = (key) => {
    navigate(`/console/restaurants/${restaurantId}/menus${key}`, { replace: true });
  };

  return (
    <BaseLayout header={(
      <BaseLayout.Header
        title="Menu management"
        backTitle="Merchant page"
        backPath={`/console/restaurants/${restaurantId}`}
      />
    )}
    >
      <MenuProvider
        isGlobal={false}
        restaurantId={restaurantId}
      >
        <Tabs
          activeKey={activeTab.key}
          items={tabItems}
          onChange={handleTabChange}
        />
        <Routes>
          <Route path="items" element={<Items />} />
          <Route path="modifiers" element={<Modifiers />} />
          <Route path="select" element={<MenuSelectorDrawer />} />
          <Route path="/*" element={<Menus />} />
        </Routes>
      </MenuProvider>
    </BaseLayout>
  );
}

export default withGuard(MerchantMenus, [PERMISSIONS.MERCHANTS], { redirect: true });
