import { Tag } from 'antd';
import dayjs from 'dayjs';
import { priceTransformer } from 'utils';

const columns = [
  {
    title: 'amount',
    key: 'amount',
    width: '15%',
    render: (value) => <b>{priceTransformer(value / 100)}</b>,
  },
  {
    title: 'status',
    key: 'state',
    width: '10%',
    render: (value) => (
      <Tag color={value === 'completed' ? 'green' : 'red'} className="font-bold">
        {value === 'completed' ? 'Paid' : 'Pending'}
      </Tag>
    ),
  },
  {
    title: 'Stripe Account',
    key: 'destination_account_name',
    width: '20%',
    render: (value) => value || '-',
  },
  {
    title: 'Transfer ID',
    key: 'transfer_id',
    width: '15%',
    render: (value) => value || '-',
  },
  {
    title: 'Initiated Date',
    key: 'paid_at',
    width: '20%',
    render: (value) => value ? dayjs(value).format('MMM D, hh:mm A, YYYY') : '-',
  },
  {
    title: 'Est. Arrival',
    key: 'arrival_at',
    width: '20%',
    render: (value) => value ? dayjs(value).format('MMM D, hh:mm A, YYYY') : '-',
  },
];
export default columns;
