import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  style,
  onChange,
  optionRender,
  size,
  value,
  labelInValue = true,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchRef = useRef(0);
  useEffect(() => {
    if (fetchRef.current === 0) debounceFetcher();
  }, []);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue={labelInValue}
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" className="my-3 ml-3" /> : null}
      loading={fetching}
      options={options}
      style={style}
      size={size}
      value={value}
      onChange={onChange}
      optionRender={optionRender}
      {...props}
    />
  );
}
export default DebounceSelect;
