import React from 'react';
import Loading from 'components/Loading';
import useConfig from 'hooks/useConfig';
import MerchantMenus from 'containers/GlobalMenuManagement/MerchantMenus';
import MenuManagement from './index';

function MenuManagementContainer() {
  const config = useConfig('general');

  if (!config) {
    return <Loading />;
  }

  if (config.globalMenusEnabled) {
    return <MerchantMenus />;
  }

  return <MenuManagement />;
}

export default MenuManagementContainer;
