import styled from 'styled-components';
import { Colors } from 'assets/colors';
import { WL_CONFIG } from 'constants';

export const StyledWrapper = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title-row {
    padding: 21px 51px;
    margin-bottom: 4px;
    display: block;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.textPrimary1} !important;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .back {
      color: #717679;
      width: 24px;
      height: 24px;
      margin-right: 3px;

      &-btn {
        padding: 0;
      }

      &-text {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #717679;
      }
    }
  }

  .status {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: capitalize;

    &--active {
      background: #e5dffc;
      color: #4b3a95 !important;
    }
    &--inactive {
      background: #e7e8e9;
      color: ${Colors.textPrimary1} !important;
    }
    &--new {
      background: #f9e6d2;
      color: #9c400b !important;
    }
    &--lost {
      background: #fae5e4;
      color: #aa2720 !important;
    }
  }

  .customer {
    &-row {
      margin: 12px 56px 20px;
    }

    &-name {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1};
      margin-right: 12px;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 56px;
    border: 1px solid #d8dce2;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
  }

  .tab {
    width: 264px;
    height: 74px;
    padding: 14px 18px;
    border-right: 1px solid #d8dce2;

    &:last-child {
      border: none;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        justify-content: flex-start;
        margin-bottom: 4px;
      }
    }

    &-desc {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: ${Colors.textPrimary1};
    }

    &-right {
      font-weight: 600;
      font-size: 13px;
      line-height: 23px;
      color: #7d8284;
    }
  }

  .details {
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
      margin: 80px 56px 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e8eaee;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 0 56px 80px;
    }

    &-cell {
      display: flex;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #717679;
      margin-bottom: 12px;

      &-left {
        width: 112px;
      }

      &-right {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.textPrimary1};
      }

      &-colored {
        color: ${WL_CONFIG.colors.primary};
      }
    }
  }

  .strong {
    font-weight: 600;
  }

  .table-box {
    margin: 0 56px;

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
      margin: 78px 56px 20px;
      padding-bottom: 0;
      border-bottom: 1px solid #e8eaee;
    }
  }

  .pagination {
    margin-top: -48px;
  }
`;
