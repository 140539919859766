import http from 'utils/http';

export const reorderItemPosition = ({ itemId, position, categoryId }) => http.post(
  `v1/items/${itemId}/move`,
  { item: itemId, position, category: categoryId },
).then((res) => res.data);

export const reorderGlobalItemsPosition = ({ itemIds, menuId, categoryId }) => http.patch(
  `v1/organizations/{{organizationId}}/global-menus/${menuId}/categories/${categoryId}/items/`,
  itemIds,
).then((res) => res.data);

export const deleteCategoryItem = ({ menuId, categoryId, itemIds }) => http.put(`/v1/organizations/{{organizationId}}/global-menus/${menuId}/categories/${categoryId}/items/`, itemIds).then((res) => res.data);

export const changeItemAvailability = (itemId) => http.post(`v1/items/${itemId}/status/`).then((res) => res.data);
