import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import {
  Alert, DatePicker, Flex, Form, Modal, Select, Space, TimePicker, Typography,
} from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import http from 'utils/http';
import { formatTime } from 'utils/date';
import { captureException } from 'utils/errors';
import useFetchedData from 'hooks/useFetchedData';
import { EMPTY_OBJECT } from 'constants';
import EditConfirmation from '../EditConfirmation';
import { StepsContext } from '../Steps/StepsProvider';

function SchedulingInput({
  value, onChange, merchantId, isCatering,
}) {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const form = useFormInstance();
  const items = form.getFieldValue('items');
  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const scheduledFor = Form.useWatch('scheduled_for');
  const {
    loading: stepLoading, lock, unlock,
  } = useContext(StepsContext);
  const formMerchant = form.getFieldValue('merchant');

  const [outOfRange, setOutOfRange] = useState(false);
  const [firstAvailableTime, setFirstAvailableTime] = useState(null);
  const [openingHours, setOpeningHours] = useState(null);
  const [openingHoursOpen, setOpeningHoursOpen] = useState(false);

  const scheduleTime = Form.useWatch('scheduled_for');
  const { data: fetchedMerchant } = useFetchedData(formMerchant && `v1/restaurants/${formMerchant?.id}`, (res) => res?.data || EMPTY_OBJECT);

  const merchant = {
    ...formMerchant,
    ...fetchedMerchant,
  };

  useEffect(() => {
    if (!merchant?.id) {
      return;
    }
    setOutOfRange(false);
    lock();
    http.get(`v1/restaurants/${merchant.id}/availability/?scheduled_datetime=${new Date(scheduleTime || Date.now()).toISOString()}`).then(({ data: { data } }) => {
      if (data.failed_check) {
        setOutOfRange(true);
      }
      setFirstAvailableTime(data.first_available_time);
      setOpeningHours(data.schedule);
    }).catch((error) => {
      captureException(error);
    }).finally(unlock);
  }, [scheduleTime, merchant?.id]);

  useEffect(() => {
    if (!scheduledFor) {
      setSelectedTime();
      setSelectedDate();
    }
  }, [scheduledFor]);

  const timeOptions = useMemo(() => {
    if (!merchantId || isCatering) return [];
    if (selectedDate === null || selectedDate === undefined) {
      return [];
    }
    const { times } = dates[selectedDate];
    return times
      .reduce((all, d) => [...all, ...d.slots], [])
      .map((s, index) => ({
        value: index,
        label: `${formatTime({ fromHour: s.start })} - ${formatTime({ fromHour: s.end })}`,
        time: s,
      }));
  }, [selectedDate]);

  useEffect(() => {
    if (loading) return;
    if (selectedDate !== undefined && selectedDate !== null && selectedTime !== undefined && selectedTime !== null) {
      let formattedScheduledData;
      if (isCatering) {
        formattedScheduledData = `${dayjs(selectedDate).format('YYYY-MM-DD')}T${dayjs(selectedTime).format('HH:mm:ss')}`;
      } else {
        formattedScheduledData = dates[selectedDate]?.date ? `${dates[selectedDate]?.date}T${timeOptions[selectedTime]?.time?.start}` : null;
      }
      onChange(formattedScheduledData ? dayjs(formattedScheduledData).toISOString() : null);
    } else {
      onChange(null);
    }
  }, [selectedTime, loading]);

  useEffect(() => {
    if (merchantId && !isCatering) {
      setLoading(true);
      http
        .post(`v1/restaurants/${merchantId}/schedule-times/`, {
          items: [],
        })
        .then(({ data: { data } }) => {
          setDates(
            Object.entries(data)
              .filter(([_, times]) => times.length > 0)
              .map(([date, times], index) => ({
                index,
                label: dayjs(date)?.format('ddd, DD MMM'),
                value: index,
                date,
                times,
              })),
          );
          setLoading(false);
        });
    }
  }, [merchantId, isCatering]);

  const handleSelectDate = (value) => {
    if (items?.length) {
      setTempDate(value);
      setOpen(true);
    } else {
      setSelectedDate(value);
      setSelectedTime(null);
    }
  };
  const handleSelectTime = (value) => {
    if (items?.length) {
      setTempTime(value);
      setOpen(true);
    } else {
      setSelectedTime(value);
    }
  };

  const onConfirmEdit = () => {
    setSelectedDate(tempDate || selectedDate);
    setSelectedTime(tempTime);
    form.setFieldValue('items', []);
    setTempDate();
    setTempTime();
  };
  return (
    <>
      {
        firstAvailableTime && (
          <Typography.Paragraph>
            First available scheduling time:
            <Typography.Text strong>{dayjs(firstAvailableTime).format('ddd, MMM D, h:mm A')}</Typography.Text>
          </Typography.Paragraph>
        )
      }
      {
        openingHours && (
          <Typography.Paragraph>
            <Typography.Text>{merchant.name}</Typography.Text>
            {' '}
            <Typography.Text>
              operates in the
              {' '}
              { merchant.parent.location_timezone }
              {' '}
              time zone. Please select a time based on the restaurant&apos;s local time.
            </Typography.Text>

            <Typography.Link onClick={() => setOpeningHoursOpen(true)}>View restaurant&apos;s hours</Typography.Link>
          </Typography.Paragraph>
        )
      }

      <Space size="large" direction={isMobile ? 'vertical' : 'horizontal'} className="w-full mt-2">
        {isCatering ? (
          <Space direction={isMobile ? 'vertical' : 'horizontal'} gap={16}>
            <Flex gap={8} align="center">
              <Typography.Text style={{ whiteSpace: 'nowrap' }}>Date:</Typography.Text>
              <DatePicker
                name="scheduled_for"
                value={selectedDate}
                onChange={handleSelectDate}
                disabledDate={(current) => current && current < dayjs().startOf('day')}
                disabled={stepLoading}
              />
            </Flex>
            <Flex gap={8} align="center">
              <Typography.Text style={{ whiteSpace: 'nowrap' }}>Time:</Typography.Text>
              <TimePicker
                name="scheduled_for"
                value={selectedTime}
                onChange={handleSelectTime}
                showSecond={false}
                minuteStep={15}
                use12Hours
                format="hh:mm A"
                disabled={stepLoading}
              />
            </Flex>
            {
              outOfRange && scheduleTime && (
                <Alert type="error" message="Warning: The restaurant is closed or lead time isn't met. You can still place your order, but inform the restaurant." />
              )
            }
          </Space>
        ) : (
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Flex gap={8} align="center">
              <Typography.Text>Date:</Typography.Text>
              <Select
                disabled={loading || stepLoading}
                style={{ width: 150 }}
                className="row-right-margin"
                name="scheduled_for_date"
                suffixIcon={<CalendarOutlined />}
                loading={loading}
                value={selectedDate}
                onChange={handleSelectDate}
                options={dates}
                allowClear
              />
            </Flex>
            <Flex gap={8} align="center">
              <Typography.Text>Time:</Typography.Text>
              <Select
                disabled={loading || stepLoading}
                style={{ width: 200 }}
                name="scheduled_for"
                className="row-right-margin"
                suffixIcon={<ClockCircleOutlined />}
                loading={loading}
                value={selectedTime}
                onChange={handleSelectTime}
                options={timeOptions}
                allowClear
              />
            </Flex>

          </Space>
        )}
        <EditConfirmation open={open} setOpen={setOpen} onConfirmEdit={onConfirmEdit} />
      </Space>
      <RestaurantTime
        openingHours={openingHours}
        merchant={merchant}
        open={openingHoursOpen}
        firstAvailableTime={firstAvailableTime}
        onClose={() => setOpeningHoursOpen(false)}
      />
    </>
  );
}

function RestaurantTime({
  openingHours, merchant, open, onClose, firstAvailableTime,
}) {
  return (
    <Modal
      title={`${merchant.name}'s hours`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      {
        firstAvailableTime && (
          <Typography.Paragraph strong style={{ fontSize: 20 }}>
            First available scheduling time:
            {dayjs(firstAvailableTime).format('ddd, MMM D, h:mm A')}
          </Typography.Paragraph>
        )
      }
      {
        openingHours && Object.entries(openingHours).map(([day, hours]) => (
          <Flex key={day} justify="space-between" className="mt-2">
            <Typography.Text strong style={{ textTransform: 'capitalize' }}>{day.toLowerCase()}</Typography.Text>
            <Flex style={{ flexDirection: 'row', flexWrap: 'wrap', textAlign: 'right' }} split={<Typography.Text style={{ fontSize: 12, margin: '0 4px' }}> , </Typography.Text>}>
              <Typography.Text style={{ textTransform: 'lowercase', fontFamily: 'Manrope' }}>
                {
                  hours.map((h) => `${h.from_hour}-${h.to_hour}`).join(', ')
                }
              </Typography.Text>
            </Flex>
          </Flex>

        ))
      }
    </Modal>
  );
}

export default SchedulingInput;
