import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Select } from 'antd';
import React from 'react';
import './index.css';

function MenuSelector({
  onChange, onCreate, menus, selectedMenuId, ...props
}) {
  return (
    <Select
      className="w-full"
      placeholder="Select menu"
      onSelect={(value) => {
        onChange?.(value);
      }}
      value={+selectedMenuId}
      dropdownClassName="menu-selector"
      options={menus.map((item) => ({ label: item.name, value: item.id }))}
      fieldNames={{ label: 'label', value: 'value' }}
      labelInValue={false}
      dropdownStyle={{ padding: 0 }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Button
            type="link"
            color="primary"
            block
            className="mt-1 text-left"
            icon={<DownloadOutlined />}
            onClick={() => onCreate('import')}
          >
            Import from another platform
          </Button>

          <Divider className="my-2" />

          <Button
            type="link"
            color="primary"
            block
            className="mb-1 text-left"
            icon={<PlusOutlined />}
            onClick={() => onCreate('scratch')}
          >
            Create from scratch
          </Button>
        </>
      )}
      {...props}
    />
  );
}

export default MenuSelector;
