import { EMPTY_OBJECT } from 'constants';
import useFetchedData from 'hooks/useFetchedData';
import React, { useState, useContext } from 'react';
import http from 'utils/http';
import { MenuContext } from '../constants';
import DeleteDisableModal from './DeleteDisableModal';
import ItemDrawer from './ItemDrawer';

function EditItemDrawer({ itemId, onClose }) {
  const { isGlobal } = useContext(MenuContext);
  const [openDelete, setOpenDelete] = useState(false);

  const { data, loading } = useFetchedData(
    itemId && (isGlobal ? `v1/organizations/{{organizationId}}/global-items/${itemId}/` : `/v1/items/${itemId}/`),
    (res) => res?.data || EMPTY_OBJECT,
  );

  async function handleEdit(data) {
    await http.put(isGlobal ? `/v1/organizations/{{organizationId}}/global-items/${itemId}/` : `/v1/items/${itemId}/`, data);
    onClose?.();
  }

  async function handleDelete() {
    await http.delete(`/v1/organizations/{{organizationId}}/global-items/${itemId}/`);
    onClose?.();
  }

  return (
    <>
      <ItemDrawer
        title="Edit item"
        data={data}
        open={!!itemId}
        loading={loading}
        onSubmit={handleEdit}
        onClose={onClose}
        onDelete={() => setOpenDelete(true)}
        itemId={itemId}
      />
      <DeleteDisableModal item={data} onConfirm={handleDelete} open={openDelete} setOpen={setOpenDelete} />
    </>
  );
}

export default EditItemDrawer;
