import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  App, Button, Flex, Typography,
} from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import { truncate } from 'lodash-es';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import http from 'utils/http';

const { Text } = Typography;

function NoteEvent({
  event: {
    data: note, occurred_at: date, author, is_deletable, is_editable, uid,
  },
  onEdit,
  refresh,
}) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [open, setOpen] = useState(false);
  const text = note.note_html ? note.note_html.replace(/<[^>]+>/g, '') : note.note;
  const { message, modal } = App.useApp();
  const [loading, setLoading] = useState(false);
  const { id: companyId } = useParams();

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(true);
  };

  const handleDelete = () => {
    modal.confirm({
      title: 'Delete note',
      content: 'Are you sure you want to delete this note? This action cannot be undone.',
      okText: 'Delete',
      okButtonProps: { danger: true },
      onOk: () => {
        setLoading(true);
        return http.delete(`v1/organizations/{{organizationId}}/companies/${companyId}/activities/${uid}`)
          .then(() => {
            message.success('Note successfully deleted!');
            refresh();
          })
          .finally(() => setLoading(false));
      },
    });
  };

  return (
    <Flex
      vertical
      gap={4}
      className={`ml-3 bg-white rounded py-2 px-3 w-full ${loading ? 'opacity-50' : ''}`}
      onClick={() => text.length >= 70 && setOpen(!open)}
    >
      <Flex justify="space-between" vertical={isMobile} className="w-full">
        <Flex gap={12} align="center">
          <Text className="font-bold text-[16px]">
            Note
          </Text>
          <Text>
            {author ? `created by ${author.email}` : 'from CRM'}
          </Text>
        </Flex>
        <Flex gap={12} align="center">
          {!!author && (
            <div>
              <Button
                icon={<EditOutlined className="text-[#00000073]" />}
                type="text"
                size="small"
                hidden={!is_editable}
                disabled={loading}
                onClick={handleEdit}
              />
              <Button
                icon={<DeleteOutlined className="text-[#00000073]" />}
                type="text"
                size="small"
                hidden={!is_deletable}
                loading={loading}
                onClick={handleDelete}
              />
            </div>
          )}
          <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
        </Flex>
      </Flex>

      <Flex
        vertical={isMobile}
        justify="space-between"
        align="flex-start"
        gap={4}
      >
        <Text style={{ height: open ? 'auto' : '20px', overflow: 'hidden' }}>
          {open ? text : truncate(text, { length: 70, omission: '...' })}
        </Text>
        {
          !open && text.length >= 70 && (
            <Button type="link" className="pr-0">
              Show more
            </Button>
          )
        }
      </Flex>
    </Flex>
  );
}

export default NoteEvent;
