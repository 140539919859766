import { Form, InputNumber } from 'antd';
import ItemsInput from 'components/ItemsInput';
import React, { useContext } from 'react';
import { StepsContext } from '../Steps/StepsProvider';
import { STEPS } from '../constants';
import OrderFormItem from '../../components/OrderFormItem';
import Steps from '../Steps';
import { useIsCatering } from '../../utils/useIsCatering';
import Summary from './Summary';

function ItemsInfo({ validating = false, total = 0 }) {
  const isCatering = useIsCatering();
  const { isActiveStep } = useContext(StepsContext);
  const isActive = isActiveStep(STEPS.ITEMS.id);
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');
  const items = form.getFieldValue('order_items');
  const getTotalCount = () => {
    if (!isActive && items?.length > 0) {
      let qty = 0;
      items.map((item) => qty += item.qty);
      return `(${qty})`;
    }
    return '';
  };

  return (
    <Steps.Step
      step={{ ...STEPS.ITEMS, title: `${STEPS.ITEMS.title} ${getTotalCount()}` }}
      summary={<Summary total={total} />}
    >
      <OrderFormItem
        name="headcount"
        label="Headcount"
        rules={[{ required: isCatering, message: 'Please select the merchant' }]}
        hidden={!isCatering}
      >
        <InputNumber addonAfter="People" min={1} />
      </OrderFormItem>
      <OrderFormItem name="order_items" fullWidth rules={[{ required: true }]}>
        <ItemsInput
          merchant={merchant}
          isCatering={isCatering}
          validating={validating}
        />
      </OrderFormItem>
    </Steps.Step>
  );
}

export default ItemsInfo;
