import { EMPTY_ARRAY } from 'constants';
import useEventEffect from 'hooks/useEvent';
import useFetchedData from 'hooks/useFetchedData';
import React, {
  useMemo,
} from 'react';
import { MenuContext } from './constants';

function MenuProvider({ children, restaurantId, isGlobal }) {
  const { data: modifiers = [], loading: modifiersLoading, mutate: refreshModifiers } = useFetchedData(
    isGlobal ? 'v1/organizations/{{organizationId}}/global-modifiers/?all=true' : `v1/restaurants/${restaurantId}/modifiers/`,
    isGlobal ? ({ data } = {}) => data?.sort((a, b) => b.id - a.id) || EMPTY_ARRAY :
      (data) => data?.sort((a, b) => b.id - a.id) || EMPTY_ARRAY,
  );

  useEventEffect(() => {
    refreshModifiers();
  }, 'refresh_modifiers');

  const contextValue = useMemo(() => ({
    isGlobal,
    modifiers,
    modifiersLoading,
  }), [
    isGlobal,
    modifiers,
    modifiersLoading,
  ]);

  return (
    <MenuContext.Provider value={contextValue}>
      {children}
    </MenuContext.Provider>
  );
}

export default MenuProvider;
