import { WL_CONFIG } from 'constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  min-width: 200px;
  margin-right: 32px;
  @media (max-width:575.98px) {
    margin-right: 0;
  }
  
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }


  .details {
    display: flex;
    flex-direction: column;

    &-cell {
      margin-bottom: 16px;

      &-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #717679;
        margin-bottom: 4px;

      }

      &-data {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #131B1F;

        &--green {
          color: ${WL_CONFIG.colors.primary};
          font-weight: 600;

        }
      }
    }
  }
`;
