import { Flex, Input, Typography } from 'antd';
import ActiveHoursInput from 'components/ActiveHoursInput';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import React, { useContext } from 'react';

function MenuForm({ onReset }) {
  const { isGlobal } = useContext(MenuContext);
  return (
    <>
      <FormLayout.Section divider={false}>
        <FormItem
          name="name"
          label="Name"
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
          fullWidth
        >
          <Input placeholder="Menu name" disabled={!isGlobal} />
        </FormItem>
        {!isGlobal && (
          <Typography.Text className="text-sm text-gray-500">
            Please contact your catering manager to edit the menu name.
          </Typography.Text>
        )}
      </FormLayout.Section>
      <FormLayout.Section
        title={(
          <Flex justify="space-between">
            <Typography.Text>Active Hours</Typography.Text>
            {
              !isGlobal && (
                <Typography.Link style={{ fontSize: 14, fontWeight: 400 }} onClick={onReset}>
                  Reset to global menu time
                </Typography.Link>
              )
            }
          </Flex>
        )}
      >
        <FormItem name="active_times" fullWidth>
          <ActiveHoursInput />
        </FormItem>
      </FormLayout.Section>
    </>
  );
}

export default MenuForm;
