import styled from 'styled-components';
import { Colors } from 'assets/colors';
import { WL_CONFIG } from 'constants';

export const StyledWrapper = styled.div`

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title-row {
    padding: 21px 51px;
    margin-bottom: 4px;
    display: block;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.textPrimary1} !important;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .back {
      color: #717679;
      width: 24px;
      height: 24px;
      margin-right: 3px;

      &-btn {
        padding: 0;
      }

      &-text {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #717679;
      }
    }
  }

  .customer {
    &-row {
      margin: 12px 56px 20px;
    }

    &-name {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1};
      margin-right: 12px;

      &-text {
        margin-right: 24px;
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 56px;
    border: 1px solid #d8dce2;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
  }

  .tab {
    width: 264px;
    height: 74px;
    padding: 14px 18px;
    border-right: 1px solid #d8dce2;

    &:last-child {
      border: none;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        justify-content: flex-start;
        margin-bottom: 4px;
      }
    }

    &-desc {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: ${Colors.textPrimary1};
    }

    &-right {
      font-weight: 600;
      font-size: 13px;
      line-height: 23px;
      color: #7d8284;
    }
  }

  .details {
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
      margin: 80px 56px 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e8eaee;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 0 56px 80px;
    }

    &-cell {
      display: flex;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #717679;
      margin-bottom: 12px;

      &-left {
        width: 112px;
      }

      &-right {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.textPrimary1};
      }

      &-colored {
        color: ${WL_CONFIG.colors.primary};
      }
    }
  }

  .strong {
    font-weight: 600;
  }

  .table-box {

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
      margin: 78px 56px 20px;
      padding-bottom: 0;
      border-bottom: 1px solid #e8eaee;
    }
  }

  .pagination {
    margin-top: -48px;
  }

  .status {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    width: fit-content;
    padding: 4px 8px 4px 4px;
    border-radius: 4px;
    text-transform: capitalize;

    &--completed {
      background: #cbf4c9;
      color: #0e623f !important;
    }
    &--scheduled {
      background: #f9e6d2;
      color: #9c400b !important;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-top: -2px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--lead {
        margin-right: 4px;
      }
    }
  }
`;

export const StyledChartWrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dde1e4;
  margin-bottom: 270px;

  .box {
    background: #f5f7f8;
    padding: 16px 24px;
    border-bottom: 1px solid #dde1e4;

    &--top {
      border-radius: 5px 5px 0 0;
    }

    &--bottom {
      border-bottom: none;
      background: #ffffff;
      padding: 12px 20px;
      border-radius: 0 0 5px 5px;
      display: flex;
      justify-content: space-between;
    }
  }

  .btn {
    font-weight: bold;
    font-size: 14px;
    padding: 5px 8px;
    margin-right: 8px;

    &-delete {
      color: #c7322d;

      &:hover {
        color: #c7322d;
      }
    }
  }

  .btns-right {
    display: flex;
    margin-left: auto;
  }

  .info {
    width: 359px !important;
    height: 32px !important;
    padding: 7px 12px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;

    &-note {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #42494c;
      margin: 4px 16px 0;
      display: flex;
      margin-bottom: 16px;

      svg {
        margin-right: 8px;
      }
    }
  }

  .coming-soon {
    background: #faf3e6;
    color: #cc8601;
    border-radius: 22px;
    padding: 4px 8px;
    width: fit-content;
    height: 26px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    svg {
      margin-right: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .not-yet {
    opacity: 0.1;
  }
`;
