import { Col, Input, Row, Space, Typography } from 'antd';
import DietaryTagSelector from 'components/DietaryTags/DietaryTagSelector';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React from 'react';
import UploadImage from './UploadImage';

function AdditionalInfo({ disabled }) {
  return (
    <FormLayout.Section>
      <Row gutter={16}>
        <Col span={18}>
          <FormItem name="description" label="Description" fullWidth>
            <Input.TextArea rows={3} placeholder="..." disabled={disabled}/>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem name="images">
            <UploadImage disabled={disabled}/>
          </FormItem>
        </Col>
      </Row>

      <Space direction="vertical" className="mb-1">
        <Typography.Text className="font-bold">
          Tag(s)
          <Typography.Text type="secondary"> (optional)</Typography.Text>
        </Typography.Text>
        <Typography.Text className="text-[#A7A9A9]">
          Select relevant tags for this item to be displayed to customers, such as Vegetarian or Gluten-Free.
        </Typography.Text>
      </Space>
      <FormItem name="dietary_tags" fullWidth>
        <DietaryTagSelector maxTagCount={4} disabled={disabled}/>
      </FormItem>
    </FormLayout.Section>
  );
}

export default AdditionalInfo;
