import { Flex, Spin } from 'antd';
import useFetchedData from 'hooks/useFetchedData';
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useEventEffect from 'hooks/useEvent';
import { EventEmitter } from 'utils/event';
import { MenuContext } from '../constants';
import List from './List';
import Toolbar from './Toolbar';
import CreateItemDrawer from './Create';

function Items({
  isItemPool, selectedRowKeys, onSelect,
}) {
  const { isGlobal } = useContext(MenuContext);
  const { id: merchantId } = useParams();
  const [createItemOpen, setCreateItemOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: items = [], loading, mutate: refreshItems } = useFetchedData(
    {
      url: isGlobal ? 'v1/organizations/{{organizationId}}/global-items/' : `v1/restaurants/${merchantId}/items/`,
      query: { all: true, search: searchTerm },
    },
    ({ data: items } = {}) => items?.map((item) => ({ ...item, key: item.id })),
  );

  useEventEffect(() => {
    refreshItems();
  }, 'refresh_items');

  if (loading && !searchTerm) {
    return (
      <Flex align="center" justify="center" className="h-[50vh]">
        <Spin size="large" />
      </Flex>
    );
  }
  return (
    <Flex vertical gap={12}>
      {(Boolean(items.length) || searchTerm) && (
        <Toolbar
          isItemPool={isItemPool}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          hasItem={Boolean(items.length)}
          onCreate={() => setCreateItemOpen(true)}
          loading={loading}
        />
      )}
      <CreateItemDrawer
        open={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false);
          EventEmitter.emit('refresh_items');
        }}
      />

      <List
        items={items}
        loading={loading}
        searchTerm={searchTerm}
        isItemPool={isItemPool}
        onCreate={() => setCreateItemOpen(true)}
        onSelect={onSelect}
        selectedRowKeys={selectedRowKeys}
      />
    </Flex>
  );
}

export default Items;
