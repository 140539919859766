import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Spin } from 'antd';
import EmptyComponent from 'components/Empty';
import useFetchedData from 'hooks/useFetchedData';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useEventEffect from 'hooks/useEvent';
import http from 'utils/http';
import CategoriesTable from '../Menus/CategoriesTable';
import MenuToolbar from './MenuToolbar';
import MenuFormDrawer from '../Menus/MenuFormDrawer';

function Menus() {
  const { id: restaurantId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const { data: menus = [], loading, mutate: refreshMenus } = useFetchedData(
    {
      url: `v1/restaurants/${restaurantId}/menus/`,
      query: { query: '{id,name,is_active,active_hours}' },
    },
    ({ results } = {}) => results,
  );

  const { data: menu, loading: menuLoading, mutate: refreshMenu } = useFetchedData(
    menus[0] && {
      url: `v1/menus/${menus[0].id}/`,
      query: { query: '{id,active_hours,description,name,images,is_active,categories{id,name,featured,description,position,items},are_item_images_visible,placeholder_image}' },
    },
    (result = {}) => result,
  );

  useEventEffect(() => {
    refreshMenu();
  }, 'refresh_items');

  useEventEffect(() => {
    refreshMenu();
  }, 'refresh_categories');

  useEventEffect(() => {
    refreshMenus();
  }, 'refresh_menus');

  async function handleEdit(data) {
    data.active_hours_ = data.active_times;
    delete data.active_times;
    await http.patch(`/v1/menus/${menus[0].id}/`, data);
    refreshMenus();
  }

  async function handleMenuReset() {
    const response = await http.patch(`/v1/menus/${menus[0].id}/`, { reset_active_hours: true });
    refreshMenu();
    return response.data;
  }

  const navigate = useNavigate();
  const { id: merchantId } = useParams();
  const hasMenu = Boolean(menus?.length);
  return (
    <>
      {loading && (
        <Flex justify="center" align="center" className="h-[50vh]">
          <Spin size="large" />
        </Flex>
      )}
      {!loading && !hasMenu && (
        <EmptyComponent
          title="Start by adding your first global menu to build your restaurant's menu effortlessly!"
          primaryAction={(
            <Button
              icon={<PlusOutlined />}
              onClick={() => navigate(`/console/restaurants/${merchantId}/menus/select`)}
              type="primary"
            >
              Select menu
            </Button>
          )}
        />
      )}

      {!loading && hasMenu && (
        <MenuToolbar
          selectedMenuId={menus[0]?.id}
          menus={menus}
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          loading={loading}
          onEditMenu={() => setEditMenuOpen(true)}
        />
      )}

      <MenuFormDrawer
        title="Edit menu"
        open={editMenuOpen}
        menuId={menus[0]?.id}
        menu={menu}
        onSubmit={(data) => handleEdit(data)}
        onReset={handleMenuReset}
        onClose={() => setEditMenuOpen(false)}
        loading={menuLoading}
      />

      <Outlet />

      {!loading && hasMenu && (
        <CategoriesTable
          menu={menu}
          searchTerm={searchTerm}
          loading={menuLoading || loading}
        />
      )}
    </>
  );
}

export default Menus;
