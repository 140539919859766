import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Space, Switch, Tag, Tooltip } from 'antd';
import DietaryTags from 'components/DietaryTags';
import React from 'react';
import { priceTransformer } from 'utils';

const columns = ({
  isGlobal, onEdit, onRemove, getNestedOptions, onChangeModifierAvailability,
}) => ([
  {
    key: 'sort',
    width: 30,
    align: 'right',
  },
  {
    title: 'Title',
    key: 'name',
    dataIndex: 'name',
    editable: true,
  },
  {
    title: 'Tags',
    key: 'dietary_tags',
    dataIndex: 'dietary_tags',
    editable: true,
    width: 150,
    render: (value) => {
      return <DietaryTags tags={value}/>;
    },
  },
  {
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
    width: 80,
    align: 'right',
    editable: true,
    render: (value, record) => {
      if (!record.modifiers_children?.length) {
        return priceTransformer(value);
      }

      // for handle before selected or current selected nested modifier
      const options = (record.localId ? record.modifiers_children : getNestedOptions(record.modifiers_children))
        ?.map((modifier) => (
          <Tag key={modifier.id} color={modifier.modifiers_children?.length ? 'green' : ''}>
            {modifier.title}
            {modifier.modifiers_children?.length > 0 && ' (nested)'}
          </Tag>
        ));
      return (
        <Space style={{ color: '#ADB2B8' }}>
          Nested
          <Tooltip
            title={<Space size={[0, 4]} wrap>{options}</Space>}
            color="white"
            placement="topRight"
            overlayStyle={{ maxWidth: '400px' }}
          >
            <InfoCircleOutlined/>
          </Tooltip>
        </Space>
      );
    },
  },
  // !isGlobal && {
  //   title: '',
  //   key: 'is_active',
  //   dataIndex: 'is_active',
  //   width: 40,
  //   align: 'center',
  //   render: (value, record) => (
  //     <Switch checked={value} onChange={() => onChangeModifierAvailability?.(record.id)}/>
  //   ),
  // },
  isGlobal && {
    title: '',
    key: 'operation',
    width: 70,
    align: 'center',
    render: (_, record) => (
      <Space size={0}>
        <Button
          type="text"
          icon={<EditOutlined/>}
          onClick={() => {
            onEdit(record);
          }}
        />
        <Button
          type="text"
          danger
          icon={<DeleteOutlined/>}
          onClick={(e) => onRemove(e, record.id || record.localId)}
        />
      </Space>
    ),
  },
].filter(c => c));

export default columns;
