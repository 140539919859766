import {
  DeleteOutlined, EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Space, Switch,
} from 'antd';
import { ModifierContext } from 'containers/MenuManagement/context';
import React, { useContext, useMemo, useState } from 'react';
import { priceTransformer } from 'utils';
import { captureException } from 'utils/errors';
import { getImage } from 'utils/image';
import { generateColumns } from 'utils/table';
import DietaryTags from 'components/DietaryTags';
import DraggableTable from 'components/DraggableTable';
import { ModalMode } from '../../../constants';
import { MenuContext } from '../../context';
import ItemDetailModal from './ItemDetailModal';
import { changeItemAvailability, deleteItem, reorderItemPosition } from './requests';

function ItemsTable({ category, categories, setCategories }) {
  const { setItemsLoading, fetchMenuDetail, showMessage } = useContext(MenuContext);
  const [mode, setMode] = useState(ModalMode.CLOSED);
  const [editingItem, setEditingItem] = useState();
  const { merchant } = useContext(ModifierContext);

  const onRemoveItem = (e, id) => {
    e.preventDefault();
    setItemsLoading(true);
    deleteItem(id).then((res) => {
      fetchMenuDetail();
    })
      .catch((e) => {
        captureException(e);
        showMessage({ type: 'error' });
        setItemsLoading(false);
      });
  };

  const onChangeItemAvailability = (id) => {
    setItemsLoading(true);
    changeItemAvailability(id).then((res) => {
      fetchMenuDetail();
    })
      .catch((e) => {
        captureException(e);
        showMessage({ type: 'error' });
        setItemsLoading(false);
      });
  };

  const columns = [
    {
      key: 'sort',
      width: 40,
      align: 'right',
    },
    {
      title: 'Item Name',
      key: 'name',
      render: (_, data) => (
        <Space>
          {getImage({ data }) && (
            <img
              className="image"
              src={getImage({ data, size: 'md' })}
              alt={data?.name || ''}
              width={25}
              height={25}
              style={{ border: '1px solid #DDE1E4', borderRadius: 5, marginRight: 4 }}
            />
          )}
          {data.name}
        </Space>
      ),
    },
    merchant.is_catering && {
      title: 'Unit',
      key: 'catering_label',
    },
    {
      title: 'Sizes',
      key: 'sizes',
      align: 'center',
      render: (sizes) => sizes?.filter((size) => size.price)
        .map((size) => size.name || 'One size').join(', '),
    },
    {
      title: 'Base Price',
      key: 'value',
      width: 150,
      align: 'center',
      render: (value) => priceTransformer(value),
    },
    {
      title: 'Tags',
      key: 'dietary_tags',
      width: 190,
      align: 'center',
      render: (value) => <DietaryTags tags={value} maxCount={3} />,
    },
    {
      title: 'Item availability',
      key: 'is_active',
      width: 160,
      align: 'center',
      render: (value, record) => (
        <Switch checked={value} onChange={() => onChangeItemAvailability(record.id)} />
      ),
    },
    {
      title: '',
      key: 'operation',
      width: 85,
      align: 'center',
      render: (_, record) => (
        <Space size="small">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingItem(record);
              setMode(ModalMode.EDIT);
            }}
          />
          <Button type="text" danger icon={<DeleteOutlined />} onClick={(e) => onRemoveItem(e, record.id)} />
        </Space>
      ),
    },
  ];
  const data = useMemo(() => category.items?.sort((a, b) => a.item_position?.[0]?.position - b.item_position?.[0]?.position)
    ?.map((item) => ({ ...item, key: item.id })) || [], [category]);
  const reOrderItem = ({ items, categoryId }) => {
    const currentCategories = [...categories];
    const categoryIndex = currentCategories.findIndex((c) => c.id === categoryId);
    currentCategories[categoryIndex].items = items;
    setCategories([...currentCategories]);
  };

  const onReorderItemCallback = ({ id, position, categoryId }) => {
    setItemsLoading(true);
    reorderItemPosition({ itemId: id, categoryId, position }).then((res) => {
      console.log(res);
    }).catch((e) => captureException(e))
      .finally(() => setItemsLoading(false));
  };

  const onDrawerClose = () => {
    setMode(ModalMode.CLOSED);
    setEditingItem();
  };
  const normalizedColumns = useMemo(() => generateColumns(columns.filter((c) => c)), [columns]);
  return (
    <>
      {!!data.length && (
        <DraggableTable
          columns={normalizedColumns}
          dataSource={data}
          setDataSource={(items) => reOrderItem({ items, categoryId: category.id })}
          onDragCallback={({ id, position }) => onReorderItemCallback({ id, position, categoryId: category.id })}
        />
      )}
      <Button type="text" style={{ margin: 8 }} icon={<PlusOutlined />} onClick={() => setMode(ModalMode.NEW)}>
        Add item
      </Button>

      <ItemDetailModal mode={mode} onClose={onDrawerClose} category={category} itemDetail={editingItem} />
    </>
  );
}

export default ItemsTable;
