import { App, Table } from 'antd';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';
import http, { getHttpErrorMessage } from 'utils/http';
import { generateColumns } from 'utils/table';
import { EventEmitter } from 'utils/event';
import { MenuContext } from '../constants';
import columns from './columns';
import DeleteDisableModal from './DeleteDisableModal';
import { getFilteredModifiers } from './utils';

function List({ searchTerm, setCurrentModifier }) {
  const { isGlobal, modifiers } = useContext(MenuContext);
  const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState();
  const { message } = App.useApp();

  const onEdit = useCallback((modifier) => {
    setCurrentModifier(modifier);
  }, [setCurrentModifier]);

  const onChangeAvailability = (data) => {
    setSelectedModifier(data);
    setOpenAvailabilityModal(true);
  };

  const normalizedColumns = useMemo(() => generateColumns(columns({
    isGlobal,
    onEdit,
    onChangeAvailability,
  })), [isGlobal]);

  const onConfirmChangeAvailability = () => {
    http.put(`v1/modifiers/${selectedModifier?.id}/`, { is_active: !selectedModifier.is_active })
      .then((res) => {
        setSelectedModifier();
        EventEmitter.emit('refresh_modifiers');
      })
      .catch((e) => {
        captureException(e);
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => setOpenAvailabilityModal(false));
  };

  return (
    <>
      <Table
        columns={normalizedColumns}
        dataSource={getFilteredModifiers({ searchTerm, modifiers })}
        scroll={{ x: 780 }}
        pagination={{
          showTotal: (total) => `Total ${total} results`,
        }}
        rowClassName="cursor-pointer"
      />

      <DeleteDisableModal
        isDelete={false}
        open={openAvailabilityModal}
        setOpen={setOpenAvailabilityModal}
        modifier={selectedModifier}
        onConfirm={onConfirmChangeAvailability}
      />
    </>
  );
}

export default List;
