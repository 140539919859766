import { Flex, Modal, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

function RestaurantTime({
  openingHours,
  merchant,
  open,
  onClose,
  firstAvailableTime,
}) {
  return (
    <Modal
      title={`${merchant.name}'s hours`}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      {
        firstAvailableTime && (
          <Typography.Paragraph strong style={{ fontSize: 20 }}>
            First available scheduling time:
            {dayjs(firstAvailableTime).format('ddd, MMM D, h:mm A')}
          </Typography.Paragraph>
        )
      }
      {
        openingHours && Object.entries(openingHours).map(([day, hours]) => (
          <Flex key={day} justify="space-between" className="mt-2">
            <Typography.Text strong style={{ textTransform: 'capitalize' }}>{day.toLowerCase()}</Typography.Text>
            <Flex style={{ flexDirection: 'row', flexWrap: 'wrap', textAlign: 'right' }} split={<Typography.Text style={{ fontSize: 12, margin: '0 4px' }}> , </Typography.Text>}>
              <Typography.Text style={{ textTransform: 'lowercase', fontFamily: 'Manrope' }}>
                {
                  hours.map((h) => `${h.from_hour}-${h.to_hour}`).join(', ')
                }
              </Typography.Text>
            </Flex>
          </Flex>
        ))
      }
    </Modal>
  );
}

export default RestaurantTime;
