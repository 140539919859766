function on(eventName, callback) {
  document.addEventListener(eventName, callback);
}

function clear(eventName, callback) {
  document.removeEventListener(eventName, callback);
}

function emit(eventName) {
  document.dispatchEvent(new Event(eventName));
}

export const EventEmitter = {
  on,
  emit,
  clear,
};
