import { Divider, Form } from 'antd';
import React, { useContext } from 'react';
import useFetchedData from 'hooks/useFetchedData';
import { STEPS } from '../constants';
import OrderFormItem from '../../components/OrderFormItem';
import Steps from '../Steps';
import { StepsContext } from '../Steps/StepsProvider';
import { useIsCatering } from '../../utils/useIsCatering';
import OrderType from './OrderType';
import RecipientInfo from './RecepientInfo';
import SchedulingInput from './SchedulingInput';
import Summary from './Summary';

function DeliveryInfo() {
  const isCatering = useIsCatering();
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');
  const { loading: stepLoading } = useContext(StepsContext);
  const orderItems = Form.useWatch('order_items');
  const { data: merchantData } = useFetchedData(
    merchant?.id && `v1/organizations/{{organizationId}}/merchants/${merchant.id}/`,
    (res) => res?.data,
  );

  return (
    <Steps.Step step={STEPS.DELIVERY_INFO} summary={<Summary />}>
      <OrderType disabled />
      <div style={{ display: isCatering ? 'block' : 'none' }}>
        <RecipientInfo />
      </div>
      {(merchantData?.has_scheduling_order) && (
        <>
          <Divider />
          <OrderFormItem
            name="scheduled_for"
            label="Scheduling time"
            fullWidth
            validateTrigger="onBlur"
            rules={[
              { required: isCatering },
            ]}
          >
            <SchedulingInput
              isCatering={isCatering}
              merchantId={merchant.id}
              loading={stepLoading}
              needConfirmation={orderItems?.length > 0}
            />
          </OrderFormItem>
        </>
      )}
    </Steps.Step>
  );
}

export default DeliveryInfo;
