import { Input } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import SwitchInput from 'components/SwitchInput';
import { MenuContext } from 'containers/GlobalMenuManagement/constants';
import React, { useContext } from 'react';

function GeneralInfo({ disabled }) {
  const { isGlobal } = useContext(MenuContext);

  return (
    <FormLayout.Section divider={false}>
      <FormItem name="name" label="Name" fullWidth rules={[{ required: true }]}>
        <Input placeholder="Modifier name" disabled={disabled} />
      </FormItem>

      <FormItem name="is_active" boolean fullWidth hidden={isGlobal}>
        <SwitchInput
          label="Mark as available"
          description="Customers can view modifier and select its available options on all items that use it."
          className="flex-row-reverse"
        />
      </FormItem>
    </FormLayout.Section>
  );
}

export default GeneralInfo;
