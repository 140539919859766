import { Input, Space, Typography } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React from 'react';

function Unit({ disabled }) {
  return (
    <FormLayout.Section>
      <Space direction="vertical" className="mb-1">
        <Typography.Text className="font-bold">Unit</Typography.Text>
        <Typography.Text className="text-[#A7A9A9]">
          Select the unit of measure for this item. If not set, it will be default.
        </Typography.Text>
      </Space>
      <FormItem name="catering_label" fullWidth>
        <Input placeholder="Box" disabled={disabled}/>
      </FormItem>
    </FormLayout.Section>
  );
}

export default Unit;
