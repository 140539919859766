import {
  Col, Divider, Flex, Form, Input, Radio, Row, Space, Typography,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import React from 'react';
import OrderFormItem from '../../components/OrderFormItem';

function RecipientInfo() {
  const form = Form.useFormInstance();
  const customer = form.getFieldValue('customer');
  const recipient = Form.useWatch('recipient');
  return (
    <>
      <Divider />
      <Row>
        <Col xs={24} lg={7}>
          <OrderFormItem name="recipient" label="Who will be accepting this order?" fullWidth>
            <Radio.Group defaultValue="self">
              <Space direction="vertical">
                <Radio value="self">
                  {customer?.name}
                  {' '}
                  (
                  {customer?.phone}
                  )
                </Radio>
                <Radio value="other">Someone else</Radio>
              </Space>
            </Radio.Group>
          </OrderFormItem>
        </Col>
        <Col xs={24} lg={17} style={{ display: recipient === 'other' ? 'block' : 'none' }}>
          <Flex vertical gap={16} className="bg-[#DAEADE66] py-2 px-4 rounded">
            <Space align="baseline">
              <Typography.Title level={5}>
                Recipient info
              </Typography.Title>
              <Typography.Text style={{ fontWeight: 700, color: '#A7A9A9' }}>
                Required
              </Typography.Text>
            </Space>
            <Row gutter={12}>
              <Col xs={24} lg={6}>
                <OrderFormItem name="recipient_name" fullWidth>
                  <Input placeholder="Recipient name" size="large" />
                </OrderFormItem>
              </Col>
              <Col xs={24} lg={6}>
                <OrderFormItem name="recipient_last_name" fullWidth>
                  <Input placeholder="Recipient last name" size="large" />
                </OrderFormItem>
              </Col>
              <Col xs={24} lg={6}>
                <OrderFormItem name="recipient_number" fullWidth>
                  <PhoneInput
                    country="us"
                    buttonClass="phone-country"
                    inputClass="input"
                    inputProps={{
                      name: 'phone',
                      autoFocus: true,
                    }}
                    inputStyle={{ width: '100%', height: 40 }}
                    placeholder="Recipient phone"
                    autofocus
                    preferredCountries={['us', 'ca']}
                    enableSearch
                    regions={['america', 'europe']}
                    countryCodeEditable={false}
                    masks={{
                      pr: '(...) ...-....',
                    }}
                  />
                </OrderFormItem>
              </Col>
            </Row>
          </Flex>
        </Col>
      </Row>
    </>
  );
}

export default RecipientInfo;
