import { Flex, Input } from 'antd';
import React from 'react';
import OrderFormItem from '../../components/OrderFormItem';
import { STEPS } from '../constants';
import Steps from '../Steps';
import Adjustment from './Adjustment';
import CustomDeliveryFee from './CustomDeliveryFee';
import DeliveryTip from './DeliveryTip';

function AdditionalDetails() {
  return (
    <Steps.Step step={STEPS.ADDITIONAL_DETAILS}>
      <Flex vertical gap={24}>
        <Adjustment />
        <CustomDeliveryFee />
        <DeliveryTip />

        <OrderFormItem label="Delivery notes" name="delivery_message" labelColStyle={{ width: 300 }}>
          <Input.TextArea
            placeholder="Delivery notes"
            showCount
            multiple
            maxLength={100}
            rows={4}
          />
        </OrderFormItem>
      </Flex>
    </Steps.Step>
  );
}
export default AdditionalDetails;
