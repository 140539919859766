export const DASHBOARD = 'auth.rds_dashboard_access';
export const ORDERS = 'auth.rds_order_tab_access';
export const REPORTS = 'auth.rds_report_tab_access';
export const MARKETING = 'auth.rds_marketing_tab_access';
export const COUPONS = 'auth.rds_coupon_tab_access';
export const CUSTOMERS = 'auth.rds_customer_tab_access';
export const MERCHANTS = 'auth.rds_merchant_tab_access';
export const PARENTS = 'auth.rds_parents_tab_access';
export const COMPANIES = 'auth.rds_companies_tab_access';
export const USER_MANAGEMENT = 'auth.rds_permission_tab_access';
export const BILLING = 'auth.rds_billing_tab_access';
export const ADMIN_ACCESS = 'auth.rds_moderator_access';
export const GLOBAL_MENU = 'auth.rds_global_menu_access';

export const PERMISSIONS = {
  DASHBOARD,
  ORDERS,
  REPORTS,
  MARKETING,
  COUPONS,
  CUSTOMERS,
  MERCHANTS,
  USER_MANAGEMENT,
  BILLING,
  PARENTS,
  ADMIN_ACCESS,
  COMPANIES,
  GLOBAL_MENU,
};
