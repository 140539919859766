import { Button, Modal, Checkbox } from 'antd';
import { getDateRange } from 'containers/Reports/utils';
import React, { useEffect, useState } from 'react';
import ModalFilterBox from './ModalFilterBox';
import './index.css';

function ExportToCSVModal(
  {
    open,
    setOpen,
    loading = false,
    filterData,
    hasLocationFilter,
    hasOrderTypeFilter,
    hasMerchantFilter,
    handleExport,
  },
) {
  const [sendEmail, setSendEmail] = useState(true);
  const [filters, setFilters] = useState();

  useEffect(() => {
    setFilters(filterData.selected);
  }, [filterData, open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    handleExport(filters);
  };

  const onChangeFilters = (key, values) => {
    setFilters({ ...filters, [key]: values });
  };

  const modalTitle = loading ? 'Preparing for download'
    : `Download report - ${getDateRange({ range: filters?.date?.range })}`;

  return (
    <Modal
      open={open}
      title={modalTitle}
      onOk={handleCancel}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Export
        </Button>,
      ]}
      bodyStyle={{
        background: '#F5F8F7', maxHeight: '500px', overflow: 'scroll', padding: '0 24px 24px', margin: '0 -24px',
      }}
    >
      {loading && (
        <div className="export-modal-section mt-5">
          <span>We’re currently exporting data.</span>
          <label>
            If you’d like you can close this dialog box.
          </label>
        </div>
      )}
      {!loading && (
        <>
          {hasLocationFilter && (
            <ModalFilterBox
              title="Location"
              options={filterData?.options?.locations}
              filter={filters?.locations}
              setFilters={(values) => onChangeFilters('locations', values)}
            />
          )}

          {hasOrderTypeFilter && (
            <ModalFilterBox
              title="Order Type"
              options={filterData?.options?.orderTypes}
              filter={filters?.orderTypes}
              setFilters={(values) => onChangeFilters('orderTypes', values)}
            />
          )}

          {hasMerchantFilter && (
            <ModalFilterBox
              title="Restaurant type"
              options={filterData?.options?.merchantTypes}
              filter={filters?.merchantTypes}
              setFilters={(values) => onChangeFilters('merchantTypes', values)}
            />
          )}

          {/* Removed Temporary */}
          {/* <div className="export-modal-section notification-section">*/}
          {/*  <span>Notifications</span>*/}
          {/*  <Checkbox value={sendEmail} onChange={setSendEmail}>*/}
          {/*    Email*/}
          {/*    {' '}*/}
          {/*    <b>{user?.info?.email}</b>*/}
          {/*    {' '}*/}
          {/*    when your report is ready to download*/}
          {/*  </Checkbox>*/}
          {/* </div>*/}

          {/* Add Temporary */}
          {!hasLocationFilter && !hasMerchantFilter && !hasOrderTypeFilter && (
            <div className="export-modal-section notification-section">
              Your report is ready to download.
            </div>
          )}
        </>
      )}
    </Modal>
  );
}

export default ExportToCSVModal;
