import {
  Alert, Flex, Form, Modal, Space, Typography,
} from 'antd';
import { PaymentTypes } from 'constants';
import dayjs from 'dayjs';
import React from 'react';
import { priceTransformer } from 'utils';
import { useIsCatering } from '../utils/useIsCatering';

function EstimatedInfoModal({
  open, onClose, onSubmit, data,
}) {
  const form = Form.useFormInstance();
  const {
    headcount,
    has_delivery,
    payment_method,
    recipient,
    recipient_name,
    recipient_last_name,
    recipient_phone,
    customer,
    merchant,
    address,
    scheduled_for,
    quotedPaymentMeta,
  } = form.getFieldsValue();
  const isCatering = useIsCatering();
  const isQuoted = payment_method?.type === PaymentTypes.QUOTED;
  const paymentType = {
    [PaymentTypes.CARD]: 'Online',
    [PaymentTypes.IN_STORE]: 'In-store',
    [PaymentTypes.CHEQUE]: 'Cheque',
  }[quotedPaymentMeta?.type || PaymentTypes.CARD];

  const priceRow = ({ title, price, style = { color: '#5A5F62' } }) => (
    <Flex justify="space-between">
      <Typography.Text style={style}>{title}</Typography.Text>
      <Typography.Text style={style}>{priceTransformer(price)}</Typography.Text>
    </Flex>
  );

  return (
    <Modal
      title="Confirming order"
      open={open}
      onCancel={onClose}
      onOk={onSubmit}
    >
      {isQuoted && (
        <Alert
          style={{ marginBottom: 16 }}
          message={
            `The quote will be sent to the customer's email and phone number, and displayed on the online ordering website. It will be removed if the payment information is not updated. (payment option: ${paymentType})`
          }
          type="info"
        />
      )}

      <Flex vertical className="mb-8">
        <Typography.Title level={4}>
          General info
        </Typography.Title>
        <Flex justify="space-between">
          <Typography.Text style={{ color: '#5A5F62' }}>Customer</Typography.Text>
          <Space size="middle">
            <Typography.Text style={{ color: '#5A5F62' }}>
              {customer?.name}
            </Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {customer?.value}
            </Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {customer?.email}
            </Typography.Text>
          </Space>
        </Flex>
        <Flex justify="space-between">
          <Typography.Text style={{ color: '#5A5F62' }}>Merchant</Typography.Text>
          <Typography.Text style={{ color: '#5A5F62' }}>
            {merchant?.name}
          </Typography.Text>
        </Flex>
        <Flex justify="space-between">
          <Typography.Text style={{ color: '#5A5F62' }}>Order type</Typography.Text>
          <Typography.Text style={{ color: '#5A5F62' }}>
            {has_delivery ? 'Delivery' : 'Pickup'}
          </Typography.Text>
        </Flex>
        {has_delivery && (
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Address</Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {address?.address}
            </Typography.Text>
          </Flex>
        )}
      </Flex>

      {isCatering && (
        <Flex vertical className="mb-8">
          <Typography.Title level={4}>
            Order instructions
          </Typography.Title>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Scheduled time</Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {dayjs(scheduled_for).format('ddd, D MMM, hh:mm A')}
            </Typography.Text>
          </Flex>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Headcount</Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>
              {headcount}
              {' '}
              people
            </Typography.Text>
          </Flex>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Recipient info</Typography.Text>
            <Space>
              <Typography.Text style={{ color: '#5A5F62' }}>
                {recipient === 'other' ? `${recipient_name} ${recipient_last_name}` : customer?.name}
              </Typography.Text>
              <Typography.Text style={{ color: '#5A5F62' }}>
                {recipient === 'other' ? recipient_phone : customer?.value}
              </Typography.Text>
            </Space>
          </Flex>
        </Flex>
      )}
      <Flex vertical className="mb-8">
        <Typography.Title level={4}>
          Order summary
        </Typography.Title>

        {priceRow({ title: 'Subtotal', price: data?.food_value })}
        {priceRow({ title: 'Delivery fee', price: data?.delivery_fare })}
        {priceRow({ title: 'Service fee', price: data?.service_fee })}
        {has_delivery && priceRow({ title: 'Tip', price: data?.tip })}
        {priceRow({ title: 'Adjustment', price: data?.adjustment_amount })}
        {priceRow({ title: 'Taxes', price: data?.tax })}
        {priceRow({
          title: 'Total',
          price: data?.total_value,
          style: {
            color: 'black', fontSize: '16px', fontWeight: '700', marginTop: 16,
          },
        })}
        {isCatering && priceRow({ title: 'Total per person', price: (data?.total_value / headcount) })}
      </Flex>

      {!isQuoted && (
        <Flex vertical>
          <Typography.Title level={4}>
            Payment summary
          </Typography.Title>
          <Flex justify="space-between">
            <Typography.Text style={{ color: '#5A5F62' }}>Method</Typography.Text>
            <Typography.Text style={{ color: '#5A5F62' }}>{payment_method?.title}</Typography.Text>
          </Flex>
        </Flex>
      )}

    </Modal>
  );
}

export default EstimatedInfoModal;
