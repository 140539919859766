import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import {
  App,
  Button, Dropdown, Flex, message, Radio, Skeleton, Space, Tag,
  Typography,
} from 'antd';
import { OrderStatusLabel, OrderStatusTagColor } from 'constants/status';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import React, { useMemo } from 'react';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'utils/http';
import { getOrderType } from 'utils/order';
import './index.css';
import { changeOrderState } from './requests';

function Header({
  order, loading, onClose, handleStateChange,
}) {
  const isMobile = useIsMobile();

  const { modal } = App.useApp();
  const statusOptions = useMemo(() => {
    const nextStates = order?.possible_next_states?.map((nextState) => (
      {
        label: nextState.state_label,
        key: nextState.state_id,
        color: OrderStatusTagColor[nextState.state_id],
      }
    )) || [];
    return [{
      label: OrderStatusLabel[order.state],
      key: order.state,
      color: OrderStatusTagColor[order.state],
    }, ...nextStates];
  }, [order?.possible_next_states, order.state]);

  const showConfirm = (nextState) => {
    modal.confirm({
      title: 'Confirm Order Status Change',
      content: (
        <Typography.Text>
          Are you sure you want to change the order status from
          {' '}
          <b>{OrderStatusLabel[order.state]}</b>
          {' '}
          to
          {' '}
          <b>{OrderStatusLabel[nextState]}</b>
          ?
        </Typography.Text>
      ),
      onOk: () => changeOrderState({ orderId: order.id, state: nextState })
        .then((res) => handleStateChange())
        .catch((e) => {
          captureException(e);
          message.error(getHttpErrorMessage(e));
        }),
      okText: 'Confirm',
      cancelText: 'Cancel',
    });
  };

  if (!order || loading) {
    return <Skeleton paragraph={{ rows: 1 }} />;
  }

  return (
    <Flex vertical gap={8}>
      <Flex justify="space-between">
        <Typography>
          Order #
          {order.number}
          {' - '}
          {order.id}
        </Typography>
        <CloseOutlined onClick={onClose} />
      </Flex>

      <Flex vertical={isMobile} justify="space-between" gap={8} align={isMobile ? 'start' : 'center'}>
        <Space>
          <Tag>{getOrderType(order)}</Tag>

          <Dropdown
            menu={{
              items: statusOptions.map((item) => ({
                key: item.key,
                label: (
                  <Radio
                    checked={item.key === order.state}
                    onChange={() => {
                      showConfirm(item.key);
                    }}
                  >
                    <Tag color={item.color} className="font-bold py-0.5">{item.label}</Tag>
                  </Radio>
                ),
              })),
            }}
          >
            <Flex gap={0}>
              <Tag
                color={OrderStatusTagColor[order.state]}
                style={{ borderRadius: '4px 0 0 4px ' }}
                className="font-bold py-1 px-3 mr-0"
              >
                {OrderStatusLabel[order.state]}
              </Tag>
              <Button
                icon={<DownOutlined style={{ fontSize: 12 }} />}
                style={{
                  width: 40,
                  height: 32,
                  borderRadius: '0 4px 4px 0',
                  borderLeft: 'none',
                }}
                className="status-selector-button"
              />
            </Flex>
          </Dropdown>

        </Space>

        <Typography.Text type="secondary" className="justify-end">
          Placed on:
          {' '}
          {dayjs(order.timestamp).format('MMM D, LT')}
        </Typography.Text>
      </Flex>
    </Flex>
  );
}
export default Header;
