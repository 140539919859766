import { EMPTY_ARRAY } from 'constants';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React from 'react';
import { useNavigate } from 'react-router';

function withGuard(Component, permissions, {
  redirect = false,
  fallback = null,
}) {
  return function (props) {
    const hasPermission = useHasAnyOfPermissions(permissions || EMPTY_ARRAY);
    const navigate = useNavigate();
    if (hasPermission) {
      return <Component {...props} />;
    }
    if (redirect) {
      // This timeout is to ensure that routes are loaded before the redirect
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 1);
    }
    return fallback;
  };
}

export default withGuard;
