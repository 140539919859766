import React from 'react';
import http from 'utils/http';
import ItemDrawer from './ItemDrawer';

function CreateItemDrawer({ open, onClose }) {
  async function handleCreate(data) {
    await http.post('/v1/organizations/{{organizationId}}/global-items/', data);
    onClose();
  }

  return (
    <ItemDrawer
      title="Create item"
      open={open}
      onSubmit={handleCreate}
      onClose={onClose}
    />
  );
}

export default CreateItemDrawer;
