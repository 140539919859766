import React from 'react';
import CateringSchedulingInput from './CateringSchedulingInput';
import NonCateringSchedulingInput from './NonCateringSchedulingInput';

function SchedulingInput({ isCatering, ...props }) {
  if (isCatering) {
    return <CateringSchedulingInput {...props} />;
  }
  return <NonCateringSchedulingInput {...props} />;
}

export default SchedulingInput;
