import {
  App, Button, Drawer, Flex, Form, Select,
} from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import http, { getHttpErrorMessage } from 'utils/http';
import useFetchedData from 'hooks/useFetchedData';
import { EventEmitter } from 'utils/event';

function MenuSelectorDrawer() {
  const navigate = useNavigate();
  const { id: merchantId } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  const { data: globalMenus = [], loading: globalMenusLoading } = useFetchedData(
    {
      url: 'v1/organizations/{{organizationId}}/global-menus/',
      query: { query: '{id,name,is_active,active_times}' },
    },
    ({ results } = {}) => results,
  );

  const onSubmit = async (data) => {
    setLoading(true);
    http.post(`/v1/organizations/{{organizationId}}/global-menus/${data.menu}/merchant/${merchantId}/`)
      .then((res) => {
        message.success('Menu added successfully!');
        EventEmitter.emit('refresh_menus');
        onClose();
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onClose() {
    navigate('..');
  }

  return (
    <Drawer
      title="Select menu from global menus"
      placement="right"
      width={590}
      onClose={onClose}
      open
      styles={{ body: { padding: '16px 0 64px' } }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={() => {
          message.error('Please fix errors!');
        }}
        onFinish={onSubmit}
      >
        <FormLayout.Section divider={false}>
          <FormItem name="menu" label="Menu" rules={[{ required: true }]} fullWidth>
            <Select
              className="w-full"
              placeholder="Select menu"
              dropdownClassName="menu-selector"
              options={globalMenus?.map((item) => ({ label: item.name, value: item.id }))}
              dropdownStyle={{ padding: 0 }}
              loading={globalMenusLoading}
            />
          </FormItem>
        </FormLayout.Section>

        <FormLayout.Footer>
          <Flex justify="flex-end" className="w-full">
            <Flex justify="end" gap={8}>
              <Button disabled={loading} onClick={onClose}>Cancel</Button>
              <Button htmlType="submit" loading={loading} type="primary">Select</Button>
            </Flex>
          </Flex>
        </FormLayout.Footer>
      </Form>
    </Drawer>
  );
}

export default MenuSelectorDrawer;
